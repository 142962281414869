import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../../css/Manage.css";
import "../../css/withdraw/ManageWithdraw.css";
import LeaveApi from "../../api/leaveApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { saveAs } from "file-saver";

const ManageWithdraw = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [withdrawList, setWithdrawList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getWithdrawList();
  }, [currentPage, location]);

  const getWithdrawList = async () => {
    try {
      const list = (
        await LeaveApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setWithdrawList((withdrawList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  //삭제 실행하는 곳
  const leaveHandler = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await LeaveApi.Del(deleteId);
        toast("삭제되었습니다.");
        getWithdrawList();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  //------------엑셀 생성
  const downloadExcel = async () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "별명", key: "nickname", width: 40 },
      { header: "탈퇴사유", key: "reason", width: 100 },
      { header: "탈퇴일", key: "createdAt", width: 100 },
    ];

    excel.map((item, index) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "탈퇴회원 관리.xlsx");
  };
  //------------액셀 부분 끝
  const [excel, setExcel] = useState([]);
  const [arr, setArr] = useState([]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>탈퇴 회원 관리</span>
        <div className="manage-table-container">
          <div className="Manage-withdraw-search-container">
            <div className="Manage-withdraw-search-number">
              <span>탈퇴 회원 수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-withdraw-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/withdraw/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              <div>
                <a onClick={downloadExcel} className="cursorPointer">
                  <img src="\img\download.svg" />
                  <span>엑셀로 내보내기</span>
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-withdraw">
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      className="cursorPointer all-check-input"
                      type="checkbox"
                      id={"check"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let obj = [];
                          const temp = withdrawList.map((value) => value.id);
                          const excelTemp = withdrawList.map((item) => {
                            obj.push({
                              email: item.email,
                              nickname: item.nickname,
                              createdAt: moment(item.createdAt).utc().format("YYYY/MM/DD"),
                              reason: item.reason,
                            });
                          });
                          setArr(temp);
                          setExcel(obj);
                        } else {
                          setArr([]);
                          setExcel([]);
                        }
                      }}
                    ></input>
                  </th>
                  <th>이메일</th>
                  <th>별명</th>
                  <th>탈퇴사유</th>
                  <th>탈퇴일</th>
                  <th>완전삭제</th>
                </tr>
              </thead>
              <tbody>
                {withdrawList.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr>
                      <td>
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id={"check" + index}
                          checked={arr.includes(item.id)}
                          onChange={(e) => {
                            let obj = {
                              email: item.email,
                              name: item.name,
                              nickname: item.nickname,
                              createdAt: moment(item.createdAt).utc().format("YYYY/MM/DD"),
                              reason: item.reason,
                            };
                            let copyData = [...arr];
                            let copyExcel = [...excel];
                            if (e.target.checked) {
                              copyData.push(item.id);
                              copyExcel.push(obj);
                              setArr(copyData);
                              setExcel(copyExcel);
                            } else {
                              let arrTemp = copyData.filter((item02) => item02 !== item.id);
                              let excelTemp = copyExcel.filter((item02) => item02.name !== item.name);
                              setArr(arrTemp);
                              setExcel(excelTemp);
                            }
                          }}
                        ></input>
                        <label htmlFor={"check" + index}></label>
                      </td>
                      <td>
                        <span>{item.email}</span>
                      </td>
                      <td>{item.nickname}</td>
                      <td>
                        <span>{item.reason}</span>
                      </td>
                      <td>{createDate}</td>
                      <td>
                        <Link onClick={() => leaveHandler(item.id)}>완전삭제</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/withdraw/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/withdraw/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/withdraw/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageWithdraw;
