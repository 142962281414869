import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/generalmember/ManageGeneralMemberSeeMore.css";
import "../../css/Manage.css";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { downloadFile } from "../../utils/download";

const ManageGeneralMemberSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [memberInfo, setMemberInfo] = useState({});
  const [memberId, setMemberId] = useState(null);

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setMemberId(location.state.id);
      getMemberInfo(id);
    }
  }, [location]);

  const getMemberInfo = async (id) => {
    try {
      const getMember = (await MemberApi.GetMember(id)).data.data.content;
      setMemberInfo(getMember);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const delMember = async () => {
    setIsOpen(true);
    setDeleteId(memberId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await MemberApi.DelMember(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/member/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  const fileDownload = async (serverFileName, originFileName) => {
    try {
      const data = {
        serverFileName: serverFileName,
        originFileName: originFileName,
      };
      const response = await MemberApi.FileDownload(data);
      downloadFile(response, originFileName);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-general-member-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link
              onClick={() => {
                delMember();
              }}
            >
              삭제
            </Link>
            <Link to={memberId ? `/admin/member/edit/${memberId}` : "/admin/member/edit"} state={{ id: memberId }}>
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-general-member-see-more">
          <span>기본정보</span>
          <div className="Manage-form-view-details">
            <span>이메일</span>
            <span>{memberInfo.email}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>이름</span>
            <span>{memberInfo.name}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>생년월일</span>
            <span>{memberInfo.birth}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>성별</span>
            <span>{memberInfo.gender === "1" ? "남성" : "여성"}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>휴대폰 번호</span>
            <span>{memberInfo.phoneNumber}</span>
          </div>
          <div className="Manage-form-view-details general-member-info-flex-start">
            <span>
              주민등록증
              <br />
              이미지
            </span>
            <div className="general-member-info-img-container">
              <img
                className="uploaded-img"
                src={memberInfo.profileUrl ? memberInfo.profileUrl : "/img/Rectangle.svg"}
              />
              {memberInfo.profileUrl ? (
                <div>
                  <span>{memberInfo.profileOriginFileName}</span>
                  <a
                    onClick={() => {
                      fileDownload(memberInfo.profileServerFileName, memberInfo.profileOriginFileName);
                    }}
                  >
                    <img src={"/img/download_black.svg"} />
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="Manage-form-view-details">
            <span>별명</span>
            <span>{memberInfo.nickname}</span>
          </div>
          <div className="Manage-form-view-details general-member-info-flex-start">
            <span>한 줄 자기소개</span>
            <span>{memberInfo.intro}</span>
          </div>
          <div className="Manage-form-view-details general-member-agreement-checkbox">
            <span>마케팅 수신 동의</span>
            <div>
              <div className="seemore-consent-checkbox">
                <span>(필수) 괜찮아마을 이용약관 동의</span>
                <input type="checkbox" id="consent-personal-info" checked="true" readOnly></input>
                <label htmlFor="consent-personal-info"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(필수) 개인정보수집 및 이용 동의</span>
                <input type="checkbox" id="consent-personal-info-third" checked="true" readOnly></input>
                <label htmlFor="consent-personal-info-third"></label>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details general-member-agreement-checkbox">
            <span>알림 수신 동의</span>
            <div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 이메일 및 SMS 마케팅정보수신 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info"
                  checked={memberInfo.agreeToEmailSms ? true : false}
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 서비스 알림 수신 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info-third"
                  checked={memberInfo.agreeToService ? true : false}
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info-third"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 광고성 알림 수신 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info-third"
                  checked={memberInfo.agreeToAd ? true : false}
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info-third"></label>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageGeneralMemberSeeMore;
