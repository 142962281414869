import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/space/ManageSpaceSeeMore.css";
import SpaceApi from "../../api/spaceApi";
import MeetingApi from "../../api/meetingApi";
import SpaceReviewApi from "../../api/spaceReviewApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { downloadFile } from "../../utils/download";
import { Map, MapMarker, StaticMap } from "react-kakao-maps-sdk";
const ManageSpaceSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState(
    "삭제된 데이터는 복구가 불가능합니다."
  );
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [meetingCount, setMeetingCount] = useState(null);
  const [meetingPostsPerPage, setMeetingPostsPerPage] = useState(null);
  const [meetstartIndex, setMeetStartIndex] = useState(0);
  const [meetcurrentPage, setMeetCurrentPage] = useState(1);
  const [meetpageIndex, setMeetPageIndex] = useState([]);

  const [reviewCount, setReviewCount] = useState(null);
  const [reviewPostsPerPage, setReviewPostsPerPage] = useState(null);
  const [reviewstartIndex, setReviewStartIndex] = useState(0);
  const [reviewcurrentPage, setReviewCurrentPage] = useState(1);
  const [reviewpageIndex, setReviewPageIndex] = useState([]);

  const [spaceId, setSpaceId] = useState(null);
  const [spaceInfo, setSpaceInfo] = useState({});
  const [gatheringList, setGatheringList] = useState([]);
  const [reviewList, setReviewList] = useState([]);

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setSpaceId(location.state.id);
      getSpaceInfo();
    }
  }, [location]);

  useEffect(() => {
    const getMeetingInfo = async () => {
      try {
        const meetingInfo = (
          await MeetingApi.getMeetings({
            page: meetcurrentPage,
            size: 8,
            spaceId: location.state.id,
          })
        ).data.data;
        setGatheringList(meetingInfo.content);
        setMeetingCount(meetingInfo.totalCount);
        setMeetingPostsPerPage(meetingInfo.content.length);

        const meetingCount = Math.ceil(
          meetingInfo.totalCount / meetingInfo.content.length / 4
        );

        for (let i = 1; i <= meetingCount; i++) {
          if (meetcurrentPage / 4 <= i) {
            setMeetStartIndex(4 * (i - 1));
          }
        }
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    getMeetingInfo();
  }, [meetcurrentPage]);

  useEffect(() => {
    const getReviewInfo = async () => {
      try {
        const reviewInfo = (
          await SpaceReviewApi.Get({
            page: reviewcurrentPage,
            size: 8,
            spaceId: location.state.id,
          })
        ).data.data;
        setReviewList(reviewInfo.content);
        setReviewCount(reviewInfo.totalCount);
        setReviewPostsPerPage(reviewInfo.content.length);

        const reviewCount = Math.ceil(
          reviewInfo.reviewCount / reviewInfo.content.length / 4
        );

        for (let i = 1; i <= reviewCount; i++) {
          if (reviewcurrentPage / 4 <= i) {
            setReviewStartIndex(4 * (i - 1));
          }
        }
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    getReviewInfo();
  }, [reviewcurrentPage]);

  const getSpaceInfo = async () => {
    try {
      //-----------공간 데이터
      const spaceInfo = (await SpaceApi.GetSpace(location.state.id)).data.data
        .content;
      setSpaceInfo(spaceInfo);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const delSpace = async () => {
    setIsOpen(true);
    setDeleteId(spaceId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await SpaceApi.Del(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/space/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  useEffect(() => {
    let page = Math.ceil(meetingCount / meetingPostsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setMeetPageIndex(arr);
  }, [meetingCount]);

  useEffect(() => {
    let page = Math.ceil(reviewCount / reviewPostsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setReviewPageIndex(arr);
  }, [reviewCount]);

  const meetingStatus = async (id, status) => {
    let obj = {
      meetingStatus: status,
    };
    try {
      await MeetingApi.setStatus(id, obj);
      toast("변경되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const reviewActivation = async (id, status) => {
    let obj = {
      activation: status,
    };
    try {
      await SpaceReviewApi.PostActivation(id, obj);
      toast("변경되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const [subMenu, setSubMenu] = useState(null);
  const [reviewSubMenu, setReviewSubMenu] = useState(null);

  const fileDownload = async (serverFileName, originFileName) => {
    try {
      const data = {
        serverFileName: serverFileName,
        originFileName: originFileName,
      };
      const response = await SpaceApi.FileDownload(data);
      downloadFile(response, originFileName);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-space-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <a
              onClick={() => {
                delSpace();
              }}
            >
              삭제
            </a>
            <Link
              to={`/admin/space/detail/edit/${spaceId}`}
              state={{ id: spaceId }}
            >
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-space-see-more">
          <span>기본정보</span>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>등록관리자</span>
            <span>{spaceInfo.manager ? spaceInfo.manager.email : ""}</span>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>공간명</span>
            <span>{spaceInfo.name}</span>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>휴대전화</span>
            <span>{spaceInfo.phoneNumber}</span>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>이메일</span>
            <span>{spaceInfo.email}</span>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>상호(기업)명</span>
            <span>{spaceInfo.companyName}</span>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>통장사본</span>
            <div className="space-member-file-name">
              <a
                onClick={() => {
                  fileDownload(
                    spaceInfo.accountServerFileName,
                    spaceInfo.accountOriginFileName
                  );
                }}
              >
                <span>{spaceInfo.accountOriginFileName}</span>
                <img src="\img\download_black.svg" />
              </a>
            </div>
          </div>
          <div className="Manage-form-view-details-space Manage-form-view-details-space-seemore">
            <span>신분증(*개인)</span>
            <div className="space-member-file-name">
              <a
                onClick={() => {
                  fileDownload(
                    spaceInfo.idCardServerFileName,
                    spaceInfo.idCardOriginFileName
                  );
                }}
              >
                <span>{spaceInfo.idCardOriginFileName}</span>
                <img src="\img\download_black.svg" />
              </a>
            </div>
          </div>
        </div>
        <div className="Manage-form manage-form-space-see-more">
          <span>프로필 관리</span>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>카테고리</span>
            <span>{spaceInfo.spaceCategoryTitle}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>서브 카테고리</span>
            <span>{spaceInfo.subCategory}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>프로필 이미지</span>
            <div className="Manage-form-view-details-img Manage-form-view-details-img-space">
              <div>
                <img
                  src={
                    spaceInfo.profileUrl
                      ? spaceInfo.profileUrl
                      : "imgRectangle.svg"
                  }
                />
              </div>
              <div>
                <a
                  onClick={() => {
                    fileDownload(
                      spaceInfo.profileServerFileName,
                      spaceInfo.profileOriginFileName
                    );
                  }}
                >
                  <span>{spaceInfo.profileOriginFileName}</span>
                  <img src="\img\download_black.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>배경 이미지</span>
            <div className="Manage-form-view-details-img Manage-form-view-details-img-space">
              <div>
                <img
                  src={
                    spaceInfo.backgroundUrl
                      ? spaceInfo.backgroundUrl
                      : "imgRectangle.svg"
                  }
                />
              </div>
              <div>
                <a
                  onClick={() => {
                    fileDownload(
                      spaceInfo.backgroundServerFileName,
                      spaceInfo.backgroundOriginFileName
                    );
                  }}
                >
                  <span>{spaceInfo.backgroundOriginFileName}</span>
                  <img src="\img\download_black.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>공간 소개글</span>
            <span>{spaceInfo.intro}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>운영시간</span>
            <span>{spaceInfo.businessHours}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>주소</span>
            <span>{spaceInfo.address}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>상세주소</span>
            <span>{spaceInfo.addressDetail}</span>
          </div>
          <div
            className="Manage-form-view-details Manage-form-view-details-space-seemore"
            style={{ alignItems: "start" }}
          >
            <span>좌표</span>
            <div>
              <StaticMap
                center={{
                  lat: spaceInfo.lat,
                  lng: spaceInfo.lon,
                }}
                style={{ width: "300px", height: "300px", borderRadius: "4px" }}
                level={2}
                maxLevel={3}
                marker={[
                  {
                    position: {
                      lat: spaceInfo.lat,
                      lng: spaceInfo.lon,
                    },
                  },
                ]}
              />
              <div className="map-position-info">
                <div>위도 (y좌표) : {spaceInfo.lat}</div>
                <div>경도 (x좌표) : {spaceInfo.lon}</div>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore">
            <span>연락처</span>
            <span>{spaceInfo.tell}</span>
          </div>
        </div>
        <div className="Manage-form manage-form-space-see-more">
          <span>상세설명</span>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>대표 이미지</span>
            <div className="Manage-form-view-details-img Manage-form-view-details-img-space">
              <div>
                <img
                  src={
                    spaceInfo.imageUrl ? spaceInfo.imageUrl : "imgRectangle.svg"
                  }
                />
              </div>
              <div>
                <a
                  onClick={() => {
                    fileDownload(
                      spaceInfo.imageServerFileName,
                      spaceInfo.imageOriginFileName
                    );
                  }}
                >
                  <span>{spaceInfo.imageOriginFileName}</span>
                  <img src="\img\download_black.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>
              상세이미지
              <br />
              (*최대 5장)
            </span>
            <div className="Manage-form-view-details-img Manage-form-view-details-img-space">
              <div className="space-detail-img-container">
                {spaceInfo.spaceFiles &&
                  spaceInfo.spaceFiles.map((item, index) => {
                    return (
                      <div className="space-detail-img">
                        <img
                          className="uploaded-img"
                          src={
                            spaceInfo.imageUrl
                              ? item.imageUrl
                              : "imgRectangle.svg"
                          }
                        />
                        <div>
                          <a
                            onClick={() => {
                              fileDownload(
                                item.imageServerFileName,
                                item.imageOriginFileName
                              );
                            }}
                          >
                            <span>{item.imageOriginFileName}</span>
                            <img src="\img\download_black.svg" />
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-space-seemore manage-flex-start">
            <span>상세설명</span>
            <span>{spaceInfo.detail}</span>
          </div>
        </div>
        <div className="Manage-form manage-form-space-see-more space-see-more-gathering-list">
          <div>
            <span>모임 리스트</span>
            <span>{`${meetingCount}개`}</span>
          </div>
          <table>
            <thead>
              <tr>
                <th>모임제목</th>
                <th>모임 시작일</th>
                <th>상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {gatheringList.map((item, index) => {
                let startDate = moment(item.meetingDate)
                  .utc()
                  .format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>
                      <span>{item.title}</span>
                    </td>
                    <td>{startDate}</td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (subMenu === index) {
                            setSubMenu(null);
                          } else {
                            setSubMenu(index);
                          }
                        }}
                      >
                        <span
                          className={
                            item.state === "모집취소"
                              ? "toggled-text-red"
                              : null
                          }
                        >
                          {item.meetingStatus === "RECRUITING"
                            ? "모집중"
                            : item.meetingStatus === "COMPLETE"
                            ? "모집완료"
                            : item.meetingStatus === "END"
                            ? "종료"
                            : item.meetingStatus === "CANCEL"
                            ? "모집취소"
                            : null}
                        </span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {subMenu === index ? (
                        <ul className="table-sub-menu">
                          <li
                            onClick={() => {
                              let copyData = [...gatheringList];
                              copyData[index].meetingStatus = "RECRUITING";
                              setGatheringList(copyData);
                              meetingStatus(item.id, "RECRUITING");
                              setSubMenu(null);
                            }}
                          >
                            모집중
                          </li>
                          <li
                            onClick={() => {
                              let copyData = [...gatheringList];
                              copyData[index].meetingStatus = "COMPLETE";
                              setGatheringList(copyData);
                              meetingStatus(item.id, "COMPLETE");
                              setSubMenu(null);
                            }}
                          >
                            모집완료
                          </li>
                          <li
                            onClick={() => {
                              let copyData = [...gatheringList];
                              copyData[index].meetingStatus = "END";
                              setGatheringList(copyData);
                              meetingStatus(item.id, "END");
                              setSubMenu(null);
                            }}
                          >
                            종료
                          </li>
                          <li
                            className="toggled-text-red"
                            onClick={() => {
                              let copyData = [...gatheringList];
                              copyData[index].meetingStatus = "CANCEL";
                              setGatheringList(copyData);
                              meetingStatus(item.id, "CANCEL");
                              setSubMenu(null);
                            }}
                          >
                            모집취소
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <Link
                        to={`/admin/gathering/detail/${item.id}`}
                        state={{ id: item.id }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (meetcurrentPage <= 1) {
                    return;
                  } else {
                    setMeetCurrentPage(meetcurrentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {meetpageIndex
                .slice(meetstartIndex, meetstartIndex + 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={
                        meetcurrentPage === item ? "pagenation-focus" : null
                      }
                      onClick={() => {
                        setMeetCurrentPage(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              <a
                onClick={() => {
                  if (meetcurrentPage >= meetpageIndex.length) {
                    return;
                  } else {
                    setMeetCurrentPage(meetcurrentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        <div className="Manage-form manage-form-space-see-more space-see-more-review">
          <div>
            <span>리뷰 관리</span>
            <span>{`${reviewCount}개`}</span>
          </div>
          <table>
            <thead>
              <tr>
                <th>이메일</th>
                <th>별명</th>
                <th>작성일자</th>
                <th>평점</th>
                <th>리뷰 상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {reviewList.map((item, index) => {
                let createDate = moment(item.createdAt)
                  .utc()
                  .format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>{item.member.email}</td>
                    <td>
                      <span>{item.member.nickname}</span>
                    </td>
                    <td>{createDate}</td>
                    <td>{item.score}</td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (reviewSubMenu === index) {
                            setReviewSubMenu(null);
                          } else {
                            setReviewSubMenu(index);
                          }
                        }}
                      >
                        <span>{item.activation ? "일반" : "숨김"}</span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {reviewSubMenu === index ? (
                        <ul className="table-sub-menu">
                          <li
                            onClick={() => {
                              let copyData = [...reviewList];
                              copyData[index].activation = true;
                              setReviewList(copyData);
                              reviewActivation(item.id, true);
                              setReviewSubMenu(null);
                            }}
                          >
                            일반
                          </li>
                          <li
                            onClick={() => {
                              let copyData = [...reviewList];
                              copyData[index].activation = false;
                              setReviewList(copyData);
                              reviewActivation(item.id, false);
                              setReviewSubMenu(null);
                            }}
                          >
                            숨김
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <Link
                        to={`/admin/space/detail/review/${item.id}`}
                        state={{ id: item.id }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (reviewcurrentPage <= 1) {
                    return;
                  } else {
                    setReviewCurrentPage(reviewcurrentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {reviewpageIndex
                .slice(reviewstartIndex, reviewstartIndex + 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={
                        reviewcurrentPage === item ? "pagenation-focus" : null
                      }
                      onClick={() => {
                        setReviewCurrentPage(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              <a
                onClick={() => {
                  if (reviewcurrentPage >= reviewpageIndex.length) {
                    return;
                  } else {
                    setReviewCurrentPage(reviewcurrentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageSpaceSeeMore;
