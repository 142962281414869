import api from "./api";

export default class ManagerApi {
  static async List(data) {
    return await api.get(`manager`, data);
  }

  static async Get(id) {
    return await api.get(`manager/${id}`);
  }

  static async Del(id) {
    return await api.del(`manager/${id}`);
  }

  static async Join(Joindata) {
    return await api.post(`manager`, Joindata);
  }

  static async LogIn(LogIndata) {
    return await api.post(`manager/login`, LogIndata);
  }

  static async Patch(id, editData) {
    return await api.patch(`manager/${id}`, editData);
  }

  static async Approval(id, approvaldata) {
    return await api.post(`manager/approval/${id}`, approvaldata);
  }

  static async Active(id, activeData) {
    return await api.post(`manager/activation/${id}`, activeData);
  }

  static async PatchBase(baseData) {
    return await api.patch(`base/1`, baseData);
  }

  static async GetBase() {
    return await api.get(`base/1`);
  }

  static async GetMemberList(data) {
    return await api.get(`member`, data);
  }

  static async MemberActive(id, data) {
    return await api.post(`member/activation/${id}`, data);
  }

  static async Delmember(id) {
    return await api.del(`member/${id}`);
  }

  static async PostAuthority(id, data) {
    return await api.post(`manager/authority/${id}`, data);
  }
}
