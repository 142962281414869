import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/notice/ManageNoticeSeeMoreAdit.css";
import "../../css/Manage.css";
import NoticeApi from "../../api/noticeApi";
import { toast } from "react-toastify";

const ManageNoticeSeeMoreAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [noticeInfo, setNoticeInfo] = useState({});

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        getNoticeInfo(id);
      }
    } catch (error) {}
  }, [location]);

  const getNoticeInfo = async (id) => {
    try {
      const info = (await NoticeApi.GetNotice(id)).data.data.content;
      setNoticeInfo({
        title: info.title,
        content: info.content,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const noticeEditHandler = async () => {
    try {
      await NoticeApi.PatchNotice(location.state.id, noticeInfo);
      toast("저장되었습니다.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-notice-see-more-adit">
          <span>수정하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              className="Manage-input-textarea manage-form-notice-see-more-input"
              type="text"
              placeholder="제목을 입력하세요"
              value={noticeInfo.title}
              onChange={(e) => {
                setNoticeInfo({
                  ...noticeInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-maintext">
            <p className="Manage-input-title">본문</p>
            <textarea
              className="Manage-input-textarea manage-form-notice-see-more-textarea"
              value={noticeInfo.content}
              onChange={(e) => {
                setNoticeInfo({
                  ...noticeInfo,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </div>{" "}
          <div className="Manage-form-save manage-form-save-notice-see-more-adit">
            <button className="cursorPointer" onClick={noticeEditHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageNoticeSeeMoreAdit;
