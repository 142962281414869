import React, { useEffect, useState } from "react";
import "../../css/login/ManageLogin.css";
import { useNavigate } from "react-router-dom";
import ManagerApi from "../../api/managerApi";
import { toast } from "react-toastify";

const ManageLogin = () => {
  const navigate = useNavigate();
  const [logInColor, setLogInColor] = useState(false);
  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  const [managerId, setManagerId] = useState(null);

  let reg = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  useEffect(() => {
    if (loginData.email && loginData.password) {
      setLogInColor(true);
    } else {
      setLogInColor(false);
    }
  }, [loginData.email, loginData.password]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      if (loginData.email === null) {
        toast("이메일을 입력해주세요.");
        return;
      }
      if (loginData.password === null) {
        toast("비밀번호를 입력해주세요.");
        return;
      }
      if (!reg.test(loginData.email)) {
        toast("올바른 이메일 형식이 아닙니다.");
        return;
      }
      if (loginData.email && loginData.password) {
        await ManagerApi.LogIn(loginData).then((response) => {
          setManagerId(response.data.data.content.id);
          sessionStorage.setItem("id", response.data.data.content.id);
          sessionStorage.setItem("email", loginData.email);
          sessionStorage.setItem("token", response.data.data.content.accessToken);
          setSession(response.data.data.content.id);
        });
        navigate("/admin/home");
      }
    } catch (err) {
      const response = err.response.data.message;
      if (typeof response === "object") {
        response.map((item, index) => {
          toast(item);
        });
        return;
      }
      if (typeof response === "string") {
        toast(response);
      }
    }
  };

  const setSession = async (id) => {
    try {
      const info = (await ManagerApi.Get(id)).data.data.content;
      sessionStorage.setItem("accessMenu", info.accessMenu);
      sessionStorage.setItem("managerAuthority", info.managerAuthority);
    } catch (error) {}
  };

  return (
    <div className="Manage-login-container">
      <div className="Manage-login">
        <h1>로그인</h1>
        <form className="Manage-login-form" onSubmit={(e) => onSubmitHandler(e)}>
          <span>이메일</span>
          <input
            type="email"
            name="email"
            required
            placeholder="이메일을 입력해 주세요"
            onChange={(e) => {
              setLoginData({
                ...loginData,
                email: e.target.value,
              });
            }}
          />
          <span>비밀번호</span>
          <input
            type="password"
            name="password"
            required
            placeholder="비밀번호를 입력해 주세요"
            onChange={(e) => {
              setLoginData({
                ...loginData,
                password: e.target.value,
              });
            }}
          />
          <button
            type="submit"
            onClick={onSubmitHandler}
            className={`Manage-login-form-login cursorPointer ${logInColor ? "login-active" : null}`}
            disabled={logInColor ? false : true}
          >
            로그인
          </button>
          <button onClick={() => navigate("/admin/join")} className="Manage-login-form-registration cursorPointer">
            회원가입
          </button>
        </form>
      </div>
    </div>
  );
};

export default ManageLogin;
