import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/mileage/ManageMileageHistory.css";
import MileageApi from "../../api/mileageApi";
import { async } from "q";
import moment from "moment";
import { toast } from "react-toastify";
const ManageMileageHistory = (props) => {
  const [mileageList, setMileageList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    const getMileageList = async () => {
      try {
        const list = (await MileageApi.Get({ memberId: props.memberId })).data
          .data;

        setMileageList(list.content);
        setTotalCount(list.totalCount);
        setPostsPerPage(list.content.length);
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    getMileageList();
  }, [currentPage]);

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  return (
    <div className="Popup-container">
      <div className="Popup popup-mileage popup-mileage-history">
        <div className="Popup-title Popup-title-mileage-history">
          <span>“이메일명” 회원 포인트 내역</span>
        </div>
        <div className="mileage-popup-middle">
          <table className="manage-table-mileage-history">
            <thead>
              <tr>
                <th>일시</th>
                <th>내용</th>
                <th>만료</th>
                <th>지급포인트</th>
                <th>사용포인트</th>
              </tr>
            </thead>
            <tbody>
              {mileageList.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format(
                  "YYYY/MM/DD HH:mm:ss"
                );
                let endDate = moment(item.endDate).utc().format("YYYY-MM-DD");
                let type = Number(item.point) > 0;
                return (
                  <tr key={index}>
                    <td>{createDate}</td>
                    <td>
                      <span>{item.content}</span>
                    </td>
                    <td>{endDate}</td>
                    <td>{type ? item.point : 0}</td>
                    <td>{type ? 0 : item.point}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container manage-table-pagenation-container-mileage">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex
                .slice(startIndex, startIndex + 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={
                        currentPage === item ? "pagenation-focus" : null
                      }
                      onClick={() => {
                        setCurrentPage(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="mileage-popup-history-close"
          onClick={() => props.setHistoryOpen(false)}
        >
          <a>닫기</a>
        </div>
      </div>
    </div>
  );
};

export default ManageMileageHistory;
