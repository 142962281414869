import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/generalmember/ManageGeneralMember.css";
import "../../css/Manage.css";
import MemberApi from "../../api/memberApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { saveAs } from "file-saver";

const ManageGeneralMember = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  //------------엑셀 생성
  const downloadExcel = async () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "이름", key: "name", width: 40 },
      { header: "별명", key: "nickname", width: 40 },
      { header: "가입일", key: "createdAt", width: 40 },
      { header: "계정 상태", key: "activation", width: 40 },
    ];

    excel.map((item, index) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "일반회원 관리.xlsx");
  };
  //------------액셀 부분 끝

  //
  const [arr, setArr] = useState([]);
  const [excel, setExcel] = useState([]);

  let [tel, setTel] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getMemberList();
  }, [currentPage, location]);

  const getMemberList = async () => {
    try {
      const list = (
        await MemberApi.GetMemberList({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setMemberData(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          toast(
            <div>
              인증시간이 만료되었습니다. <br /> 다시 로그인해주세요.
            </div>
          );
          sessionStorage.clear();
          navigate("/admin/login");
          break;
        case 500:
          toast(
            `${
              err.response.data.message
                ? err.response.data.message
                : "서버에 문제가 있습니다. 잠시후 다시 시도해주세요."
            }`
          );
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  // const searchMember = async () => {
  //   try {
  //     const searchList = (
  //       await MemberApi.GetMemberList({ keyword: searchKeyword })
  //     ).data.data;
  //     console.log(searchList);
  //     setMemberData(searchList.content);
  //     setTotalCount(searchList.totalCount);
  //   } catch (error) {
  //     toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
  //   }
  // };

  const memberActivation = async (itemId, index, state) => {
    let copyData = [...memberData];
    copyData[index].activation = state;
    setMemberData(copyData);
    let obj = {
      activation: state,
    };
    try {
      await MemberApi.MemberActive(itemId, obj);
      toast("변경되었습니다.");
      getMemberList();
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const delMember = async (itemId) => {
    setIsOpen(true);
    setDeleteId(itemId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await MemberApi.DelMember(deleteId);
        toast("삭제되었습니다.");
        setAlertValue(false);
        getMemberList();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  const [accountActive, setAccountActive] = useState(null);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>일반회원 관리</span>
        <div className="manage-table-container">
          <div className="Manage-general-member-search-container">
            <div className="Manage-general-member-search-number">
              <span>전체 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-general-member-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/member/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              {/* <div>
                <a href="#">
                  <span>삭제</span>
                </a>
              </div> */}
              <div>
                <Link to={"/admin/member/message"} state={{ tel: tel }}>
                  <span>문자 보내기</span>
                </Link>
              </div>
              <div>
                <a onClick={downloadExcel} className="cursorPointer">
                  <img src="\img\download.svg" />
                  <span>엑셀로 내보내기</span>
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-general-member">
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      className="cursorPointer all-check-input"
                      type="checkbox"
                      id={"check"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let obj = [];
                          const temp = memberData.map((value) => value.id);
                          const excelTemp = memberData.map((item) => {
                            obj.push({
                              email: item.email,
                              name: item.name,
                              nickname: item.nickname,
                              createdAt: item.createdAt,
                              activation: `${item.activation ? "활성" : "비활성"}`,
                            });
                          });
                          setArr(temp);
                          setExcel(obj);
                        } else {
                          setArr([]);
                          setExcel([]);
                        }
                      }}
                    ></input>
                  </th>
                  <th>이메일</th>
                  <th>이름</th>
                  <th>별명</th>
                  <th>가입일</th>
                  <th>계정 상태</th>
                  <th>계정 삭제</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {memberData.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          id="check1"
                          className="cursorPointer"
                          checked={arr.includes(item.id)}
                          onChange={(e) => {
                            let obj = {
                              email: item.email,
                              name: item.name,
                              nickname: item.nickname,
                              createdAt: item.createdAt,
                              activation: `${item.activation ? "활성" : "비활성"}`,
                            };
                            let copyData = [...arr];
                            let copyExcel = [...excel];
                            if (e.target.checked) {
                              copyData.push(item.id);
                              copyExcel.push(obj);
                              setArr(copyData);
                              setExcel(copyExcel);

                              const telData = {
                                id: item.id,
                                name: item.name,
                                tel: item.phoneNumber,
                              };
                              tel.push(telData);
                              setTel([...tel]);
                            } else {
                              let arrTemp = copyData.filter((item02) => item02 !== item.id);
                              let excelTemp = copyExcel.filter(
                                (item02) => item02 !== copyExcel.find((e) => e.email === item.email)
                              );
                              setArr(arrTemp);
                              setExcel(excelTemp);
                              tel = tel.filter((item2) => item2.id !== item.id);
                              setTel([...tel]);
                            }
                          }}
                        ></input>
                        <label htmlFor="check1"></label>
                      </td>
                      <td>{item.email}</td>
                      <td>
                        <span>{item.name}</span>
                      </td>
                      <td>
                        <span>{item.nickname}</span>
                      </td>
                      <td>{createDate}</td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (accountActive === index) {
                              setAccountActive(null);
                            } else {
                              setAccountActive(index);
                            }
                          }}
                        >
                          <span>{item.activation ? "활성" : "비활성"}</span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {accountActive === index ? (
                          <ul className="table-sub-menu">
                            <li
                              onClick={() => {
                                // let copyData = [...memberData];
                                // copyData[index].activation = true;
                                // setMemberData(copyData);
                                memberActivation(item.id, index, true);
                                setAccountActive(null);
                              }}
                            >
                              활성
                            </li>
                            <li
                              onClick={() => {
                                // let copyData = [...memberData];
                                // copyData[index].activation = false;
                                // setMemberData(copyData);
                                memberActivation(item.id, index, false);
                                setAccountActive(null);
                              }}
                            >
                              비활성
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <div>
                          <a onClick={() => delMember(item.id)}>삭제</a>
                        </div>
                      </td>
                      <td>
                        <Link to={`/admin/member/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/member/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/member/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/member/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageGeneralMember;
