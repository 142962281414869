import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import "../../css/gathering/ManageGatheringInquiry.css";
import "../../css/Manage.css";
import ManageGatheringInquirySeeMore from "./ManageGatheringInquirySeeMore";
import MeetingAskApi from "../../api/meetingAskApi";
import moment from "moment";
import { toast } from "react-toastify";

const ManageGatheringInquiry = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, page } = params;

  const [askId, setAskId] = useState(null);
  const [askInfo, setAskInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (params) {
      setAskId(params.id);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(page ? Number(page) : 1);
    getAsk();
  }, [currentPage, location]);

  const getAsk = async () => {
    try {
      const list = (
        await MeetingAskApi.Get({
          page: currentPage,
          size: 10,
          meetingId: props.meetingId,
        })
      ).data.data;
      setAskInfo(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const [popup, setPopup] = useState(false);

  let searchRef = useRef(null);

  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setPopup(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <div>
      <section>
        <div className="Manage-form manage-form-gathering-inquiry">
          <div className="gathering-inquiry-title">
            <span>문의</span>
            <span>{totalCount}개</span>
          </div>
          <table className="gathering-inquiry-table">
            <thead>
              <tr>
                <th>이메일</th>
                <th>문의내용</th>
                <th>작성일자</th>
                <th>상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {askInfo.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>{item.member.email}</td>
                    <td>
                      <span>{item.question}</span>
                    </td>
                    <td>{createDate}</td>
                    <td>
                      <span className={!item.status ? "table-waiting-answer" : "table-complete-answer"}>
                        {item.status ? "답변완료" : "답변대기"}
                      </span>
                    </td>
                    <td>
                      <a
                        onClick={() => {
                          setSelectedIndex(index);
                          setPopup(!popup);
                        }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    navigate(`/admin/gathering/detail/${id}/${currentPage - 1}`, {
                      key: 4,
                      state: {
                        id: "문의",
                      },
                    });
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                return (
                  <a
                    key={index}
                    className={currentPage === item ? "pagenation-focus" : null}
                    onClick={() => {
                      navigate(`/admin/gathering/detail/${id}/${item}`, {
                        key: 4,
                        state: {
                          id: "문의",
                        },
                      });
                    }}
                  >
                    {item}
                  </a>
                );
              })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    navigate(`/admin/gathering/detail/${id}/${currentPage + 1}`, {
                      key: 4,
                      state: {
                        id: "문의",
                      },
                    });
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {popup && (
        <ManageGatheringInquirySeeMore
          searchRef={searchRef}
          askId={askId}
          askInfo={askInfo}
          setAskInfo={setAskInfo}
          setPopup={setPopup}
          getAsk={getAsk}
          selectedIndex={selectedIndex}
        />
      )}
    </div>
  );
};

export default ManageGatheringInquiry;
