import api from "./api";

export default class NoticeApi {
  static async PostNotice(data) {
    return await api.post(`notice`, data);
  }
  static async Get(data) {
    return await api.get(`notice`, data);
  }
  static async GetNotice(id) {
    return await api.get(`notice/${id}`);
  }
  static async DelNotice(id) {
    return await api.del(`notice/${id}`);
  }

  static async PatchNotice(id, data) {
    return await api.patch(`notice/${id}`, data);
  }
}
