import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/faq/ManageFaq.css";
import "../../css/Manage.css";
import ManageFaqDetail from "./ManageFaqDetail";
import ManageCatagory from "../catagory/ManageCatagory";
import moment from "moment";
import FaqApi from "../../api/faqApi";

const ManageFaq = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [faqToggle, setFaqToggle] = useState([
    { label: "faq 관리", value: true },
    { label: "카테고리 관리", value: false },
  ]);

  useEffect(() => {
    if (location.state) {
      let stateIndex = faqToggle.findIndex((item) => item.label === location.state.id);
      let copyData = [...faqToggle];

      copyData.map((item, index) => {
        item.value = false;
      });
      copyData[stateIndex].value = true;
      setFaqToggle(copyData);
    }
  }, [location]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="Manage-faq-catagory">
          {faqToggle.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate("/admin/faq/1", {
                    key: index,
                    state: {
                      id: item.label,
                    },
                  });
                }}
                className={item.value === true ? "gathering-menu-active" : ""}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        {faqToggle[0].value ? <ManageFaqDetail /> : null}
        {faqToggle[1].value ? <ManageCatagory /> : null}
      </section>
    </div>
  );
};

export default ManageFaq;
