import api from "./api";

export default class AlarmApi {
  static async Get(data) {
    return await api.get(`alarm`, data);
  }

  static async GetAlarm(id) {
    return await api.get(`alarm/${id}`);
  }

  static async Post(data) {
    return await api.post(`alarm`, data);
  }
}
