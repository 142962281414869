import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/notice/ManageNotice.css";
import "../../css/Manage.css";
import NoticeApi from "../../api/noticeApi";
import moment from "moment";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { toast } from "react-toastify";

const ManageNotice = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [noticeList, setNoticeList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getNoticeList();
  }, [currentPage, location]);

  const getNoticeList = async () => {
    try {
      const list = (
        await NoticeApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setNoticeList((noticeList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>공지사항 관리</span>
        <div className="manage-table-container">
          <div className="Manage-notice-search-container">
            <div className="Manage-notice-search-number">
              <span>전체 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-notice-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/member/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              <div onClick={() => navigate("/admin/notice/add")} className="cursorPointer">
                <img src="\img\sidebar-plus-icon.svg" />
                <span>추가하기</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-notice">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>제목</th>
                  <th>게시 관리자 이메일</th>
                  <th>게시일자</th>
                  <th>더보기</th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr>
                      <td>{totalCount - (index + (currentPage - 1) * 10)}</td>
                      <td>
                        <span>{item.title}</span>
                      </td>
                      <td>{item.manager.email}</td>
                      <td>{createDate}</td>
                      <td>
                        <Link to={`/admin/notice/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/notice/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/notice/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/notice/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageNotice;
