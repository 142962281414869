import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "../../css/notice/ManageNoticeSeeMoreAdd.css";
import "../../css/Manage.css";
import NoticeApi from "../../api/noticeApi";
import { toast } from "react-toastify";

const ManageNoticeSeeMoreAdd = () => {
  let navigate = useNavigate();

  const [noticeAdd, setNoticeAdd] = useState({
    title: "",
    content: "",
    managerId: 1,
  });

  const noticeAddHandler = async () => {
    try {
      await NoticeApi.PostNotice(noticeAdd);
      toast("등록되었습니다.");
      navigate("/admin/notice/1");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-notice-see-more-add">
          <span>추가하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              className="Manage-input-textarea manage-form-notice-see-more-input"
              type="text"
              placeholder="제목을 입력하세요"
              onChange={(e) => {
                setNoticeAdd({
                  ...noticeAdd,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container manage-flex-start">
            <p className="Manage-input-title">본문</p>
            <textarea
              className="Manage-input-textarea manage-form-notice-see-more-textarea"
              onChange={(e) => {
                setNoticeAdd({
                  ...noticeAdd,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </div>{" "}
          <div className="Manage-form-save manage-form-save-notice-see-more-add">
            <button onClick={noticeAddHandler}>등록하기</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageNoticeSeeMoreAdd;
