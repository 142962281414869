import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/gathering/ManageGatheringScheduleEnroll.css";
import "../../css/Manage.css";
import ManageGatheringProgramAdd from "./ManageGatheringProgramAdd";
import moment from "moment";
import { toast } from "react-toastify";
import MeetingApi from "../../api/meetingApi";
import { nullCheck } from "../../utils/check";
import "moment/locale/ko";
import AlertPopup from "../../components/AlertPopup";

const ManageGatheringScheduleEnroll = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [meetingId, setMeetingId] = useState(0);
  const [addProgramPopupFlag, setAddProgramPopupFlag] = useState([-1, -1]);
  const [popupFlag, setPopupFlag] = useState(false);
  const [spaceId, setSpaceId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [deleteImageIds, setDeleteImageIds] = useState([]);
  const [deleteMeetingSchduleProgramIds, setdeleteMeetingSchduleProgramIds] =
    useState([]);
  // 일정 목록
  const [meetingSchedules, setMeetingSchedules] = useState([]);

  useEffect(() => {
    if (alertValue) {
      deleteMeetingScheduleAll(params.id);
      // for (let index = 0; index < meetingSchedules.length; index++) {
      //   const element = meetingSchedules[index];

      //   if (!nullCheck(element.meetingSchedulePrograms)) {
      //     for (
      //       let index = 0;
      //       index < element.meetingSchedulePrograms.length;
      //       index++
      //     ) {
      //       const element2 = element.meetingSchedulePrograms[index];
      //       deleteMeetingScheduleProgram(element2.id);
      //     }
      //   }
      //   deleteMeetingSchedule(element.id);
      // }
      postScheduleDate();
    }
  }, [alertValue]);

  async function deleteMeetingScheduleAll(id) {
    try {
      await MeetingApi.deleteMeetingScheduleAll(id);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // mounted
  useEffect(() => {
    getMeetingInfo();
    getMeetingScheduleInfo();
    setMeetingId(params.id);
    getProgramList();
  }, []);

  // 미팅 정보
  const [meeting, setMeeting] = useState({});

  async function getMeetingInfo() {
    try {
      const response = await MeetingApi.getMeeting(params.id);
      const data = response.data.data.content;
      setSpaceId(data.space.id);
      setStartDate(moment(new Date(data.startDate)).utc().format("yyyy-MM-DD"));
      setEndDate(moment(new Date(data.endDate)).utc().format("yyyy-MM-DD"));
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // 일정 정보
  async function getMeetingScheduleInfo() {
    try {
      const response = await MeetingApi.getMeetingSchedule({
        meetingId: params.id,
      });
      const data = response.data.data.content;

      if (data.length > 0) {
        setMeetingSchedules(
          data.map((item) => {
            return {
              ...item,
              openDate: moment(new Date(item.openDate)).format("yyyy-MM-DD"),
              images: item.meetingScheduleFiles,

              meetingSchedulePrograms: item.meetingSchedulePrograms.map(
                (item2) => {
                  return {
                    ...item2,
                    images: item2.type ? [] : item2.meetingScheduleProgramFiles,
                    programFiles: item2.type
                      ? item2.meetingScheduleProgramFiles
                      : [],
                    programFilesChange: item2.type ? false : "",
                    deleteImageIds: [],
                  };
                }
              ),
            };
          })
        );
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // 모임기간 등록
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  async function postScheduleDate() {
    try {
      if (nullCheck(startDate)) {
        toast("시작 일정를 입력해주세요.");
        return;
      }

      if (nullCheck(endDate)) {
        toast("종료 일정을 입력해주세요.");
        return;
      }

      if (moment(new Date(endDate)).isBefore(moment(new Date(startDate)))) {
        toast("종료 일정은 시작 일정 이후로 입력해주세요.");
        return;
      }

      const requestDto = {
        meetingId: meetingId,
        startDate: moment(new Date(startDate))
          .hours(0)
          .format("yyyy-MM-DD HH:mm:ss"),
        endDate: moment(new Date(endDate))
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format("yyyy-MM-DD HH:mm:ss"),
      };

      await MeetingApi.postScheduleDate(requestDto);
      const days =
        moment(new Date(endDate)).diff(moment(new Date(startDate)), "days") + 1;

      const meetingSchedules = [];
      for (let index = 0; index < days; index++) {
        const meetingSchedule = {
          meetingId: meetingId,
          openDate: moment(new Date(startDate))
            .add(index, "day")
            .format("yyyy-MM-DD"),
          title: "",
          startTime: "10:00",
          endTime: "10:00",
          vehicle: "",
          content: "",
          images: [],
          meetingSchedulePrograms: [],
        };
        meetingSchedules.push(meetingSchedule);
      }
      setMeetingSchedules([...meetingSchedules]);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // 파일 업로드
  const inputFiles = (e, index) => {
    const meetingSchedule = meetingSchedules[index];

    if (meetingSchedule.images.length === 5) {
      toast("이미지는 최대 5장까지 등록가능합니다.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = {
          imageOriginFileName: file.name,
          imageUrl: e.target.result,
          file: file,
        };
        meetingSchedule.images.push(image);
        setMeetingSchedules([...meetingSchedules]);
      };
    }
    e.target.value = "";
  };
  const deleteFiles = (index, imageIndex) => {
    const meetingSchedule = meetingSchedules[index];
    if (meetingSchedule.images[imageIndex].id) {
      deleteImageIds.push(meetingSchedule.images[imageIndex].id);
    }
    meetingSchedule.images = meetingSchedule.images.filter(
      (image, currentIndex) => currentIndex !== imageIndex
    );
    setMeetingSchedules([...meetingSchedules]);
  };

  const inputScheduleProgramFiles = (e, index, meetingScheduleProgramIndex) => {
    const meetingSchedule = meetingSchedules[index];
    const meetingScheduleProgram =
      meetingSchedule.meetingSchedulePrograms[meetingScheduleProgramIndex];

    if (meetingScheduleProgram.type) {
      if (meetingScheduleProgram.programFiles.length === 5) {
        toast("이미지는 최대 5장까지 등록가능합니다.");
        return;
      }
    } else {
      if (meetingScheduleProgram.images.length === 5) {
        toast("이미지는 최대 5장까지 등록가능합니다.");
        return;
      }
    }

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = {
          imageOriginFileName: file.name,
          imageUrl: e.target.result,
          file: file,
        };
        meetingScheduleProgram.images.push(image);
        if (meetingScheduleProgram.type) {
          meetingScheduleProgram.programFiles.push(image);
        }
        setMeetingSchedules([...meetingSchedules]);
      };
    }
    e.target.value = "";
  };
  const deleteScheduleProgramFiles = (
    index,
    meetingScheduleProgramIndex,
    meetingScheduleProgramIamgeIndex
  ) => {
    const meetingSchedule = meetingSchedules[index];
    const meetingScheduleProgram =
      meetingSchedule.meetingSchedulePrograms[meetingScheduleProgramIndex];
    if (meetingScheduleProgram.type) {
      if (
        meetingScheduleProgram.programFiles[meetingScheduleProgramIamgeIndex].id
      ) {
        meetingScheduleProgram.deleteImageIds.push(
          meetingScheduleProgram.programFiles[meetingScheduleProgramIamgeIndex]
            .id
        );
      }
    } else {
      if (meetingScheduleProgram.images[meetingScheduleProgramIamgeIndex].id) {
        meetingScheduleProgram.deleteImageIds.push(
          meetingScheduleProgram.images[meetingScheduleProgramIamgeIndex].id
        );
      }
    }

    meetingScheduleProgram.images = meetingScheduleProgram.images.filter(
      (image, currentIndex) => currentIndex !== meetingScheduleProgramIamgeIndex
    );
    if (meetingScheduleProgram.type) {
      meetingScheduleProgram.programFiles =
        meetingScheduleProgram.programFiles.filter(
          (image, currentIndex) =>
            currentIndex !== meetingScheduleProgramIamgeIndex
        );
    }
    setMeetingSchedules([...meetingSchedules]);
  };

  // 프로그램 세팅
  const [programList, setProgramList] = useState([]);

  const getProgramList = async () => {
    try {
      const response = await MeetingApi.getMeetingProgramList({
        size: 1000,
        meetingId: params.id,
      });
      const data = response.data.data;
      setProgramList(data.content.map((item) => item.program));
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };
  let saveDisableFlag = 0;
  // 저장
  const save = async () => {
    try {
      saveDisableFlag += 1;
      if (saveDisableFlag === 1) {
        // await MeetingApi.deleteMeetingScheduleAll(meetingId)
        for (let index = 0; index < meetingSchedules.length; index++) {
          const meetingSchedule = meetingSchedules[index];
          const requestDto1 = new FormData();
          let meetingScheduleId = 0;
          if (meetingSchedule.id) {
            requestDto1.append(
              "openDate",
              moment(new Date(meetingSchedule.openDate))
                .hours(0)
                .format("yyyy-MM-DD HH:mm:ss")
            );
            requestDto1.append("title", meetingSchedule.title);
            requestDto1.append("startTime", meetingSchedule.startTime);
            requestDto1.append("endTime", meetingSchedule.endTime);
            requestDto1.append("vehicle", meetingSchedule.vehicle);
            requestDto1.append("content", meetingSchedule.content);

            if (!nullCheck(meetingSchedule.images)) {
              for (
                let index = 0;
                index < meetingSchedule.images.length;
                index++
              ) {
                const element1 = meetingSchedule.images[index].file;
                if (element1) {
                  requestDto1.append("images", element1);
                }
              }
            }

            if (!nullCheck(deleteImageIds)) {
              for (let index = 0; index < deleteImageIds.length; index++) {
                requestDto1.append("deleteImageIds", deleteImageIds[index]);
              }
            }

            await MeetingApi.patchMeetingSchedule(
              meetingSchedule.id,
              requestDto1
            );
            meetingScheduleId = meetingSchedule.id;
            if (deleteMeetingSchduleProgramIds) {
              for (
                let index = 0;
                index < deleteMeetingSchduleProgramIds.length;
                index++
              ) {
                await MeetingApi.deleteMeetingScheduleProgram(
                  deleteMeetingSchduleProgramIds[index]
                );
              }
            }
          } else {
            requestDto1.append("meetingId", meetingId);
            requestDto1.append(
              "openDate",
              moment(new Date(meetingSchedule.openDate))
                .hours(0)
                .format("yyyy-MM-DD HH:mm:ss")
            );
            requestDto1.append("title", meetingSchedule.title);
            requestDto1.append("startTime", meetingSchedule.startTime);
            requestDto1.append("endTime", meetingSchedule.endTime);
            requestDto1.append("vehicle", meetingSchedule.vehicle);
            requestDto1.append("content", meetingSchedule.content);

            if (!nullCheck(meetingSchedule.images)) {
              for (
                let index = 0;
                index < meetingSchedule.images.length;
                index++
              ) {
                const element1 = meetingSchedule.images[index].file;
                requestDto1.append("images", element1);
              }
            }

            const response = await MeetingApi.postMeetingSchedule(requestDto1);
            meetingScheduleId = response.data.data.content;
          }

          if (
            !nullCheck(meetingSchedule.meetingSchedulePrograms) &&
            meetingScheduleId
          ) {
            for (
              let index2 = 0;
              index2 < meetingSchedule.meetingSchedulePrograms.length;
              index2++
            ) {
              const element2 = meetingSchedule.meetingSchedulePrograms[index2];
              const requestDto2 = new FormData();
              if (element2.id) {
                requestDto2.append("title", element2.title);
                requestDto2.append("startTime", element2.startTime);
                requestDto2.append("endTime", element2.endTime);
                requestDto2.append("content", element2.content);

                if (element2.type) {
                  requestDto2.append(
                    "baseMeetingProgramId",
                    element2.baseMeetingProgramId
                  );
                } else {
                  requestDto2.append("vehicle", element2.vehicle);
                }

                if (!nullCheck(element2.images)) {
                  for (let index = 0; index < element2.images.length; index++) {
                    const element3 = element2.images[index].file;
                    if (element3) {
                      requestDto2.append("images", element3);
                    }
                  }
                }

                if (!nullCheck(element2.deleteImageIds)) {
                  for (
                    let index = 0;
                    index < element2.deleteImageIds.length;
                    index++
                  ) {
                    requestDto2.append(
                      "deleteImageIds",
                      element2.deleteImageIds[index]
                    );
                  }
                }

                if (element2.programFilesChange) {
                  for (
                    let index = 0;
                    index < element2.programFiles.length;
                    index++
                  ) {
                    const element4 = element2.programFiles[index];
                    if (!nullCheck(element4.id)) {
                      requestDto2.append(
                        `programFiles[${index}][imageUrl]`,
                        element4.imageUrl
                      );
                      requestDto2.append(
                        `programFiles[${index}][imageOriginFileName]`,
                        element4.imageOriginFileName
                      );
                      requestDto2.append(
                        `programFiles[${index}][imageServerFileName]`,
                        element4.imageServerFileName
                      );
                    }
                  }
                }

                await MeetingApi.patchMeetingScheduleProgram(
                  element2.id,
                  requestDto2
                );
              } else {
                requestDto2.append("meetingScheduleId", meetingScheduleId);
                requestDto2.append("title", element2.title);
                requestDto2.append("startTime", element2.startTime);
                requestDto2.append("endTime", element2.endTime);
                requestDto2.append("content", element2.content);
                if (element2.type) {
                  requestDto2.append(
                    "baseMeetingProgramId",
                    element2.baseMeetingProgramId
                  );
                } else {
                  requestDto2.append("vehicle", element2.vehicle);
                }

                if (!nullCheck(element2.images)) {
                  for (let index = 0; index < element2.images.length; index++) {
                    const element3 = element2.images[index].file;
                    requestDto2.append("images", element3);
                  }
                }

                if (!nullCheck(element2.programFiles)) {
                  for (
                    let index = 0;
                    index < element2.programFiles.length;
                    index++
                  ) {
                    const element4 = element2.programFiles[index];
                    if (!nullCheck(element4.id)) {
                      requestDto2.append(
                        `programFiles[${index}][imageUrl]`,
                        element4.imageUrl
                      );
                      requestDto2.append(
                        `programFiles[${index}][imageOriginFileName]`,
                        element4.imageOriginFileName
                      );
                      requestDto2.append(
                        `programFiles[${index}][imageServerFileName]`,
                        element4.imageServerFileName
                      );
                    }
                  }
                }

                await MeetingApi.postMeetingScheduleProgram(requestDto2);
              }
            }
          }
        }
        toast("저장되었습니다.");
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-schedule">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-schedule-add">
          <div>
            <span>일정관리</span>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title-schedule">모임기간*</p>
            <div className="Manage-date-postdate gathering-period-schedule">
              <div>
                <input
                  type="date"
                  required
                  data-placeholder="NNNN-NN-NN"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(
                      moment(new Date(e.target.value)).format("yyyy-MM-DD")
                    );
                  }}
                />
              </div>
              <span>~</span>
              <div>
                <input
                  type="date"
                  required
                  data-placeholder="NNNN-NN-NN"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(
                      moment(new Date(e.target.value)).format("yyyy-MM-DD")
                    );
                  }}
                />
              </div>
              <a
                onClick={(e) => {
                  if (meetingSchedules.length > 0) {
                    setIsOpen(true);
                    return;
                  }
                  postScheduleDate();
                }}
              >
                등록
              </a>
            </div>
          </div>

          {meetingSchedules.map((item, index) => {
            return (
              <div
                className="schedule-eroll-container schedule-eroll-container-first"
                key={index}
              >
                <div className="schedule-date">
                  <span>
                    {index + 1}일차 {item.openDate} (
                    {moment(new Date(item.openDate))
                      .format("dddd", "ko")
                      .substring(0, 1)}
                    )
                  </span>
                </div>
                <div className="schedule-enroll-add-btn">
                  <span>*첫번째 일정은 장소 일정만 추가 가능합니다.</span>
                  <div>
                    <a
                      onClick={(e) => {
                        const length = item.meetingSchedulePrograms.length;

                        if (
                          nullCheck(item.endTime) ||
                          (length > 0 &&
                            nullCheck(
                              item.meetingSchedulePrograms[length - 1].endTime
                            ))
                        ) {
                          toast("일정 시간을 입력해주세요.");
                          return;
                        }

                        const meetingSchedule = meetingSchedules[index];
                        let meetingScheduleProgram = {};
                        if (item.meetingSchedulePrograms.length > 0) {
                          meetingScheduleProgram = {
                            type: false,
                            meetingScheduleId: "",
                            title: "",
                            startTime:
                              item.meetingSchedulePrograms[length - 1].endTime,
                            endTime: "",
                            vehicle: "",
                            content: "",
                            baseMeetingProgramId: "",
                            images: [],
                            programFiles: [],
                          };
                        } else {
                          meetingScheduleProgram = {
                            type: false,
                            meetingScheduleId: "",
                            title: "",
                            startTime: item.endTime,
                            endTime: "",
                            vehicle: "",
                            content: "",
                            baseMeetingProgramId: "",
                            images: [],
                            programFiles: [],
                          };
                        }
                        meetingSchedule.meetingSchedulePrograms.push(
                          meetingScheduleProgram
                        );
                        setMeetingSchedules([...meetingSchedules]);
                      }}
                    >
                      장소 일정 추가
                    </a>
                    <a
                      onClick={(e) => {
                        const length = item.meetingSchedulePrograms.length;

                        if (
                          nullCheck(item.endTime) ||
                          (length > 0 &&
                            nullCheck(
                              item.meetingSchedulePrograms[length - 1].endTime
                            ))
                        ) {
                          toast("일정 시간을 입력해주세요.");
                          return;
                        }

                        const meetingSchedule = meetingSchedules[index];
                        let meetingScheduleProgram = {};
                        if (item.meetingSchedulePrograms.length > 0) {
                          meetingScheduleProgram = {
                            type: true,
                            meetingScheduleId: "",
                            title: "프로그램을 선택하세요.",
                            startTime:
                              item.meetingSchedulePrograms[length - 1].endTime,
                            endTime: "",
                            vehicle: "",
                            content: "",
                            baseMeetingProgramId: "",
                            images: [],
                            programFiles: [],
                          };
                        } else {
                          meetingScheduleProgram = {
                            type: true,
                            meetingScheduleId: "",
                            title: "프로그램을 선택하세요.",
                            startTime: item.endTime,
                            endTime: "",
                            vehicle: "",
                            content: "",
                            baseMeetingProgramId: "",
                            images: [],
                            programFiles: [],
                          };
                        }
                        meetingSchedule.meetingSchedulePrograms.push(
                          meetingScheduleProgram
                        );
                        setMeetingSchedules([...meetingSchedules]);
                        getProgramList();
                      }}
                    >
                      프로그램 일정 추가
                    </a>
                  </div>
                </div>
                <div className="schedule-enroll-information">
                  <div className="Manage-input-container">
                    <p className="Manage-input-title">일정명*</p>
                    <input
                      className="Manage-input-textarea manage-form-schedule-enroll"
                      type="text"
                      placeholder="일정명을 입력하세요"
                      required
                      value={item.title}
                      onChange={(e) => {
                        setMeetingSchedules(
                          meetingSchedules.map(
                            (meetingSchedule, currentIndex) => {
                              if (index === currentIndex) {
                                return {
                                  ...meetingSchedule,
                                  title: e.target.value,
                                };
                              } else {
                                return {
                                  ...meetingSchedule,
                                };
                              }
                            }
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="Manage-input-container">
                    <p className="Manage-input-title">시간*</p>
                    <div className="Manage-time-schedule gathering-period-schedule gathering-period-schedule-enroll">
                      <input
                        type="time"
                        required
                        data-placeholder="NNNN-NN-NN"
                        // value={nullCheck(item.startTime) ? '09:00' : item.startTime}
                        value={item.startTime}
                        onChange={(e) => {
                          // if (
                          //   moment(item.endTime, "HH:mm").isBefore(
                          //     moment(e.target.value, "HH:mm")
                          //   )
                          // ) {
                          //   toast("종료 일정은 시작 일정 이후로 입력해주세요.");
                          //   e.target.value = "";
                          //   return;
                          // }
                          setMeetingSchedules(
                            meetingSchedules.map(
                              (meetingSchedule, currentIndex) => {
                                if (index === currentIndex) {
                                  return {
                                    ...meetingSchedule,
                                    startTime: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...meetingSchedule,
                                  };
                                }
                              }
                            )
                          );
                        }}
                      />
                      <span>~</span>
                      <input
                        type="time"
                        required
                        data-placeholder="NNNN-NN-NN"
                        // value={nullCheck() ? '12:00' : item.endTime}
                        value={item.endTime}
                        onChange={(e) => {
                          // if (
                          //   moment(e.target.value, "HH:mm").isBefore(
                          //     moment(item.startTime, "HH:mm")
                          //   )
                          // ) {
                          //   toast("종료 일정은 시작 일정 이후로 입력해주세요.");
                          //   e.target.value = "";
                          //   return;
                          // }
                          if (item.meetingSchedulePrograms.length > 0) {
                            for (
                              let index = 0;
                              index < item.meetingSchedulePrograms.length;
                              index++
                            ) {
                              const element =
                                item.meetingSchedulePrograms[index];
                              const startTime = element.startTime;
                              const endTime = element.endTime;
                              const diff = moment.duration(
                                moment(endTime, "HH:mm").diff(
                                  moment(startTime, "HH:mm")
                                )
                              );
                              if (index < 1) {
                                const newTime = moment(e.target.value, "HH:mm")
                                  .add(diff, "minutes")
                                  .format("HH:mm");
                                element.startTime = e.target.value;
                                element.endTime = nullCheck(element.endTime)
                                  ? ""
                                  : newTime;
                              } else {
                                element.startTime =
                                  item.meetingSchedulePrograms[
                                    index - 1
                                  ].endTime;

                                const newTime = moment(
                                  element.startTime,
                                  "HH:mm"
                                )
                                  .add(diff, "minutes")
                                  .format("HH:mm");

                                element.endTime = nullCheck(element.endTime)
                                  ? ""
                                  : newTime;
                              }
                            }
                          }
                          setMeetingSchedules(
                            meetingSchedules.map(
                              (meetingSchedule, currentIndex) => {
                                if (index === currentIndex) {
                                  return {
                                    ...meetingSchedule,
                                    endTime: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...meetingSchedule,
                                  };
                                }
                              }
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="Manage-input-container">
                    <p className="Manage-input-title">이동수단*</p>
                    <input
                      className="Manage-input-textarea manage-form-schedule-enroll"
                      type="text"
                      placeholder="ex)대중교통, 버스, 지하철, 자동차 등"
                      required
                      value={item.vehicle}
                      onChange={(e) => {
                        setMeetingSchedules(
                          meetingSchedules.map(
                            (meetingSchedule, currentIndex) => {
                              if (index === currentIndex) {
                                return {
                                  ...meetingSchedule,
                                  vehicle: e.target.value,
                                };
                              } else {
                                return {
                                  ...meetingSchedule,
                                };
                              }
                            }
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="Manage-input-container manage-input-container-detail">
                    <p className="Manage-input-title">상세설명*</p>
                    <textarea
                      className="Manage-input-textarea-detail"
                      placeholder="상세설명을 입력하세요"
                      value={item.content}
                      onChange={(e) => {
                        setMeetingSchedules(
                          meetingSchedules.map(
                            (meetingSchedule, currentIndex) => {
                              if (index === currentIndex) {
                                return {
                                  ...meetingSchedule,
                                  content: e.target.value,
                                };
                              } else {
                                return {
                                  ...meetingSchedule,
                                };
                              }
                            }
                          )
                        );
                      }}
                    ></textarea>
                  </div>
                  <div className="Manage-input-container Manage-input-container-gathering-add">
                    <span className="Manage-input-title">
                      이미지 (*최대 5장)
                    </span>
                    <div className="schedule-info-img">
                      <label htmlFor={`img-input_${index}`}>
                        <img src="\img\img-registration.svg" />
                        <span>이미지 등록</span>
                      </label>
                      <input
                        id={`img-input_${index}`}
                        className="Manage-input-textarea manage-form-gathering-add-input"
                        type="file"
                        required
                        onChange={(e) => {
                          inputFiles(e, index);
                        }}
                      />

                      {item.images?.map((image, iamgeIndex) => (
                        <div
                          key={iamgeIndex}
                          className="schedule-info-img-list"
                          style={{ marginRight: "13px" }}
                        >
                          <img
                            src={image.imageUrl}
                            style={{ width: "90px", height: "90px" }}
                          />
                          <img
                            src="\img\image-cancel.svg"
                            onClick={(e) => {
                              deleteFiles(index, iamgeIndex);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {meetingSchedules[index].meetingSchedulePrograms.map(
                  (meetingScheduleProgram, meetingScheduleProgramIndex) => {
                    return (
                      <>
                        {meetingScheduleProgram.type ? (
                          <div
                            key={meetingScheduleProgramIndex}
                            className="schedule-enroll-information schedule-enroll-information-program"
                          >
                            <div className="Manage-input-container">
                              <p className="Manage-input-title">프로그램*</p>
                              <div className="select">
                                <div
                                  className="selected"
                                  onClick={() => {
                                    const temp = addProgramPopupFlag;

                                    if (
                                      temp[0] === index &&
                                      temp[1] === meetingScheduleProgramIndex
                                    ) {
                                      temp[0] = -1;
                                      temp[1] = -1;
                                    } else {
                                      temp[0] = index;
                                      temp[1] = meetingScheduleProgramIndex;
                                    }
                                    setAddProgramPopupFlag([...temp]);
                                  }}
                                >
                                  <div
                                    className={
                                      meetingScheduleProgram.baseMeetingProgramId ===
                                      ""
                                        ? "selected-value-basic"
                                        : "selected-value"
                                    }
                                  >
                                    {meetingScheduleProgram.title}
                                  </div>
                                  <div className="select-toggle-btn">
                                    <img src="\img\table-togle.svg" />
                                  </div>
                                </div>
                                {addProgramPopupFlag[0] === index &&
                                addProgramPopupFlag[1] ===
                                  meetingScheduleProgramIndex ? (
                                  <ul className="option-container">
                                    {programList.map(
                                      (meetingProgram, meetingProgramIndex) => {
                                        return (
                                          <li
                                            className="option"
                                            onClick={() => {
                                              meetingScheduleProgram.programFilesChange = true;
                                              if (
                                                meetingScheduleProgram
                                                  .programFiles.length > 0
                                              ) {
                                                for (
                                                  let index = 0;
                                                  index <
                                                  meetingScheduleProgram
                                                    .programFiles.length;
                                                  index++
                                                ) {
                                                  const element =
                                                    meetingScheduleProgram
                                                      .programFiles[index];
                                                  if (element.id) {
                                                    meetingScheduleProgram.deleteImageIds.push(
                                                      element.id
                                                    );
                                                  }
                                                }
                                              }

                                              meetingScheduleProgram.title =
                                                meetingProgram.title;
                                              meetingScheduleProgram.baseMeetingProgramId =
                                                meetingProgram.id;
                                              meetingScheduleProgram.programFiles =
                                                meetingProgram.programFiles.map(
                                                  (image) => {
                                                    return {
                                                      ...image,
                                                    };
                                                  }
                                                );
                                              meetingScheduleProgram.images =
                                                [];
                                              meetingScheduleProgram.content =
                                                meetingProgram.content;

                                              meetingScheduleProgram.startTime =
                                                meetingScheduleProgramIndex ===
                                                0
                                                  ? meetingSchedules[index]
                                                      .endTime
                                                  : meetingSchedules[index]
                                                      .meetingSchedulePrograms[
                                                      meetingScheduleProgramIndex -
                                                        1
                                                    ].endTime;
                                              meetingScheduleProgram.endTime =
                                                moment(
                                                  meetingScheduleProgram.startTime,
                                                  "HH:mm"
                                                )
                                                  .add(
                                                    meetingProgram.requiredTime,
                                                    "hours"
                                                  )
                                                  .format("HH:mm");
                                              setMeetingSchedules([
                                                ...meetingSchedules,
                                              ]);
                                              const temp = addProgramPopupFlag;
                                              temp[0] = -1;
                                              temp[1] = -1;
                                              setAddProgramPopupFlag([...temp]);
                                            }}
                                          >
                                            {meetingProgram.title}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : null}
                              </div>
                              <a
                                style={{
                                  height: "29px",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  lineHeight: "23px",
                                  textAlign: "center",
                                  color: "#262c31",
                                  display: "block",
                                  boxSizing: "border-box",
                                  padding: "3px 8px",
                                  background: "#ffffff",
                                  border: "1px solid #d7dbe0",
                                  borderRadius: "4px",
                                  marginLeft: "8px",
                                }}
                                onClick={(e) => {
                                  setPopupFlag(true);
                                }}
                              >
                                새로운 프로그램 추가
                              </a>
                            </div>
                            <div className="Manage-input-container">
                              <p className="Manage-input-title">시간*</p>
                              <div className="Manage-time-schedule gathering-period-schedule gathering-period-schedule-enroll">
                                <input
                                  type="time"
                                  required
                                  data-placeholder="NNNN-NN-NN"
                                  style={{ background: "#EEF2F3" }}
                                  disabled
                                  value={
                                    meetingScheduleProgramIndex === 0
                                      ? meetingSchedules[index].endTime
                                      : meetingSchedules[index]
                                          .meetingSchedulePrograms[
                                          meetingScheduleProgramIndex - 1
                                        ].endTime
                                  }
                                />
                                <span>~</span>
                                <input
                                  type="time"
                                  required
                                  data-placeholder="NNNN-NN-NN"
                                  style={{ background: "#EEF2F3" }}
                                  disabled
                                  value={
                                    meetingSchedules[index]
                                      .meetingSchedulePrograms[
                                      meetingScheduleProgramIndex
                                    ].endTime
                                  }
                                  onChange={(e) => {}}
                                />
                              </div>
                            </div>
                            <div className="Manage-input-container manage-input-container-detail">
                              <p className="Manage-input-title">상세설명*</p>
                              <textarea
                                className="Manage-input-textarea-detail"
                                placeholder="상세설명을 입력하세요"
                                value={
                                  meetingSchedules[index]
                                    .meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].content
                                }
                                onChange={(e) => {
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].content = e.target.value;
                                  setMeetingSchedules([...meetingSchedules]);
                                }}
                              ></textarea>
                            </div>
                            <div className="Manage-input-container Manage-input-container-gathering-add">
                              <span className="Manage-input-title">
                                이미지 (*최대 5장)
                              </span>
                              <div className="schedule-info-img">
                                <label
                                  htmlFor={`img-input_img_${index}_${meetingScheduleProgramIndex}`}
                                >
                                  <img src="\img\img-registration.svg" />
                                  <span>이미지 등록</span>
                                </label>
                                <input
                                  id={`img-input_img_${index}_${meetingScheduleProgramIndex}`}
                                  className="Manage-input-textarea manage-form-gathering-add-input"
                                  type="file"
                                  required
                                  onChange={(e) => {
                                    inputScheduleProgramFiles(
                                      e,
                                      index,
                                      meetingScheduleProgramIndex
                                    );
                                  }}
                                />
                                {meetingScheduleProgram.programFiles?.map(
                                  (image, meetingScheduleProgramIamgeIndex) => (
                                    <div
                                      key={meetingScheduleProgramIamgeIndex}
                                      className="schedule-info-img-list"
                                      style={{ marginRight: "13px" }}
                                    >
                                      <img
                                        src={image.imageUrl}
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                        }}
                                      />
                                      <img
                                        src="\img\image-cancel.svg"
                                        onClick={(e) => {
                                          deleteScheduleProgramFiles(
                                            index,
                                            meetingScheduleProgramIndex,
                                            meetingScheduleProgramIamgeIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="add-program-del-btn">
                              <a
                                onClick={(e) => {
                                  const meetingSchedulePrograms =
                                    meetingSchedules[index]
                                      .meetingSchedulePrograms;
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms =
                                    meetingSchedulePrograms.filter(
                                      (image, currentIndex) =>
                                        currentIndex !==
                                        meetingScheduleProgramIndex
                                    );
                                  setMeetingSchedules([...meetingSchedules]);
                                  if (
                                    meetingSchedulePrograms[
                                      meetingScheduleProgramIndex
                                    ].id
                                  ) {
                                    deleteMeetingSchduleProgramIds.push(
                                      meetingSchedulePrograms[
                                        meetingScheduleProgramIndex
                                      ].id
                                    );
                                  }
                                }}
                              >
                                삭제
                              </a>
                            </div>
                          </div>
                        ) : null}

                        {!meetingScheduleProgram.type ? (
                          <div
                            key={meetingScheduleProgramIndex}
                            className="schedule-enroll-information"
                            style={{ marginTop: "16px" }}
                          >
                            <div className="Manage-input-container">
                              <p className="Manage-input-title">일정명*</p>
                              <input
                                className="Manage-input-textarea manage-form-schedule-enroll"
                                type="text"
                                placeholder="일정명을 입력하세요"
                                required
                                value={meetingScheduleProgram.title}
                                onChange={(e) => {
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].title = e.target.value;
                                  setMeetingSchedules([...meetingSchedules]);
                                }}
                              />
                            </div>
                            <div className="Manage-input-container">
                              <p className="Manage-input-title">시간*</p>
                              <div className="Manage-time-schedule gathering-period-schedule gathering-period-schedule-enroll">
                                <input
                                  type="time"
                                  required
                                  data-placeholder="NNNN-NN-NN"
                                  style={{ background: "#EEF2F3" }}
                                  disabled
                                  value={
                                    meetingScheduleProgramIndex === 0
                                      ? meetingSchedules[index].endTime
                                      : meetingSchedules[index]
                                          .meetingSchedulePrograms[
                                          meetingScheduleProgramIndex - 1
                                        ].endTime
                                  }
                                />
                                <span>~</span>
                                <input
                                  type="time"
                                  required
                                  data-placeholder="NNNN-NN-NN"
                                  value={
                                    meetingSchedules[index]
                                      .meetingSchedulePrograms[
                                      meetingScheduleProgramIndex
                                    ].endTime
                                  }
                                  onChange={(e) => {
                                    // if (
                                    //   moment(e.target.value, "HH:mm").isBefore(
                                    //     moment(
                                    //       meetingScheduleProgram.startTime,
                                    //       "HH:mm"
                                    //     )
                                    //   )
                                    // ) {
                                    //   toast(
                                    //     "종료 일정은 시작 일정 이후로 입력해주세요."
                                    //   );
                                    //   e.target.value = "";
                                    //   return;
                                    // }

                                    if (
                                      meetingScheduleProgramIndex !==
                                      item.meetingSchedulePrograms.length - 1
                                    ) {
                                      // 마지막이 아닌경우
                                      for (
                                        let index =
                                          meetingScheduleProgramIndex + 1;
                                        index <
                                        item.meetingSchedulePrograms.length;
                                        index++
                                      ) {
                                        const element =
                                          item.meetingSchedulePrograms[index];
                                        const startTime = element.startTime;
                                        const endTime = element.endTime;
                                        const diff = moment.duration(
                                          moment(endTime, "HH:mm").diff(
                                            moment(startTime, "HH:mm")
                                          )
                                        );
                                        if (
                                          index ===
                                          meetingScheduleProgramIndex + 1
                                        ) {
                                          const newTime = moment(
                                            e.target.value,
                                            "HH:mm"
                                          )
                                            .add(diff, "minutes")
                                            .format("HH:mm");
                                          element.startTime = e.target.value;
                                          element.endTime = nullCheck(
                                            element.endTime
                                          )
                                            ? ""
                                            : newTime;
                                        } else {
                                          const newTime = moment(
                                            element.startTime,
                                            "HH:mm"
                                          )
                                            .add(diff, "minutes")
                                            .format("HH:mm");
                                          element.startTime =
                                            meetingSchedules[
                                              index
                                            ].meetingSchedulePrograms[
                                              index - 1
                                            ].endTime;
                                          element.endTime = nullCheck(
                                            element.endTime
                                          )
                                            ? ""
                                            : newTime;
                                        }
                                      }
                                      // const temp = meetingSchedules[index].meetingSchedulePrograms[meetingScheduleProgramIndex + 1];
                                      // const startTime = temp.startTime;
                                      // const endTime = temp.endTime;
                                      // const diff = moment.duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm")));
                                      // const newTime = moment(e.target.value, "HH:mm").add(diff.asMinutes(), "minutes").format("HH:mm");
                                      // item.meetingSchedulePrograms[meetingScheduleProgramIndex + 1].startTime = e.target.value;
                                      // item.meetingSchedulePrograms[meetingScheduleProgramIndex + 1].endTime = newTime;
                                    }

                                    meetingSchedules[
                                      index
                                    ].meetingSchedulePrograms[
                                      meetingScheduleProgramIndex
                                    ].endTime = e.target.value;
                                    setMeetingSchedules([...meetingSchedules]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="Manage-input-container">
                              <p className="Manage-input-title">이동수단*</p>
                              <input
                                className="Manage-input-textarea manage-form-schedule-enroll"
                                type="text"
                                placeholder="ex)대중교통, 버스, 지하철, 자동차 등"
                                required
                                value={
                                  meetingSchedules[index]
                                    .meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].vehicle
                                }
                                onChange={(e) => {
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].vehicle = e.target.value;
                                  setMeetingSchedules([...meetingSchedules]);
                                }}
                              />
                            </div>
                            <div className="Manage-input-container manage-input-container-detail">
                              <p className="Manage-input-title">상세설명*</p>
                              <textarea
                                className="Manage-input-textarea-detail"
                                placeholder="상세설명을 입력하세요"
                                value={
                                  meetingSchedules[index]
                                    .meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].content
                                }
                                onChange={(e) => {
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms[
                                    meetingScheduleProgramIndex
                                  ].content = e.target.value;
                                  setMeetingSchedules([...meetingSchedules]);
                                }}
                              ></textarea>
                            </div>
                            <div className="Manage-input-container Manage-input-container-gathering-add">
                              <span className="Manage-input-title">
                                이미지 (*최대 5장)
                              </span>
                              <div className="schedule-info-img">
                                <label
                                  htmlFor={`img-input_img_${index}_${meetingScheduleProgramIndex}`}
                                >
                                  <img src="\img\img-registration.svg" />
                                  <span>이미지 등록</span>
                                </label>
                                <input
                                  id={`img-input_img_${index}_${meetingScheduleProgramIndex}`}
                                  className="Manage-input-textarea manage-form-gathering-add-input"
                                  type="file"
                                  required
                                  onChange={(e) => {
                                    inputScheduleProgramFiles(
                                      e,
                                      index,
                                      meetingScheduleProgramIndex
                                    );
                                  }}
                                />
                                {meetingScheduleProgram.images?.map(
                                  (image, meetingScheduleProgramIamgeIndex) => (
                                    <div
                                      key={meetingScheduleProgramIamgeIndex}
                                      className="schedule-info-img-list"
                                      style={{ marginRight: "13px" }}
                                    >
                                      <img
                                        src={image.imageUrl}
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                        }}
                                      />
                                      <img
                                        src="\img\image-cancel.svg"
                                        onClick={(e) => {
                                          deleteScheduleProgramFiles(
                                            index,
                                            meetingScheduleProgramIndex,
                                            meetingScheduleProgramIamgeIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="add-program-del-btn">
                              <a
                                style={{
                                  position: "relative",
                                  width: "67px",
                                  marginTop: "39px",
                                  left: "720px",
                                }}
                                onClick={(e) => {
                                  const meetingSchedulePrograms =
                                    meetingSchedules[index]
                                      .meetingSchedulePrograms;
                                  meetingSchedules[
                                    index
                                  ].meetingSchedulePrograms =
                                    meetingSchedulePrograms.filter(
                                      (image, currentIndex) =>
                                        currentIndex !==
                                        meetingScheduleProgramIndex
                                    );
                                  setMeetingSchedules([...meetingSchedules]);
                                  if (
                                    meetingSchedulePrograms[
                                      meetingScheduleProgramIndex
                                    ].id
                                  ) {
                                    deleteMeetingSchduleProgramIds.push(
                                      meetingSchedulePrograms[
                                        meetingScheduleProgramIndex
                                      ].id
                                    );
                                  }
                                }}
                              >
                                삭제
                              </a>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  }
                )}
              </div>
            );
          })}

          <div className="Manage-form-save save-gathering-schedule-enroll">
            <button
              className="cursorPointer"
              style={{ cursor: "pointer" }}
              onClick={(e) => save()}
            >
              저장하기
            </button>
          </div>
        </div>
      </section>
      <ManageGatheringProgramAdd
        openFlag={popupFlag}
        setAddProgramPopupFlag={setPopupFlag}
        spaceId={spaceId}
        meetingId={meetingId}
        getProgramList={getProgramList}
      ></ManageGatheringProgramAdd>
      <AlertPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headerTitle={"일정관리"}
        content={`모임기간을 새로 등록하시면 기존 일정들은 삭제됩니다. 일정을 새로 등록하시겠습니까?`}
        cancelTitle={"취소"}
        confirmTitle={"확인"}
        setAlertValue={setAlertValue}
      ></AlertPopup>
    </div>
  );
};

export default ManageGatheringScheduleEnroll;
