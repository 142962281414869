import React, { useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringAdit.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import MeetingApi from "../../api/meetingApi";
import { toast } from "react-toastify";
import moment from "moment";
import { nullCheck } from "../../utils/check";

const ManageGatheringAdit = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [meetingId, setMeetingId] = useState(0);
  const [meetingStatusFlag, setMeetingStatusFlag] = useState(false);
  const [meetingCategoryFlag, setMeetingCategoryFlag] = useState(false);

  // mounted
  useEffect(() => {
    getMeetingInfo();
    setMeetingId(params.id);
  }, []);

  // 기본정보
  const [meeting, setMeeting] = useState({
    email: "",
    spaceName: "",
    meetingStatusTitle: "모임 상태를 선택해주세요.",
    meetingCategoryTitle: "모임 카테고리를 선택해주세요.",
    managerAuthority: "",
    files: [],

    title: "",
    intro: "",
    material: "",
    meetingStatus: "",
    meetingCategory: "",
    meetingType: "",
    meetingDate: "",
    meetingDetail: "",
    personnel: "",
    recruitingStartDate: "",
    recruitingEndDate: "",
    content: "",
    meetingTerms: "",
    subCategory: "",
    price: 0,
    discount: false,
    discountPercent: 0,
    images: [],
    imagesDeleteIds: [],
  });

  async function getMeetingInfo() {
    try {
      const response = await MeetingApi.getMeeting(params.id);
      const data = response.data.data.content;

      setMeeting((meeting) => ({
        ...meeting,
        managerAuthority: sessionStorage.getItem("managerAuthority"),
        email: data.manager.email,
        spaceName: data.space.name,
        meetingStatusTitle: data.meetingStatusTitle,
        meetingCategoryTitle: data.meetingCategoryTitle,
        title: data.title,
        intro: data.intro,
        material: data.material,
        meetingStatus: data.meetingStatus,
        meetingCategory: data.meetingCategory,
        meetingType: data.meetingType,
        meetingDate: moment(new Date(data.meetingDate))
          .utc()
          .format("yyyy-MM-DD HH:mm"),
        meetingDetail: data.meetingDetail,
        personnel: data.personnel,
        recruitingStartDate: moment(new Date(data.recruitingStartDate))
          .utc()
          .format("yyyy-MM-DD"),
        recruitingEndDate: moment(new Date(data.recruitingEndDate))
          .utc()
          .format("yyyy-MM-DD"),
        content: data.content,
        meetingTerms: data.meetingTerms,
        subCategory: data.subCategory,
        price: data.price,
        discount: data.discount,
        discountPercent: data.discountPercent,
        files: data.meetingFiles,
      }));
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // 파일 업로드
  const inputFiles = (e) => {
    if (meeting.files.length === 5) {
      toast("이미지는 최대 5장까지 등록가능합니다.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const fileTemp = {
          imageOriginFileName: file.name,
          imageUrl: e.target.result,
        };
        setMeeting((meeting) => ({
          ...meeting,
          files: [...meeting.files, fileTemp],
          images: [...meeting.images, file],
        }));
      };
    }
    e.target.value = "";
  };
  const deleteFiles = (index) => {
    if (meeting.files[index].id) {
      const imagesDeleteIds = meeting.imagesDeleteIds;
      imagesDeleteIds.push(meeting.files[index].id);
    }

    setMeeting((meeting) => ({
      ...meeting,
      files: meeting.files.filter(
        (file, currentIndex) => currentIndex !== index
      ),
      images: meeting.images.filter(
        (image, currentIndex) => currentIndex !== index
      ),
    }));
  };

  // 저장
  const save = async () => {
    try {
      if (meeting.spaceId === 0) {
        toast("공간명을 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.title)) {
        toast("모임 제목을 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.meetingStatus)) {
        toast("모임 상태를 선택해주세요.");
        return;
      }

      if (nullCheck(meeting.meetingCategory)) {
        toast("모임 카테고리를 선택해주세요.");
        return;
      }

      if (nullCheck(meeting.meetingDate) || nullCheck(meeting.meetingDetail)) {
        toast("모임 일시를 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.personnel)) {
        toast("모집인원을 입력해주세요.");
        return;
      }

      if (
        nullCheck(meeting.recruitingStartDate) ||
        nullCheck(meeting.recruitingEndDate)
      ) {
        toast("모집기간을 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.intro)) {
        toast("모임 소개을 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.material)) {
        toast(
          "모임 준비물을 입력해주세요. 준비물이 없을 시 없음으로 입력해주세요."
        );
        return;
      }

      if (nullCheck(meeting.content)) {
        toast("상세소개를 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.meetingTerms)) {
        toast("상품약관을 입력해주세요.");
        return;
      }

      if (nullCheck(meeting.meetingType)) {
        toast("모임 분류를 선택해주세요.");
        return;
      }

      if (meeting.meetingType === "PAY" && meeting.price === 0) {
        toast("가격을 입력해주세요.");
        return;
      }

      const requestDto = new FormData();
      requestDto.append("managerId", meeting.managerId);
      requestDto.append("spaceId", meeting.spaceId);
      requestDto.append("title", meeting.title);
      requestDto.append("intro", meeting.intro);
      requestDto.append("material", meeting.material);
      requestDto.append("meetingStatus", meeting.meetingStatus);
      requestDto.append("meetingCategory", meeting.meetingCategory);
      requestDto.append("meetingType", meeting.meetingType);
      requestDto.append(
        "meetingDate",
        moment(new Date(meeting.meetingDate)).format("yyyy-MM-DD HH:mm:ss")
      );
      requestDto.append("meetingDetail", meeting.meetingDetail);
      requestDto.append("personnel", meeting.personnel);
      requestDto.append(
        "recruitingStartDate",
        moment(new Date(meeting.recruitingStartDate)).format(
          "yyyy-MM-DD HH:mm:ss"
        )
      );
      requestDto.append(
        "recruitingEndDate",
        moment(new Date(meeting.recruitingEndDate))
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format("yyyy-MM-DD HH:mm:ss")
      );
      requestDto.append("content", meeting.content);
      requestDto.append("meetingTerms", meeting.meetingTerms);
      requestDto.append("subCategory", meeting.subCategory);
      requestDto.append("price", meeting.price);
      requestDto.append("discount", meeting.discount);
      requestDto.append("discountPercent", meeting.discountPercent);

      if (!nullCheck(meeting.images)) {
        for (let index = 0; index < meeting.images.length; index++) {
          const element = meeting.images[index];
          requestDto.append("images", element);
        }
      }

      if (!nullCheck(meeting.imagesDeleteIds)) {
        for (let index = 0; index < meeting.imagesDeleteIds.length; index++) {
          const element = meeting.imagesDeleteIds[index];
          requestDto.append("imagesDeleteIds", element);
        }
      }

      await MeetingApi.patchMeeting(meetingId, requestDto);
      toast("수정되었습니다.");
      navigate(`/admin/gathering/detail/${meetingId}`);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div
          className="Manage-form manage-form-gathering-adit"
          style={{ height: "100%" }}
        >
          <span>수정하기</span>
          <span>기본정보 및 소개</span>
          <div className="Manage-input-container Manage-input-container-gathering-adit">
            <span className="Manage-input-title">등록관리자이메일</span>
            <input
              className="Manage-input-textarea manage-form-gathering-adit-input"
              type="email"
              placeholder="이메일"
              disabled
              value={meeting.email}
            />
          </div>
          {meeting.managerAuthority !== "MANAGER" ? (
            <div className="Manage-input-container Manage-input-container-gathering-adit">
              <span className="Manage-input-title">공간명*</span>
              <input
                className="Manage-input-textarea manage-form-gathering-adit-input"
                type="text"
                placeholder="공간명"
                value={meeting.spaceName}
                required
                disabled
              />
            </div>
          ) : null}
          <div className="Manage-input-container Manage-input-container-gathering-adit">
            <p className="Manage-input-title">모임제목*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="모임 제목을 입력해주세요."
                  value={meeting.title}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      title: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-adit">
            <p className="Manage-input-title">상태*</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => {
                  setMeetingStatusFlag(!meetingStatusFlag);
                  if (meetingCategoryFlag) {
                    setMeetingCategoryFlag(false);
                  }
                }}
              >
                <div
                  className={
                    meeting.meetingStatus === ""
                      ? "selected-value-basic"
                      : "selected-value"
                  }
                >
                  {meeting.meetingStatusTitle}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {meetingStatusFlag ? (
                <ul className="option-container">
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "RECRUITING",
                        meetingStatusTitle: "모집중",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    모집중
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "COMPLETE",
                        meetingStatusTitle: "모집완료",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    모집완료
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "END",
                        meetingStatusTitle: "종료",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    종료
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "CANCEL",
                        meetingStatusTitle: "취소",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    취소
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "TEMP_SAVED",
                        meetingStatusTitle: "임시저장",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    임시저장
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">카테고리*</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => {
                  setMeetingCategoryFlag(!meetingCategoryFlag);
                  if (meetingStatusFlag) {
                    setMeetingStatusFlag(false);
                  }
                }}
              >
                <div
                  className={
                    meeting.meetingCategory === ""
                      ? "selected-value-basic"
                      : "selected-value"
                  }
                >
                  {meeting.meetingCategoryTitle}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {meetingCategoryFlag ? (
                <ul className="option-container">
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "RESIDENCE",
                        meetingCategoryTitle: "거주",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    거주
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "TRAVEL",
                        meetingCategoryTitle: "여행",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    여행
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "ORGANIZATION",
                        meetingCategoryTitle: "단체",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    단체
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-postdate">
            <p className="Manage-input-title">일시*</p>
            <div className="Manage-date-postdate Manage-date-postdate-gathering">
              <input
                type="datetime-local"
                required
                data-placeholder="NNNN-NN-NN --:--"
                value={meeting.meetingDate}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingDate: moment(new Date(e.target.value)).format(
                      "yyyy-MM-DD HH:mm:ss"
                    ),
                  }));
                }}
              />
              <input
                className="Manage-input-textarea manage-form-gathering-add-input"
                type="text"
                required
                placeholder="N시간 혹은 N박 N일로 입력해주세요"
                value={meeting.meetingDetail}
                onChange={(e) => {
                  setMeeting((meeting) => ({
                    ...meeting,
                    meetingDetail: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">모집인원*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="number"
                  required
                  placeholder="N"
                  value={meeting.personnel}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      personnel: e.target.value,
                    }));
                  }}
                />
                <span style={{ marginLeft: "310px" }}>명</span>
              </div>
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">모집기간*</p>
            <div className="Manage-date-postdate Manage-date-postdate-gathering-period">
              <input
                type="date"
                required
                data-placeholder="NNNN-NN-NN"
                value={meeting.recruitingStartDate}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    recruitingStartDate: moment(
                      new Date(e.target.value)
                    ).format("yyyy-MM-DD"),
                  }));
                }}
              />
              <span>~</span>
              <input
                type="date"
                required
                data-placeholder="NNNN-NN-NN"
                value={meeting.recruitingEndDate}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    recruitingEndDate: moment(new Date(e.target.value)).format(
                      "yyyy-MM-DD"
                    ),
                  }));
                }}
              />
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">모임소개*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="모임 소개를 입력해주세요"
                  value={meeting.intro}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      intro: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">준비물*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="준비물을 입력해주세요 ex) 수건, 선크림"
                  value={meeting.material}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      material: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <span className="Manage-input-title">상세소개*</span>
            <textarea
              placeholder="모임 상세소개를 입력해주세요"
              value={meeting.content}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  content: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <span className="Manage-input-title">상품약관*</span>
            <textarea
              placeholder="상품약관을 입력해주세요"
              value={meeting.meetingTerms}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  meetingTerms: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">
              서브 카테고리
              <br />
              (최대5개)
            </p>
            <input
              className="Manage-input-textarea manage-form-gathering-add-input"
              type="text"
              placeholder="ex) 서핑, 바다, 익스트림"
              value={meeting.subCategory}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  subCategory: e.target.value,
                }));
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-adit">
            <span className="Manage-input-title">이미지 (*최대 5장)</span>
            <div className="manage-form-gathering-adit-input-img manage-form-gathering-adit-input-img-root">
              <label htmlFor="img-input" style={{ cursor: "pointer" }}>
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-gathering-adit-input"
                type="file"
                required
                onChange={(e) => {
                  inputFiles(e);
                }}
              />

              {meeting.files.map((file, index) => (
                <div
                  key={index}
                  className="manage-form-gathering-img-container"
                >
                  <img
                    src={file.imageUrl}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <img
                    src="\img\image-cancel.svg"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      deleteFiles(index);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Manage-input-container">
            <span className="Manage-input-title">모임분류*</span>
            <div className="input-radio-gathering-container">
              <input
                className="input-radio-gathering"
                type="radio"
                id="pay"
                name="gathering-classification"
                checked={meeting.meetingType === "PAY"}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "PAY",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="pay">유료</label>
              <input
                className="input-radio-gathering"
                type="radio"
                id="free-procedure"
                name="gathering-classification"
                checked={meeting.meetingType === "APPROVAL"}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "APPROVAL",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="free-procedure">무료 (승인절차O)</label>
              <input
                className="input-radio-gathering"
                type="radio"
                id="free-no-procedure"
                name="gathering-classification"
                checked={meeting.meetingType === "FREE"}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "FREE",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="free-no-procedure">무료 (승인절차X)</label>
            </div>
          </div>
          {meeting.meetingType === "PAY" ? (
            <div className="Manage-input-container Manage-input-container-gathering-add">
              <span className="Manage-input-title">가격</span>
              <div>
                <input
                  type="number"
                  className="Manage-input-textarea manage-form-gathering-add-input"
                  placeholder="숫자만 입력"
                  value={meeting.price}
                  onChange={(e) => {
                    setMeeting((item) => ({
                      ...item,
                      price: e.target.value,
                    }));
                  }}
                />
                <div className="sale-apply-container">
                  <input
                    type="checkbox"
                    id="sale-apply"
                    checked={meeting.discount}
                    onChange={(e) => {
                      setMeeting((item) => ({
                        ...item,
                        discount: e.target.checked,
                      }));
                    }}
                  ></input>
                  <label htmlFor="sale-apply"></label>
                  <span>할인적용</span>
                  <div className="apply-number-container">
                    <input
                      className="Manage-input-textarea Manage-input-sale-apply-number"
                      type="text"
                      placeholder="N"
                      style={{ paddingLeft: "228px" }}
                      value={meeting.discountPercent}
                      onChange={(e) => {
                        setMeeting((item) => ({
                          ...item,
                          discountPercent: e.target.value,
                        }));
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="Manage-form-save manage-form-save-gathering-adit">
            <button
              style={{
                position: "relative",
                right: "-12px",
                bottom: "-5px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                save();
              }}
            >
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringAdit;
