import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "../../css/popup/ManagePopupsAdit.css";
import "../../css/Manage.css";
import PopupApi from "../../api/popupApi";
import { toast } from "react-toastify";

const ManagePopupsAdd = () => {
  let navigate = useNavigate();

  const [popupInfo, setPopupInfo] = useState({
    title: null,
    startDate: null,
    endDate: null,
    link: null,
    binaryUrl: null,
    imageUrl: null,
    imageDeleteFlag: false,
  });

  const saveImgFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      setPopupInfo({
        ...popupInfo,
        binaryUrl: file,
        imageUrl: e.target.result,
        imageDeleteFlag: true,
      });
    };
    // reader.readAsDataURL(file);
    e.target.value = "";
  };

  const popupAddHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("title", popupInfo.title);
      formData.append("startDate", popupInfo.startDate);
      formData.append("endDate", popupInfo.endDate);
      if (popupInfo.binaryUrl) {
        formData.append("image", popupInfo.binaryUrl);
      }
      formData.append("link", popupInfo.link);
      await PopupApi.Post(formData);
      toast("저장되었습니다.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-popup-adit">
          <span>추가하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              type="text"
              className="Manage-input-textarea"
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">시작일</p>
            <input
              id="alarm-start-date"
              type="date"
              data-placeholder="0000-00-00"
              required
              aria-required="true"
              className="Manage-input-textarea"
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  startDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">종료일</p>
            <input
              id="alarm-end-date"
              type="date"
              data-placeholder="0000-00-00"
              required
              aria-required="true"
              className="Manage-input-textarea"
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  endDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-img-container">
            <p className="Manage-input-title">사진업로드</p>
            <div>
              <div className="notice-input-img-container">
                <label htmlFor="img-input">
                  <img src="\img\img-registration.svg" />
                  <span>이미지 등록</span>
                </label>
                <input
                  id="img-input"
                  className="Manage-input-textarea"
                  type="file"
                  required
                  accept="image/*"
                  onChange={(e) => saveImgFile(e)}
                />
                {popupInfo.imageUrl ? (
                  <div className="notice-img uploaded-img-container">
                    <img className="uploaded-img" src={popupInfo.imageUrl} />
                    <img src="\img\image-cancel.svg" />
                  </div>
                ) : null}
              </div>
              <span>*권장사이즈 96*118</span>
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">연결 링크</p>
            <input
              type="url"
              className="Manage-input-textarea"
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  link: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-form-save Manage-form-save-banner">
            <button className="cursorPointer" onClick={popupAddHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManagePopupsAdd;
