import api, { _axios } from "./api";

export default class SpaceApi {
  static async Get(data) {
    return await api.get(`space`, data);
  }
  static async GetCheck(data) {
    return await api.get(`space/check/name`, data);
  }
  static async GetSpace(id) {
    return await api.get(`space/${id}`);
  }
  static async Post(data) {
    return await api.post(`space`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async Patch(id, data) {
    return await api.patch(`space/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async Del(id) {
    return await api.del(`space/${id}`);
  }
  static async PostType(id, data) {
    return await api.post(`space/type/${id}`, data);
  }
  static async FileDownload(data) {
    return await _axios.post(`space/download/file`, data, {
      responseType: "blob",
    });
  }
}
