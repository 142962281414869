import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/spacesupport/ManageSpaceSupportSeeMore.css";
import "../../css/Manage.css";
import SpaceSupportApi from "../../api/spaceSupportApi";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManageSpaceSupportSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [supportId, setSupportId] = useState(null);
  const [supportInfo, setSupportInfo] = useState({});

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setSupportId((id) => location.state.id);
        getSpaceSupportInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getSpaceSupportInfo = async (id) => {
    try {
      const info = (await SpaceSupportApi.GetSpaceSupport(id)).data.data.content;
      setSupportInfo(info);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const supportHandler = async () => {
    setIsOpen(true);
    setDeleteId(supportId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await SpaceSupportApi.Del(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/spacesupport/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-spacesupport-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={supportHandler}>삭제</Link>
          </div>
        </div>
        <div className="Manage-form manage-form-spacesupport-see-more">
          <span>공간 지원 내용</span>
          <div className="Manage-form-view-details">
            <span>상태</span>
            <span>{supportInfo.status ? "처리" : "미처리"}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>담당자 이름</span>
            <span>{supportInfo.managerName}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>담당자 전화번호</span>
            <span>{supportInfo.phoneNumber}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>위치</span>
            <span>{supportInfo.address}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>댓글 내용</span>
            <span>{supportInfo.content}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>약관 동의</span>
            <div>
              <div className="seemore-consent-checkbox">
                <span>개인정보 수집 및 이용 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info"
                  checked={supportInfo.agreeToPrivacy ? true : false}
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>정보 제3자 제공동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info-third"
                  checked={supportInfo.agreeToInformation ? true : false}
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info-third"></label>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageSpaceSupportSeeMore;
