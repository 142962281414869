import api from "./api";

export default class PopupApi {
  static async Get() {
    return await api.get(`popup`);
  }
  static async Post(data) {
    return await api.post(`popup`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async Del(id) {
    return await api.del(`popup/${id}`);
  }
  static async GetPopup(id) {
    return await api.get(`popup/${id}`);
  }
  static async PatchPopup(id, data) {
    return await api.patch(`popup/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
