import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/mileage/ManageMileage.css";
import "../../css/Manage.css";
import ManageMileageAllIncrease from "./ManageMileageAllIncrease";
import ManageMileageIndiIncrease from "./ManageMileageIndiIncrease";
import MemberApi from "../../api/memberApi";
import MileageApi from "../../api/mileageApi";
import moment from "moment";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const ManageMileage = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //------------엑셀 생성
  const downloadExcel = async () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "마을리지 내용", key: "content", width: 40 },
      { header: "마을리지", key: "point", width: 40 },
      { header: "마을리지합", key: "pointTotal", width: 40 },
      { header: "일시", key: "createDate", width: 40 },
      { header: "만료일", key: "endDate", width: 40 },
    ];

    excel.map((item, index) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "마일리지.xlsx");
  };
  //------------액셀 부분 끝

  const [arr, setArr] = useState([]);
  const [excel, setExcel] = useState([]);

  const [popupAll, setPopupAll] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [popupIndi, setPopupIndi] = useState(false);
  const [mileageList, setMileageList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getMileageList();
  }, [currentPage, location]);

  useEffect(() => {
    getMileageList2();
  }, [popupIndi, popupAll]);

  const getMileageList = async () => {
    try {
      const list = (
        await MileageApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchKeyword,
        })
      ).data.data;

      setMileageList((mileageList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));

          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const getMileageList2 = async () => {
    try {
      const list = (
        await MileageApi.Get({
          page: 1,
          size: 10,
          keyword: "",
        })
      ).data.data;

      setMileageList((mileageList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));

          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>마을리지 관리</span>
        <div className="manage-table-container">
          <div className="Manage-mileage-search-container">
            <div className="Manage-mileage-search-number">
              <span>전체수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-mileage-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/mileage/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              <div onClick={() => setPopupAll(true)}>
                <img src="\img\see-more-btn-plus.svg" />
                <span>전체회원 마을리지 증감 설정</span>
              </div>
              <div onClick={() => setPopupIndi(true)}>
                <img src="\img\see-more-btn-plus.svg" />
                <span>개별회원 마을리지 증감 설정</span>
              </div>
              <div onClick={downloadExcel} className="cursorPointer">
                <img src="\img\see-more-btn-plus.svg" />
                <span>엑셀로 내보내기</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-mileage">
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      className="cursorPointer all-check-input"
                      type="checkbox"
                      id={"check"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let obj = [];
                          const temp = mileageList.map((value) => value.id);
                          const excelTemp = mileageList.map((item) => {
                            obj.push({
                              email: item.member.email,
                              content: item.content,
                              point: item.point,
                              pointTotal: item.pointTotal,
                              createDate: item.createdAt,
                              endDate: item.endDate,
                            });
                          });
                          setArr(temp);
                          setExcel(obj);
                        } else {
                          setArr([]);
                          setExcel([]);
                        }
                      }}
                    ></input>
                    <label className="all-check-label" htmlFor={"check"}></label>
                  </th>
                  <th>이메일</th>
                  <th>마을리지 내용</th>
                  <th>마을리지</th>
                  <th>마을리지합</th>
                  <th>일시</th>
                  <th>만료일</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {mileageList.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
                  let endDate = moment(item.endDate).utc().format("YYYY-MM-DD");
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          id="check1"
                          checked={arr.includes(item.id)}
                          onChange={(e) => {
                            let obj = {
                              email: item.member.email,
                              content: item.content,
                              point: item.point,
                              pointTotal: item.pointTotal,
                              createDate: item.createdAt,
                              endDate: item.endDate,
                            };
                            let copyData = [...arr];
                            let copyExcel = [...excel];
                            if (e.target.checked) {
                              copyData.push(item.id);
                              copyExcel.push(obj);
                              setArr(copyData);
                              setExcel(copyExcel);
                            } else {
                              let arrTemp = copyData.filter((item02) => item02 !== item.id);
                              let excelTemp = copyExcel.filter(
                                (item02) => item02 !== copyExcel.find((e) => e.email === item.member.email)
                              );
                              setArr(arrTemp);
                              setExcel(excelTemp);
                            }
                          }}
                        ></input>
                        <label htmlFor="check1"></label>
                      </td>
                      <td>
                        <span>{item.member.email}</span>
                      </td>
                      <td>
                        <span>{item.content}</span>
                      </td>
                      <td>{item.point}</td>
                      <td>{item.pointTotal}</td>
                      <td>{createDate}</td>
                      <td>{endDate}</td>
                      <td>
                        <Link to={`/admin/mileage/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/mileage/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/mileage/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/mileage/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {popupAll && <ManageMileageAllIncrease setPopupAll={setPopupAll} />}
      {popupIndi && <ManageMileageIndiIncrease setPopupIndi={setPopupIndi} />}
    </div>
  );
};

export default ManageMileage;
