
import api, {_axios} from "./api";

export default class MemberApi {
  static async GetMemberList(data) {
    return await api.get(`member`, data);
  }

  static async MemberActive(id, data) {
    return await api.post(`member/activation/${id}`, data);
  }

  static async Send(data) {
    return await api.post(`member/sms`, data);
  }

  static async DelMember(id) {
    return await api.del(`member/${id}`);
  }

  static async GetMember(id) {
    return await api.get(`member/${id}`);
  }

  static async EditMember(id, data) {
    return await api.patch(`member/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async FileDownload(data) {
    return await _axios.post(`member/download/file`, data, {
      responseType: "blob",
    });
  }
}
