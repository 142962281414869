import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringReviewSeeMore.css";
import MeetingReviewApi from "../../api/meetingReview";
import moment from "moment";
import { toast } from "react-toastify";
const ManageGatheringReviewSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [date, setDate] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [reviewScore, setReviewScore] = useState(null);
  const [reviewInfo, setReviewInfo] = useState({});
  const [reviewStar, setReviewStar] = useState([]);

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setReviewId(location.state.id);
      getReviewInfo(id);
    }
  }, [location]);

  const getReviewInfo = async (id) => {
    try {
      const info = (await MeetingReviewApi.GetReview(id)).data.data.content;
      setReviewScore(info.score.toFixed(1));
      setDate(moment(info.createdAt).utc().format("YYYY/MM/DD"));
      setReviewInfo(info);

      let star = [];
      for (let i = 0; i < info.score; i++) {
        star.push("/img/star-full.svg");
      }
      for (let i = 0; i < 5 - info.score; i++) {
        star.push("/img/star-empty.svg");
      }
      setReviewStar(star);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const activationReview = async () => {
    let obj = {
      activation: false,
    };
    try {
      await MeetingReviewApi.Activation(reviewId, obj);
      getReviewInfo(reviewId);
      toast("숨김처리되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-review-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={activationReview}>숨김</Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-review-see-more">
          <span>리뷰</span>
          <div className="Manage-form-view-details gathering-review-seemore-detail">
            <span>이메일</span>
            <span>{reviewInfo.member && reviewInfo.member.email}</span>
          </div>
          <div className="Manage-form-view-details gathering-review-seemore-detail">
            <span>별명</span>
            <span>{reviewInfo.member && reviewInfo.member.nickname}</span>
          </div>
          <div className="Manage-form-view-details gathering-review-seemore-detail">
            <span>작성일자</span>
            <span>{date}</span>
          </div>
          <div className="gathering-review-detail-img-container">
            {reviewInfo.meetingProgramReviewFiles &&
              reviewInfo.meetingProgramReviewFiles.map((item, index) => {
                return <img src={item.imageUrl} />;
              })}
          </div>
          <div className="gathering-review-contents-container">
            <p>{reviewInfo.programTitle}</p>
            <div className="gathering-review-scope-container">
              {reviewStar.map((item, index) => {
                return <img src={item} />;
              })}
              <span>리뷰{reviewScore}</span>
            </div>
            <span>{reviewInfo.content}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringReviewSeeMore;
