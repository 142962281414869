import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/gathering/ManageGatheringSeeMore.css";
import "../../css/Manage.css";
import MeetingApi from "../../api/meetingApi";
import { toast } from "react-toastify";
import moment from "moment";
import { useMemo } from "react";
import MeetingProgramApi from "../../api/meetingProgramApi";
import MeetingReviewApi from "../../api/meetingReview";
import AlertPopup from "../../components/AlertPopup";
import { downloadFile } from "../../utils/download";
import ManageGatheringDetailProgramAdd from "./ManageGatheringDetailProgramAdd";

const ManageGatheringDetail = () => {
  const params = useParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState(
    "삭제된 데이터는 복구가 불가능합니다."
  );
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  //--program 삭제 state--//
  const [programIsOpen, setProgramIsOpen] = useState(false);
  const [programHeaderTitle, setProgramHeaderTitle] =
    useState("삭제 하시겠습니까?");
  const [programContent, setProgramContent] = useState(
    "삭제된 데이터는 복구가 불가능합니다."
  );
  const [programAlertValue, setProgramAlertValue] = useState(false);
  const [programCancelTitle, setProgramCancelTitle] = useState("취소");
  const [programConfirmTitle, setProgramConfirmTitle] = useState("삭제");
  //--program 삭제 state--//

  const [meetingId, setMeetingId] = useState(0);
  const [addProgramPopupFlag, setAddProgramPopupFlag] = useState(false);

  // mounted
  useEffect(() => {
    getMeetingInfo();
    getMeetingProgramsInfo();
    setMeetingId(params.id);
  }, []);

  // 기본정보
  const [meeting, setMeeting] = useState({});

  async function getMeetingInfo() {
    try {
      const response = await MeetingApi.getMeeting(params.id);
      const data = response.data.data;
      setMeeting(data.content);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // 프로그램
  const [meetingProgramList, setMeetingProgramList] = useState([]);
  const [selectFlag, setSelectFlag] = useState(null);

  // pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);
  const [subMenuActive, setSubMenuActive] = useState(null);

  useEffect(() => {
    getMeetingProgramsInfo();
  }, [currentPage]);

  useMemo(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const getMeetingProgramsInfo = async () => {
    try {
      const response = await MeetingApi.getMeetingPrograms({
        page: currentPage,
        size: 10,
        meetingId: params.id,
      });
      const data = response.data.data;

      setMeetingProgramList(data.content);
      setTotalCount(data.totalCount);
      setPostsPerPage(data.content.length);

      const count = Math.ceil(data.totalCount / data.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  // 참여자
  const [meetingMemberList, setMeetingMemberList] = useState([]);

  // pagination
  const [totalCount2, setTotalCount2] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [startIndex2, setStartIndex2] = useState(0);
  const [pageIndex2, setPageIndex2] = useState([]);
  const [postsPerPage2, setPostsPerPage2] = useState(null);

  useEffect(() => {
    getMeetingMemberInfo();
  }, [currentPage2]);

  useMemo(() => {
    let page = Math.ceil(totalCount2 / postsPerPage2);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex2(arr);
  }, [totalCount2]);

  const getMeetingMemberInfo = async () => {
    try {
      const response = await MeetingApi.getMeetingMembers({
        page: currentPage2,
        size: 10,
        meetingId: params.id,
      });
      const data = response.data.data;

      setMeetingMemberList(
        data.content.map((item) => {
          return {
            ...item,
            createdAtString: moment(new Date(item.createdAt))
              .utc()
              .format("yyyy/MM/DD"),
          };
        })
      );
      setTotalCount2(data.totalCount);
      setPostsPerPage2(data.content.length);

      const count = Math.ceil(data.totalCount / data.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage2 / 4 <= i) {
          setStartIndex2(4 * (i - 1));
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const supportStatusHandler = async (id, index, state) => {
    // let copyData = [...spaceSupportData];
    // copyData[index].status = state;
    // setSpaceSupportData(copyData);
    let supportStatus = {
      meetingMemberStatus: state,
    };
    try {
      await MeetingApi.PostMeetingMemberStatus(id, supportStatus);
      toast("변경되었습니다.");
      getMeetingMemberInfo();
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //프로그램 삭제 실행하는 곳
  const delMeetingProgram = async (id) => {
    setProgramIsOpen(true);
    setDeleteProgramId(id);
  };

  const [deleteProgramId, setDeleteProgramId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteProgramId <= 0) {
          return;
        }
        await MeetingProgramApi.delMeetingProgram(deleteProgramId);
        toast("삭제되었습니다.");
        getMeetingProgramsInfo();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (programAlertValue) {
      del().finally(() => {
        setProgramAlertValue(false);
      });
    }
  }, [programAlertValue]);

  //멤버 삭제 실행하는 곳
  const delmember = async (id) => {
    setIsOpen(true);
    setDeletememId(id);
  };

  const [deletememId, setDeletememId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deletememId <= 0) {
          return;
        }
        await MeetingApi.DelMeetingMembers(deletememId);
        toast("삭제되었습니다.");
        getMeetingMemberInfo();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del().finally(() => {
        setAlertValue(false);
      });
    }
  }, [alertValue]);

  const fileDownload = async (serverFileName, originFileName) => {
    try {
      const data = {
        serverFileName: serverFileName,
        originFileName: originFileName,
      };
      const response = await MeetingApi.FileDownload(data);
      downloadFile(response, originFileName);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const setReviewActivation = async (id, state) => {
    try {
      await MeetingProgramApi.updateReviewFlag(id, {
        reviewFlag: state,
      });
      await getMeetingProgramsInfo();
      setSelectFlag(-1);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  // 프로그램 세팅
  const [programList, setProgramList] = useState([]);

  const getProgramList = async () => {
    try {
      const response = await MeetingApi.getProgramList({
        size: 1000,
        // spaceId: meeting.space.id ?? undefined,
        activation: true,
      });
      const data = response.data.data;
      // const meetingProgramIds = meeting.meetingPrograms.map(
      //   (item) => item.programId
      // );
      setProgramList(
        // data.content.filter((item) => !meetingProgramIds.includes(item.id))
        [...data.content]
      );
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };
  return (
    <>
      <div>
        <div className="Manage-form manage-form-gathering-see-more">
          <div>
            <span>기본정보</span>
            <Link to={`/admin/gathering/edit/${meeting.id}`}>수정하기</Link>
          </div>
          <div className="Manage-form-view-details">
            <span>등록 관리자 이메일</span>
            <span>{meeting.manager?.email}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>공간명</span>
            <span>{meeting.space?.name}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>모임명</span>
            <span>{meeting.title}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>상태</span>
            <span>{meeting.meetingStatusTitle}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>카테고리</span>
            <span>{meeting.meetingCategoryTitle}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>일시</span>
            <span>
              {moment(new Date(meeting.meetingDate)).utc().format("yy.MM.DD")} /{" "}
              {meeting.meetingDetail}
            </span>
          </div>
          <div className="Manage-form-view-details">
            <span>모집인원</span>
            <span>
              {meeting.memberCount}명/{meeting.personnel}명
            </span>
          </div>
          <div className="Manage-form-view-details">
            <span>모집기간</span>
            <span>
              {moment(new Date(meeting.recruitingStartDate))
                .utc()
                .format("yyyy.MM.DD")}
              ~
              {moment(new Date(meeting.recruitingEndDate))
                .utc()
                .format("yyyy.MM.DD")}
            </span>
          </div>
          <div className="Manage-form-view-details">
            <span>프로그램</span>
            <span>
              {meeting.programTitle} 명 외 {meeting.programCount}개
            </span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>소개</span>
            <span>{meeting.intro}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>상세소개</span>
            <span>{meeting.content}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>준비물</span>
            <span>{meeting.material}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>서브 카테고리(태그)</span>
            <span>{meeting.subCategory}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-gathering-seemore manage-flex-start">
            <span>
              이미지
              <br />
              (*최대 5장)
            </span>
            <div style={{ width: "500px", display: "flex", flexWrap: "wrap" }}>
              {meeting.meetingFiles?.map((item, index) => {
                return (
                  <div
                    className="Manage-form-view-details-img Manage-form-view-details-img-gathering"
                    style={{ marginRight: "6px" }}
                    key={index}
                  >
                    <div>
                      <img src={item.imageUrl} />
                    </div>
                    <div
                      onClick={() => {
                        fileDownload(
                          item.imageServerFileName,
                          item.imageOriginFileName
                        );
                      }}
                    >
                      <span>{item.imageOriginFileName}</span>
                      <img src="\img\download_black.svg" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Manage-form-view-details">
            <span>가격</span>
            <span>
              {meeting.price?.toLocaleString("ko-KR")}({meeting.discountPercent}
              %)
            </span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-lastchild manage-flex-start">
            <span>상품약관</span>
            <span>{meeting.meetingTerms}</span>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-see-more-comment">
          <span>등록된 프로그램</span>
          <a
            onClick={async (e) => {
              await getProgramList();
              setAddProgramPopupFlag(true);
            }}
            className="add-button"
          >
            프로그램 추가
          </a>
          <table>
            <thead>
              <tr>
                <th>프로그램명</th>
                <th>리뷰작성</th>
                <th>삭제</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {meetingProgramList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span>{item.program?.title}</span>
                    </td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (selectFlag === index) setSelectFlag(null);
                          else setSelectFlag(index);
                        }}
                      >
                        <span>{item.reviewFlag ? "O" : "X"}</span>
                        <img src="/img/table-togle.svg" />
                      </a>
                      {selectFlag === index ? (
                        <ul className="table-sub-menu table-sub-menu-gathering">
                          <li
                            onClick={() => {
                              setReviewActivation(item.id, true);
                            }}
                          >
                            O
                          </li>
                          <li
                            onClick={() => {
                              setReviewActivation(item.id, false);
                            }}
                          >
                            X
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <Link onClick={() => delMeetingProgram(item.id)}>
                        삭제
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/admin/program/detail/${item.program.id}`}
                        state={{ id: item.program.id }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex
                .slice(startIndex, startIndex + 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={
                        currentPage === item ? "pagenation-focus" : null
                      }
                      onClick={() => {
                        setCurrentPage(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        {meeting.meetingType === "APPROVAL" ? (
          <div className="Manage-form gathering-see-more-list-approve">
            <span>
              참여자 리스트 {meeting.memberCount}명/{meeting.personnel}명
            </span>
            <table>
              <thead>
                <tr>
                  <th>별명</th>
                  <th>게시일자</th>
                  <th>결제금액</th>
                  <th>승인</th>
                  <th>삭제</th>
                  <th>결제내역</th>
                </tr>
              </thead>
              <tbody>
                {meetingMemberList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{item.member?.nickname}</span>
                      </td>
                      <td>{item.createdAtString}</td>
                      <td>{item.payment?.toLocaleString("ko-KR")}원</td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (subMenuActive === index) setSubMenuActive(null);
                            else setSubMenuActive(index);
                          }}
                        >
                          <span>
                            {item.meetingMemberStatus === "WAIT"
                              ? "승인전"
                              : item.meetingMemberStatus === "COMPLETE"
                              ? "승인완료"
                              : item.meetingMemberStatus === "REJECT"
                              ? "반려"
                              : null}
                          </span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {subMenuActive === index ? (
                          <ul className="table-sub-menu table-sub-menu-spacesupport">
                            <li
                              onClick={() => {
                                supportStatusHandler(item.id, index, "WAIT");
                                setSubMenuActive(null);
                              }}
                            >
                              승인전
                            </li>
                            <li
                              onClick={() => {
                                supportStatusHandler(
                                  item.id,
                                  index,
                                  "COMPLETE"
                                );
                                setSubMenuActive(null);
                              }}
                            >
                              승인완료
                            </li>
                            <li
                              onClick={() => {
                                supportStatusHandler(item.id, index, "REJECT");
                                setSubMenuActive(null);
                              }}
                            >
                              반려
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <Link onClick={() => delmember(item.id)}>삭제</Link>
                      </td>
                      <td>
                        <a onClick={() => toast("서비스 준비 중입니다.")}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage2 <= 1) {
                      return;
                    } else {
                      setCurrentPage2(currentPage2 - 1);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex2
                  .slice(startIndex2, startIndex2 + 4)
                  .map((item, index) => {
                    return (
                      <a
                        key={index}
                        className={
                          currentPage2 === item ? "pagenation-focus" : null
                        }
                        onClick={() => {
                          setCurrentPage2(item);
                        }}
                      >
                        {item}
                      </a>
                    );
                  })}
                <a
                  onClick={() => {
                    if (currentPage2 >= pageIndex2.length) {
                      return;
                    } else {
                      setCurrentPage2(currentPage2 + 1);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="Manage-form manage-form-gathering-see-more-list">
            <span>
              참여자 리스트 {meeting.memberCount}명/{meeting.personnel}명
            </span>
            <table>
              <thead>
                <tr>
                  <th>별명</th>
                  <th>게시일자</th>
                  <th>결제금액</th>
                  <th>삭제</th>
                  <th>결제내역</th>
                </tr>
              </thead>
              <tbody>
                {meetingMemberList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{item.member?.nickname}</span>
                      </td>
                      <td>{item.createdAtString}</td>
                      <td>{item.payment?.toLocaleString("ko-KR")}원</td>
                      <td>
                        <Link onClick={() => delmember(item.id)}>삭제</Link>
                      </td>
                      <td>
                        <a onClick={() => toast("서비스 준비 중입니다.")}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage2 <= 1) {
                      return;
                    } else {
                      setCurrentPage2(currentPage2 - 1);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex2
                  .slice(startIndex2, startIndex2 + 4)
                  .map((item, index) => {
                    return (
                      <a
                        key={index}
                        className={
                          currentPage2 === item ? "pagenation-focus" : null
                        }
                        onClick={() => {
                          setCurrentPage2(item);
                        }}
                      >
                        {item}
                      </a>
                    );
                  })}
                <a
                  onClick={() => {
                    if (currentPage2 >= pageIndex2.length) {
                      return;
                    } else {
                      setCurrentPage2(currentPage2 + 1);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
            {/* 프로그램 삭제 컴포넌트 */}
            <AlertPopup
              isOpen={programIsOpen}
              setIsOpen={setProgramIsOpen}
              headerTitle={programHeaderTitle}
              content={programContent}
              setAlertValue={setProgramAlertValue}
              cancelTitle={programCancelTitle}
              confirmTitle={programConfirmTitle}
            />
            {/* 멤버 삭제 컴포넌트 */}
            <AlertPopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              headerTitle={headerTitle}
              content={content}
              setAlertValue={setAlertValue}
              cancelTitle={cancelTitle}
              confirmTitle={confirmTitle}
            />
          </div>
        )}
      </div>
      <ManageGatheringDetailProgramAdd
        key={String(addProgramPopupFlag)}
        openFlag={addProgramPopupFlag}
        setAddProgramPopupFlag={setAddProgramPopupFlag}
        getMeetingProgramsInfo={getMeetingProgramsInfo}
        meetingId={meetingId}
        programList={programList}
      ></ManageGatheringDetailProgramAdd>
    </>
  );
};

export default ManageGatheringDetail;
