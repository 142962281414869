import React, { useState } from "react";
import "../../css/mileage/ManageMileageIncrease.css";
import "../../css/Manage.css";
import MileageApi from "../../api/mileageApi";
import moment from "moment";
import { toast } from "react-toastify";
import { nullCheck } from "../../utils/check";

const ManageMileageAllIncrease = (props) => {
  const [indiInfo, setIndiInfo] = useState({
    content: null,
    point: null,
    period: null,
  });

  const mileageSetting = async () => {
    if (nullCheck(indiInfo.content)) {
      return toast("내용을 입력해주세요.");
    }
    if (nullCheck(indiInfo.point)) {
      return toast("포인트를 입력해주세요.");
    }
    if (Number(indiInfo.point) > 0 && nullCheck(indiInfo.period)) {
      return toast("유효기간을 입력해주세요.");
    }

    let currentDate = moment();
    let date = 0;
    if (indiInfo.period === "0") {
      date = "2999/12/31 23:59:59";
    } else {
      let end = currentDate.add(indiInfo.period, "days").endOf("day");
      date = end.format("YYYY/MM/DD HH:mm:ss");
    }
    let obj = {
      content: indiInfo.content,
      point: indiInfo.point,
      endDate: date,
    };
    try {
      await MileageApi.Post(obj);
      toast("설정되었습니다.");
      props.setPopupAll(false);      
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Popup-container">
      <div className="Popup popup-mileage">
        <div className="Popup-title">
          <span>전체회원 마을리지 증감 설정</span>
        </div>
        <div className="mileage-popup-middle Manage-input-mileage ">
          <div className="Manage-form-view-details mileage-details">
            <span>포인트 내용</span>
            <input
              className="Manage-input-textarea Manage-input-mileage-point-detail"
              type="text"
              required
              placeholder="포인트 내용을 입력하세요"
              onChange={(e) => {
                setIndiInfo((indiInfo) => ({
                  ...indiInfo,
                  content: e.target.value,
                }));
              }}
            />
          </div>
          <div className="Manage-form-view-details mileage-details">
            <span>포인트</span>
            <input
              className="Manage-input-textarea Manage-input-mileage-point"
              type="number"
              required
              placeholder="0 (감소일 경우 -입력)"
              onChange={(e) => {
                setIndiInfo((indiInfo) => ({
                  ...indiInfo,
                  point: e.target.value,
                }));
              }}
            />
          </div>
          {indiInfo.point >= 0 ? (
            <div className="Manage-form-view-details Manage-form-view-details-explanation mileage-details">
              <span>포인트 유효기간</span>
              <div>
                <div className="point-expiration-container">
                  <input
                    className="Manage-input-textarea Manage-input-mileage-point-expiration"
                    type="number"
                    min="0"
                    required
                    placeholder="N"
                    onKeyDown={(e) => {
                      if (e.code === "Minus") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setIndiInfo((indiInfo) => ({
                        ...indiInfo,
                        period: e.target.value,
                      }));
                    }}
                  />
                  <span>일</span>
                </div>
                <span className="point-expiration-annotation">
                  *0 입력시 유효기간이 적용되지 않습니다.
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mileage-popup-close-container">
          <div
            className="mileage-popup-close cursorPointer"
            onClick={() => props.setPopupAll(false)}
          >
            <span>닫기</span>
          </div>
          <div
            className="mileage-popup-setup cursorPointer"
            onClick={mileageSetting}
          >
            <span>설정하기</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageMileageAllIncrease;
