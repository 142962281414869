import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ManagerApi from "../../api/managerApi";
import "../../css/permission/ManagePermissions.css";
import "../../css/Manage.css";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManagePermissions = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [permissionsData, setPermissionData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [authorFilter, setAuthorFilter] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const lastIndex = currentPage * postsPerPage;
  const firstIndex = lastIndex - postsPerPage;
  // -------------2023.06.14
  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setAuthorFilter(searchParams.get("authority") ? searchParams.get("authority") : "");
    setCurrentPage(Number(page));
    getList();
  }, [currentPage, location]);

  const getList = async () => {
    try {
      const list = (
        await ManagerApi.List({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
          managerAuthority: searchParams.get("authority"),
        })
      ).data.data;
      setPostsPerPage(list.content.length);
      setPermissionData(list.content);
      setTotalCount(() => list.totalCount);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    setPageCount((pageCount) => page);
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const delHandler = async (id, index) => {
    try {
      if (permissionsData[index].managerAuthority === "ROOTADMIN") {
        toast("ROOT는 삭제할 수 없습니다.");
        return;
      } else {
        setIsOpen(true);
        setdeleteId(id);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activeHandler = async (itemId, index, state) => {
    let copyData = [...permissionsData];
    copyData[index].activation = state;
    setPermissionData(copyData);
    let ManagerActivity = {
      activation: state,
    };
    try {
      await ManagerApi.Active(itemId, ManagerActivity);
      toast("변경되었습니다");
    } catch (error) {
      console.log(error);
    }
  };

  const authorityHandler = async (id, index, value) => {
    let copyData = [...permissionsData];
    copyData[index].managerAuthority = value;
    setPermissionData(copyData);
    let authority = {
      managerAuthority: value,
    };
    try {
      await ManagerApi.PostAuthority(id, authority);
      toast("변경되었습니다");
    } catch (error) {
      console.log(error);
    }
  };

  // const authorityFilter = async (authority) => {
  //   try {
  //     const list = (await ManagerApi.List({ managerAuthority: authority })).data.data;
  //     setPostsPerPage(list.content.length);
  //     setPermissionData(list.content);
  //     setTotalCount(() => list.totalCount);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [subMenuActive, setSubMenuActive] = useState(null);
  const [rightMenuActive, setRightMenuActive] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [deleteId, setdeleteId] = useState(-1);

  useEffect(() => {
    const deleteAdmin = async () => {
      await ManagerApi.Del(deleteId);
      toast("삭제 되었습니다.");
      getList();
    };

    if (alertValue) {
      deleteAdmin();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>관리자 권한 관리</span>
        <div className="manage-table-container">
          <div className="Manage-permissions-type">
            <a
              className={authorFilter === "" ? "manager-filter" : ""}
              onClick={() => {
                // authorityFilter("");
                // setAuthorFilter("");
                navigate(`/admin/permission/1?keyword=${searchKeyword}&authority=`);
              }}
            >
              전체
            </a>
            <a
              className={authorFilter === "ROOTADMIN" ? "manager-filter" : ""}
              onClick={() => {
                // authorityFilter("ROOTADMIN");
                // setAuthorFilter("ROOTADMIN");
                navigate(`/admin/permission/1?keyword=${searchKeyword}&authority=ROOTADMIN`);
              }}
            >
              ROOT
            </a>
            <a
              className={authorFilter === "ADMIN" ? "manager-filter" : ""}
              onClick={() => {
                // authorityFilter("ADMIN");
                // setAuthorFilter("ADMIN");
                navigate(`/admin/permission/1?keyword=${searchKeyword}&authority=ADMIN`);
              }}
            >
              ADMIN
            </a>
            <a
              className={authorFilter === "MANAGER" ? "manager-filter" : ""}
              onClick={() => {
                // authorityFilter("MANAGER");
                // setAuthorFilter("MANAGER");
                navigate(`/admin/permission/1?keyword=${searchKeyword}&authority=MANAGER`);
              }}
            >
              MANAGER
            </a>
          </div>
          <div className="Manage-permissions-search-container">
            <div className="Manage-permissions-search-number">
              <span>전체 이용자 수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-permissions-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/permission/1?keyword=${searchKeyword}&authority=${authorFilter}`);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-permissions">
            <table>
              <thead>
                <tr>
                  <th>이메일</th>
                  <th>이름</th>
                  <th>가입일</th>
                  <th>활성화</th>
                  <th>권한</th>
                  <th>생성일</th>
                  <th>삭제</th>
                  <th>승인/수정</th>
                </tr>
              </thead>
              <tbody>
                {permissionsData.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr key={item.id}>
                      <td>
                        <span>{item.email}</span>
                      </td>
                      <td>{item.name}</td>
                      <td>{createDate}</td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (!item.approval) {
                              return;
                            }
                            if (subMenuActive === index) {
                              setSubMenuActive(null);
                            } else {
                              setSubMenuActive(index);
                              setRightMenuActive(null);
                            }
                          }}
                        >
                          <span>{item.activation ? "활성화" : "비활성화"}</span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {subMenuActive === index ? (
                          <ul className="table-sub-menu table-sub-menu-permissions">
                            <li
                              onClick={() => {
                                activeHandler(item.id, index, true);
                                setSubMenuActive(null);
                              }}
                            >
                              활성화
                            </li>
                            <li
                              onClick={() => {
                                activeHandler(item.id, index, false);
                                setSubMenuActive(null);
                              }}
                            >
                              비활성화
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (!item.approval) {
                              return;
                            }

                            if (rightMenuActive === index) {
                              setRightMenuActive(null);
                            } else {
                              setRightMenuActive(index);
                              setSubMenuActive(null);
                            }
                          }}
                        >
                          <span>
                            {item.managerAuthority === "MANAGER"
                              ? "MANAGER"
                              : item.managerAuthority === "ROOTADMIN"
                              ? "ROOT"
                              : item.managerAuthority === "ADMIN"
                              ? "ADMIN"
                              : "-"}
                          </span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {rightMenuActive === index && item.managerAuthority !== "ROOTADMIN" ? (
                          <ul className="table-sub-menu table-sub-menu-right">
                            {/* <li
                              onClick={() => {
                                authorityHandler(item.id, index, "ROOTADMIN");
                                setRightMenuActive(null);
                              }}
                            >
                              ROOT
                            </li> */}
                            <li
                              onClick={() => {
                                authorityHandler(item.id, index, "ADMIN");
                                setRightMenuActive(null);
                              }}
                            >
                              ADMIN
                            </li>
                            <li
                              onClick={() => {
                                authorityHandler(item.id, index, "MANAGER");
                                setRightMenuActive(null);
                              }}
                            >
                              MANAGER
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>{createDate}</td>
                      <td>
                        <a href={item.delLink} onClick={() => delHandler(`${item.id}`, index)}>
                          삭제
                        </a>
                      </td>
                      <td>
                        <a
                          className={item.approval ? null : "width-approval"}
                          onClick={() => {
                            if (item.approval === false) {
                              navigate(`/admin/permission/approve/${item.id}`, {
                                state: {
                                  id: item.id,
                                },
                              });
                            } else if (item.approval === true) {
                              navigate(`/admin/permission/edit/${item.id}`, {
                                state: {
                                  id: item.id,
                                },
                              });
                            }
                          }}
                        >
                          <span>{item.approval ? "수정" : "승인하기"}</span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(
                        `/admin/permission/${currentPage - 1}?keyword=${searchKeyword}&authority=${authorFilter}`
                      );
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={(currentPage) => {
                        navigate(`/admin/permission/${item}?keyword=${searchKeyword}&authority=${authorFilter}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage === pageIndex.at(-1)) {
                      return;
                    } else {
                      navigate(
                        `/admin/permission/${currentPage + 1}?keyword=${searchKeyword}&authority=${authorFilter}`
                      );
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AlertPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headerTitle={"삭제 하시겠습니까?"}
        content={`삭제된 데이터는 복구가 불가능합니다.`}
        cancelTitle={"취소"}
        confirmTitle={"삭제"}
        setAlertValue={setAlertValue}
      ></AlertPopup>
    </div>
  );
};

export default ManagePermissions;
