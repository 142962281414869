import api from "./api";

export default class OpensourceApi {
  static async Post(data) {
    return await api.post(`open-source`, data);
  }
  static async Get() {
    return await api.get(`open-source`);
  }
  static async Patch(id, data) {
    return await api.patch(`open-source/${id}`, data);
  }
}
