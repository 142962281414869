import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringNoticeAdd.css";
import { toast } from "react-toastify";
import MeetingNoticeApi from "../../api/meetingNoticeApi";
import { nullCheck } from "../../utils/check";
const ManageGatheringNoticeAdd = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [noticeInfo, setNoticeInfo] = useState({
    managerId: sessionStorage.getItem("id"),
    meetingId: params.id,
    title: null,
    content: null,
    images: [],
    imageFiles: [],
    file: null,
    fileUrl: null,
    fileOriginFileName: null,
  });

  const uploadImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (noticeInfo.images.length === 5) {
          toast("이미지는 최대 5장까지 등록가능합니다.");
          return;
        }
        const fileTemp = {
          imageOriginFileName: file.name,
          src: e.target.result,
        };
        setNoticeInfo((noticeInfo) => ({
          ...noticeInfo,
          imageFiles: [...noticeInfo.imageFiles, fileTemp],
          images: [...noticeInfo.images, file],
        }));
      };
    }
    e.target.value = "";
  };

  const deleteFiles = (index) => {
    setNoticeInfo((noticeInfo) => ({
      ...noticeInfo,
      imageFiles: noticeInfo.imageFiles.filter(
        (file, currentIndex) => currentIndex !== index
      ),
      images: noticeInfo.images.filter(
        (image, currentIndex) => currentIndex !== index
      ),
    }));
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setNoticeInfo((noticeInfo) => ({
        ...noticeInfo,
        file: file,
        fileUrl: e.target.result,
        fileOriginFileName: file.name,
      }));
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteFile = () => {
    setNoticeInfo((noticeInfo) => ({
      ...noticeInfo,
      file: null,
      fileUrl: "",
    }));
  };

  const addNotice = async () => {
    try {
      const formData = new FormData();
      formData.append("managerId", Number(noticeInfo.managerId));
      formData.append("meetingId", Number(noticeInfo.meetingId));
      formData.append("title", noticeInfo.title);
      formData.append("content", noticeInfo.content);
      if (!nullCheck(noticeInfo.images)) {
        for (let index = 0; index < noticeInfo.images.length; index++) {
          const element = noticeInfo.images[index];
          formData.append("images", element);
        }
      }
      if (noticeInfo.file) {
        formData.append("file", noticeInfo.file);
      }
      await MeetingNoticeApi.PostNotice(formData);
      toast("수정되었습니다.");
      navigate(-1);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-notice-add">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-notice-see-more">
          <span>추가하기</span>
          <div className="Manage-input-container gathering-notice-add-input-container">
            <span className="Manage-input-title">제목</span>
            <input
              className="Manage-input-textarea manage-form-gathering-notice-add-input"
              type="text"
              placeholder="프로그램명"
              required
              onChange={(e) =>
                setNoticeInfo((noticeInfo) => ({
                  ...noticeInfo,
                  title: e.target.value,
                }))
              }
            />
          </div>
          <div className="Manage-input-container gathering-notice-add-input-container">
            <span className="Manage-input-title">내용</span>
            <textarea
              placeholder="내용을 입력하세요"
              onChange={(e) =>
                setNoticeInfo((noticeInfo) => ({
                  ...noticeInfo,
                  content: e.target.value,
                }))
              }
            ></textarea>
          </div>
          <div className="Manage-input-container gathering-notice-add-input-container">
            <span className="Manage-input-title">이미지 (*최대 5장)</span>
            <div className="gathering-notice-add-input-img-container">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-gathering-add-input"
                type="file"
                required
                onChange={(e) => {
                  uploadImg(e);
                }}
              />
              {noticeInfo.imageFiles.map((file, index) => (
                <div
                  key={index}
                  className="manage-form-gathering-img-container"
                >
                  <img
                    src={file.src}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <img
                    src="\img\image-cancel.svg"
                    onClick={(e) => {
                      deleteFiles(index);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Manage-input-container gathering-notice-add-input-container">
            <span className="Manage-input-title">첨부파일</span>
            <div className="gathering-notice-add-input-file-container">
              <label htmlFor="file-input">
                <img src="\img\file-upload-plus.svg" />
                <span>파일 등록</span>
              </label>
              <input
                id="file-input"
                className="Manage-input-textarea manage-form-gathering-notice-add-input"
                type="file"
                required
                onChange={(e) => uploadFile(e)}
              />
              {noticeInfo.fileUrl ? (
                <div className="gathering-notice-filename">
                  <span>{noticeInfo.fileOriginFileName}</span>
                  <img src="\img\image-cancel.svg" onClick={deleteFile} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-gathering-notice-add">
            <button className="cursorPointer" onClick={addNotice}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringNoticeAdd;
