import api from "./api";

export default class MeetingAskApi {
  static async Get(data) {
    return await api.get(`meeting-ask`, data);
  }

  static async Patch(id, data) {
    return await api.patch(`meeting-ask/${id}`, data);
  }

  // static async delMeeting(id, data) {
  //   return await api.del(`meeting/${id}`, data);
  // }
}
