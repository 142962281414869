import React, { useState, useEffect } from "react";
import "../../css/banner/ManageBanner.css";
import "../../css/Manage.css";
import BannerApi from "../../api/bannerApi";
import { toast } from "react-toastify";

const ManageBanner = () => {
  const [bannerInfo, setBannerInfo] = useState({});

  useEffect(() => {
    getBanner();
  }, []);
  const getBanner = async () => {
    try {
      const info = (await BannerApi.Get()).data.data.content[0];
      setBannerInfo({
        ...info,
        imageDeleteFlag: false,
      });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const bannerImg = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setBannerInfo({
        ...bannerInfo,
        binaryUrl: file,
        imageUrl: e.target.result,
        imageOriginFileName: file.name,
        imageDeleteFlag: true,
      });
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const delBannerImg = () => {
    setBannerInfo({
      ...bannerInfo,
      imageUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
      imageDeleteFlag: true,
    });
  };

  const bannerEdit = async () => {
    try {
      const formData = new FormData();
      formData.append("link", bannerInfo.link);
      if (bannerInfo.binaryUrl) {
        formData.append("image", bannerInfo.binaryUrl);
      }
      if (bannerInfo.imageDeleteFlag) {
        formData.append("imageDeleteFlag", bannerInfo.imageDeleteFlag);
      }
      await BannerApi.Patch(bannerInfo.id, formData);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>배너 관리</span>
        <div className="Manage-form manage-form-banner">
          <span>슬라이드</span>
          <div className="ManageBanner-form-img">
            <div className="banner-input-img-container">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input id="img-input" className="Manage-input-textarea" type="file" onChange={(e) => bannerImg(e)} />
              {bannerInfo.imageUrl ? (
                <div className="banner-img-name-wrap">
                  <span>{bannerInfo.imageOriginFileName}</span>
                  <img src="\img\image-cancel.svg" onClick={delBannerImg} />
                </div>
              ) : null}
            </div>
          </div>
          <span>*권장사이즈 720*720</span>
          <div className="ManageBanner-form-link">
            <span>연결링크</span>
            <input
              type="text"
              value={bannerInfo.link}
              onChange={(e) => {
                setBannerInfo({
                  ...bannerInfo,
                  link: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-form-save Manage-form-save-banner">
            <button className="cursorPointer" onClick={bannerEdit}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageBanner;
