import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/alarm/ManageAlarmSeeMore.css";
import "../../css/Manage.css";
import AlarmApi from "../../api/alarmApi.js";
import moment from "moment";
import { toast } from "react-toastify";
// import "moment-timezone";

const ManageAlarmSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [alarmId, setAlarmId] = useState(null);
  const [alarm, setAlarm] = useState({
    title: null,
    cDate: null,
    content: null,
  });

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setAlarmId(location.state.id);
        getAlarmInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getAlarmInfo = async (id) => {
    try {
      const alarmInfo = (await AlarmApi.GetAlarm(id)).data.data.content;
      let createdDate = moment(alarmInfo.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      setAlarm({
        ...alarm,
        title: alarmInfo.title,
        cDate: createdDate,
        content: alarmInfo.content,
      });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-alarm-see-more">
          <span>상세보기</span>
          {/* <div className="Manage-form-view-details">
            <span>카테고리</span>
            <span>카테고리</span>
          </div> */}
          <div className="Manage-form-view-details">
            <span>제목</span>
            <span>{alarm.title}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시일자</span>
            <span>{alarm.cDate}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>본문</span>
            <span>{alarm.content}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageAlarmSeeMore;
