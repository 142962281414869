import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/faq/ManageFaqSeeMoreAdit.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import { toast } from "react-toastify";

const ManageFaqSeeMoreAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [faqCatagory, setFaqCategory] = useState([]);
  const [selectMenu, setSelectMenu] = useState(false);
  const [selectMenuText, setSelectMenuText] = useState(null);
  const [FaqId, setFaqId] = useState(null);
  const [faqInfo, setFaqInfo] = useState({
    title: null,
    content: null,
    managerId: sessionStorage.getItem("id"),
    faqCatagoryId: null,
  });

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setFaqId(location.state.id);
        getFaqInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getFaqInfo = async (id) => {
    try {
      const info = (await FaqApi.Get(id)).data.data.content;
      const category = (await FaqApi.GetCategory()).data.data.content;
      // const arr = [];
      // category.map((item) => {
      //   arr.unshift(item);
      // });
      setFaqCategory(category);
      setFaqInfo({
        category: info.faqCategory.title,
        title: info.title,
        content: info.content,
      });
      setSelectMenuText(info.faqCategory.title);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const faqEditHandler = async () => {
    try {
      await FaqApi.PatchFaq(FaqId, faqInfo);
      toast("수정되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-faq-see-more-adit">
          <span>수정하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">카테고리</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => setSelectMenu(!selectMenu)}
              >
                <div className="selected-value">{selectMenuText}</div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {selectMenu ? (
                <ul className="option-container">
                  {faqCatagory.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="option"
                        onClick={() => {
                          setSelectMenuText(item.title);
                          setSelectMenu(false);
                          setFaqInfo({
                            ...faqInfo,
                            faqCategoryId: item.id,
                          });
                        }}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              className="Manage-input-textarea manage-form-faq-see-more-input"
              type="text"
              placeholder="제목을 입력하세요"
              value={faqInfo.title}
              onChange={(e) => {
                setFaqInfo({
                  ...faqInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-maintext">
            <p className="Manage-input-title">본문</p>
            <textarea
              className="Manage-input-textarea manage-form-faq-see-more-textarea"
              value={faqInfo.content}
              onChange={(e) => {
                setFaqInfo({
                  ...faqInfo,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </div>{" "}
          <div className="Manage-form-save manage-form-save-faq-see-more-adit">
            <button className="cursorPointer" onClick={faqEditHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageFaqSeeMoreAdit;
