import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringAdd.css";
import MeetingApi from "../../api/meetingApi";
import TermsApi from "../../api/termsApi";
import moment from "moment";
import { toast } from "react-toastify";
import ManageGatheringProgramAdd from "./ManageGatheringProgramAdd";
import { nullCheck } from "../../utils/check";

const ManageGatheringAdd = () => {
  const navigate = useNavigate();
  const [meetingStatusFlag, setMeetingStatusFlag] = useState(false);
  const [meetingCategoryFlag, setMeetingCategoryFlag] = useState(false);
  const [meetingProgramFlag, setMeetingProgramFlag] = useState(-1);
  const [addProgramPopupFlag, setAddProgramPopupFlag] = useState(false);

  const [meeting, setMeeting] = useState({
    email: "",
    spaceName: "",
    meetingStatusTitle: "모임 상태를 선택해주세요.",
    meetingCategoryTitle: "모임 카테고리를 선택해주세요.",
    files: [],
    managerAuthority: "",

    managerId: 0,
    spaceId: 0,
    title: "",
    intro: "",
    material: "",
    meetingStatus: "",
    meetingCategory: "",
    meetingType: "",
    meetingDate: moment(new Date()).format("yyyy-MM-DD 10:00:00"),
    meetingDetail: "",
    personnel: "",
    recruitingStartDate: "",
    recruitingEndDate: "",
    content: "",
    meetingTerms: "",
    subCategory: "",
    price: 0,
    discount: false,
    discountPercent: 0,
    images: [],
    meetingPrograms: [],
  });

  // mounted
  useEffect(() => {
    getTermList();
    const setSpace = async () => {
      try {
        const response = await MeetingApi.getSpaceList({
          size: 1,
          managerId: sessionStorage.getItem("id"),
        });
        const data = response.data.data;
        setMeeting((meeting) => ({
          ...meeting,
          spaceName: data.content[0].name,
          spaceId: data.content[0].id,
        }));
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };

    const managerAuthority = sessionStorage.getItem("managerAuthority");

    if (managerAuthority === "MANAGER") {
      setSpace();
    }

    setMeeting((meeting) => ({
      ...meeting,
      managerId: sessionStorage.getItem("id"),
      email: sessionStorage.getItem("email"),
      managerAuthority: managerAuthority,
    }));
  }, []);

  const getTermList = async () => {
    try {
      const list = (await TermsApi.GetTerms(3)).data.data.content;
      setMeeting((meeting) => ({
        ...meeting,
        meetingTerms: list.content,
      }));
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  // 공간 세팅
  const [spaceList, setSpaceList] = useState([]);

  useEffect(() => {
    if (meeting.spaceName) {
      getSpaceList();
      setMeetingStatusFlag(false);
      setMeetingCategoryFlag(false);
    } else {
      setMeeting((meeting) => ({
        ...meeting,
        spaceId: 0,
      }));
      setMeeting((item) => ({
        ...item,
        // meetingPrograms: [],
      }));
    }
  }, [meeting.spaceName]);

  const getSpaceList = async () => {
    try {
      const response = await MeetingApi.getSpaceList({
        size: 1000,
        keyword: meeting.spaceName,
      });
      const data = response.data.data;
      setSpaceList(
        data.content.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  // 파일 업로드
  const inputFiles = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (meeting.files.length === 5) {
          toast("이미지는 최대 5장까지 등록가능합니다.");
          return;
        }
        const fileTemp = {
          imageOriginFileName: file.name,
          src: e.target.result,
        };
        setMeeting((meeting) => ({
          ...meeting,
          files: [...meeting.files, fileTemp],
          images: [...meeting.images, file],
        }));
      };
    }
    e.target.value = "";
  };
  const deleteFiles = (index) => {
    setMeeting((meeting) => ({
      ...meeting,
      files: meeting.files.filter(
        (file, currentIndex) => currentIndex !== index
      ),
      images: meeting.images.filter(
        (image, currentIndex) => currentIndex !== index
      ),
    }));
  };

  // 프로그램 세팅
  const [programList, setProgramList] = useState([]);

  const getProgramList = async () => {
    try {
      const response = await MeetingApi.getProgramList({
        size: 1000,
        // spaceId: meeting.spaceId,
        activation: true,
      });
      const data = response.data.data;
      // const meetingProgramIds = meeting.meetingPrograms.map(
      //   (item) => item.programId
      // );
      setProgramList(
        // data.content.filter((item) => !meetingProgramIds.includes(item.id))
        [...data.content]
      );
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  // 저장
  const save = async () => {
    if (meeting.meetingStatus === "TEMP_SAVED") {
      try {
        if (meeting.spaceId === 0) {
          toast("공간명을 입력해주세요.");
          return;
        }

        const requestDto = new FormData();
        meeting.managerId && requestDto.append("managerId", meeting.managerId);
        requestDto.append("spaceId", meeting.spaceId);
        meeting.title && requestDto.append("title", meeting.title);
        meeting.intro && requestDto.append("intro", meeting.intro);
        meeting.material && requestDto.append("material", meeting.material);
        meeting.meetingStatus &&
          requestDto.append("meetingStatus", meeting.meetingStatus);
        meeting.meetingCategory &&
          requestDto.append("meetingCategory", meeting.meetingCategory);
        meeting.meetingType &&
          requestDto.append("meetingType", meeting.meetingType);
        meeting.meetingDate &&
          requestDto.append("meetingDate", meeting.meetingDate);
        meeting.meetingDetail &&
          requestDto.append("meetingDetail", meeting.meetingDetail);
        meeting.personnel && requestDto.append("personnel", meeting.personnel);
        meeting.recruitingStartDate &&
          requestDto.append("recruitingStartDate", meeting.recruitingStartDate);
        meeting.recruitingEndDate &&
          requestDto.append("recruitingEndDate", meeting.recruitingEndDate);
        meeting.content && requestDto.append("content", meeting.content);
        meeting.meetingTerms &&
          requestDto.append("meetingTerms", meeting.meetingTerms);
        meeting.subCategory &&
          requestDto.append("subCategory", meeting.subCategory);
        meeting.price && requestDto.append("price", meeting.price);
        meeting.discount && requestDto.append("discount", meeting.discount);
        meeting.discountPercent &&
          requestDto.append("discountPercent", meeting.discountPercent);
        meeting.meetingDate &&
          requestDto.append("startDate", meeting.meetingDate);

        if (meeting.meetingDetail) {
          if (meeting.meetingDetail.includes("시간")) {
            requestDto.append(
              "endDate",
              moment(new Date(meeting.meetingDate))
                .add(meeting.meetingDetail.split("시간")[0], "h")
                .format("yyyy-MM-DD HH:mm:ss")
            );
          } else {
            requestDto.append(
              "endDate",
              moment(new Date(meeting.meetingDate))
                .add(
                  Number(meeting.meetingDetail.split("박")[1].split("일")[0]) -
                    1,
                  "d"
                )
                .format("yyyy-MM-DD HH:mm:ss")
            );
          }
        }

        if (!nullCheck(meeting.images)) {
          for (let index = 0; index < meeting.images.length; index++) {
            const element = meeting.images[index];
            requestDto.append("images", element);
          }
        }

        if (!nullCheck(meeting.meetingPrograms)) {
          for (let index = 0; index < meeting.meetingPrograms.length; index++) {
            const element = meeting.meetingPrograms[index];
            if (element.programId === 0) {
              break;
            }
            requestDto.append(
              `meetingPrograms[${index}][programId]`,
              element.programId
            );
            requestDto.append(
              `meetingPrograms[${index}][reviewFlag]`,
              element.reviewFlag
            );
          }
        }

        await MeetingApi.postTempMeeting(requestDto);
        toast("저장되었습니다.");
        navigate("/admin/gathering/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    } else {
      try {
        if (meeting.spaceId === 0) {
          toast("공간명을 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.title)) {
          toast("모임 제목을 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.meetingStatus)) {
          toast("모임 상태를 선택해주세요.");
          return;
        }

        if (nullCheck(meeting.meetingCategory)) {
          toast("모임 카테고리를 선택해주세요.");
          return;
        }

        if (
          nullCheck(meeting.meetingDate) ||
          nullCheck(meeting.meetingDetail)
        ) {
          toast("모임 일시를 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.personnel)) {
          toast("모집인원을 입력해주세요.");
          return;
        }

        if (
          nullCheck(meeting.recruitingStartDate) ||
          nullCheck(meeting.recruitingEndDate)
        ) {
          toast("모집기간을 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.intro)) {
          toast("모임 소개을 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.material)) {
          toast(
            "모임 준비물을 입력해주세요. 준비물이 없을 시 없음으로 입력해주세요."
          );
          return;
        }

        if (nullCheck(meeting.content)) {
          toast("상세소개를 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.meetingTerms)) {
          toast("상품약관을 입력해주세요.");
          return;
        }

        if (nullCheck(meeting.meetingType)) {
          toast("모임 분류를 선택해주세요.");
          return;
        }

        if (meeting.meetingType === "PAY" && meeting.price === 0) {
          toast("가격을 입력해주세요.");
          return;
        }

        const requestDto = new FormData();
        requestDto.append("managerId", meeting.managerId);
        requestDto.append("spaceId", meeting.spaceId);
        requestDto.append("title", meeting.title);
        requestDto.append("intro", meeting.intro);
        requestDto.append("material", meeting.material);
        requestDto.append("meetingStatus", meeting.meetingStatus);
        requestDto.append("meetingCategory", meeting.meetingCategory);
        requestDto.append("meetingType", meeting.meetingType);
        requestDto.append("meetingDate", meeting.meetingDate);
        requestDto.append("meetingDetail", meeting.meetingDetail);
        requestDto.append("personnel", meeting.personnel);
        requestDto.append("recruitingStartDate", meeting.recruitingStartDate);
        requestDto.append("recruitingEndDate", meeting.recruitingEndDate);
        requestDto.append("content", meeting.content);
        requestDto.append("meetingTerms", meeting.meetingTerms);
        requestDto.append("subCategory", meeting.subCategory);
        requestDto.append("price", meeting.price);
        requestDto.append("discount", meeting.discount);
        requestDto.append("discountPercent", meeting.discountPercent);
        requestDto.append("startDate", meeting.meetingDate);
        if (meeting.meetingDetail.includes("시간")) {
          requestDto.append(
            "endDate",
            moment(new Date(meeting.meetingDate))
              .add(meeting.meetingDetail.split("시간")[0], "h")
              .format("yyyy-MM-DD HH:mm:ss")
          );
        } else {
          requestDto.append(
            "endDate",
            moment(new Date(meeting.meetingDate))
              .add(
                Number(meeting.meetingDetail.split("박")[1].split("일")[0]) - 1,
                "d"
              )
              .format("yyyy-MM-DD HH:mm:ss")
          );
        }

        if (!nullCheck(meeting.images)) {
          for (let index = 0; index < meeting.images.length; index++) {
            const element = meeting.images[index];
            requestDto.append("images", element);
          }
        }

        let breakFlag = false;
        if (!nullCheck(meeting.meetingPrograms)) {
          for (let index = 0; index < meeting.meetingPrograms.length; index++) {
            const element = meeting.meetingPrograms[index];
            if (element.programId === 0) {
              breakFlag = true;
              break;
            }
            requestDto.append(
              `meetingPrograms[${index}][programId]`,
              element.programId
            );
            requestDto.append(
              `meetingPrograms[${index}][reviewFlag]`,
              element.reviewFlag
            );
          }
          if (breakFlag) {
            toast("프로그램을 선택해주세요.");
            return;
          }
        }

        await MeetingApi.postMeeting(requestDto);
        toast("저장되었습니다.");
        navigate("/admin/gathering/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-add">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div
          className="Manage-form manage-form-gathering-add"
          style={{ minHeight: "0", height: "100%" }}
        >
          <span>추가하기</span>
          <span>기본정보 및 소개</span>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <span className="Manage-input-title">등록관리자이메일</span>
            <input
              className="Manage-input-textarea manage-form-gathering-add-input"
              type="email"
              value={meeting.email}
              disabled
            />
          </div>
          {meeting.managerAuthority !== "MANAGER" ? (
            <div className="Manage-input-container Manage-input-container-gathering-add">
              <span className="Manage-input-title">공간명*</span>
              <div className="select" style={{ border: "none" }}>
                <input
                  className="Manage-input-textarea manage-form-gathering-add-input"
                  type="text"
                  placeholder="공간명 검색 후 선택해주세요"
                  value={meeting.spaceName}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      spaceName: e.target.value,
                    }));
                  }}
                  required
                />
                {meeting.spaceName.length > 0 && meeting.spaceId === 0 ? (
                  <ul className="option-container">
                    {spaceList.map((space, index) => (
                      <li
                        key={index}
                        className="option"
                        onClick={(e) => {
                          setMeeting((meeting) => ({
                            ...meeting,
                            spaceId: space.id,
                            spaceName: space.name,
                          }));
                        }}
                      >
                        {space.name}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">모임제목*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="모임 제목을 입력해주세요."
                  value={meeting.title}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      title: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">상태*</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => {
                  setMeetingStatusFlag(!meetingStatusFlag);
                  if (meetingCategoryFlag) {
                    setMeetingCategoryFlag(false);
                  }
                }}
              >
                <div
                  className={
                    meeting.meetingStatus === ""
                      ? "selected-value-basic"
                      : "selected-value"
                  }
                >
                  {meeting.meetingStatusTitle}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {meetingStatusFlag ? (
                <ul className="option-container">
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "RECRUITING",
                        meetingStatusTitle: "모집중",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    모집중
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "COMPLETE",
                        meetingStatusTitle: "모집완료",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    모집완료
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "END",
                        meetingStatusTitle: "종료",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    종료
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "CANCEL",
                        meetingStatusTitle: "취소",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    취소
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingStatus: "TEMP_SAVED",
                        meetingStatusTitle: "임시저장",
                      }));
                      setMeetingStatusFlag(!meetingStatusFlag);
                    }}
                  >
                    임시저장
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">카테고리*</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => {
                  setMeetingCategoryFlag(!meetingCategoryFlag);
                  if (meetingStatusFlag) {
                    setMeetingStatusFlag(false);
                  }
                }}
              >
                <div
                  className={
                    meeting.meetingCategory === ""
                      ? "selected-value-basic"
                      : "selected-value"
                  }
                >
                  {meeting.meetingCategoryTitle}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {meetingCategoryFlag ? (
                <ul className="option-container">
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "RESIDENCE",
                        meetingCategoryTitle: "거주",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    거주
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "TRAVEL",
                        meetingCategoryTitle: "여행",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    여행
                  </li>
                  <li
                    className="option"
                    onClick={() => {
                      setMeeting((item) => ({
                        ...item,
                        meetingCategory: "ORGANIZATION",
                        meetingCategoryTitle: "단체",
                      }));
                      setMeetingCategoryFlag(!meetingCategoryFlag);
                    }}
                  >
                    단체
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-postdate">
            <p className="Manage-input-title">일시*</p>
            <div className="Manage-date-postdate Manage-date-postdate-gathering">
              <input
                type="datetime-local"
                required
                data-placeholder="NNNN-NN-NN --:--"
                value={meeting.meetingDate}
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingDate: moment(new Date(e.target.value)).format(
                      "yyyy-MM-DD HH:mm:ss"
                    ),
                  }));
                }}
              />
              <input
                className="Manage-input-textarea manage-form-gathering-add-input"
                type="text"
                required
                placeholder="N시간 혹은 N박 N일로 입력해주세요"
                value={meeting.meetingDetail}
                onChange={(e) => {
                  setMeeting((meeting) => ({
                    ...meeting,
                    meetingDetail: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">모집인원*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="number"
                  required
                  placeholder="N"
                  value={meeting.personnel}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      personnel: e.target.value,
                    }));
                  }}
                />
                <span style={{ marginLeft: "310px" }}>명</span>
              </div>
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">모집기간*</p>
            <div className="Manage-date-postdate Manage-date-postdate-gathering-period">
              <input
                type="date"
                required
                data-placeholder="NNNN-NN-NN"
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    recruitingStartDate: moment(new Date(e.target.value))
                      .hours(0)
                      .format("yyyy-MM-DD HH:mm:ss"),
                  }));
                }}
              />
              <span>~</span>
              <input
                type="date"
                required
                data-placeholder="NNNN-NN-NN"
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    recruitingEndDate: moment(new Date(e.target.value))
                      .hours(23)
                      .minutes(59)
                      .seconds(59)
                      .format("yyyy-MM-DD HH:mm:ss"),
                  }));
                }}
              />
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">모임소개*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="모임 소개를 입력해주세요"
                  value={meeting.intro}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      intro: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">준비물*</p>
            <div>
              <div className="recruit-number-container">
                <input
                  style={{ paddingRight: "25px" }}
                  className="Manage-input-textarea Manage-input-recruit-number"
                  type="text"
                  required
                  placeholder="준비물을 입력해주세요 ex) 수건, 선크림"
                  value={meeting.material}
                  onChange={(e) => {
                    setMeeting((meeting) => ({
                      ...meeting,
                      material: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add flex-start">
            <span className="Manage-input-title">상세소개*</span>
            <textarea
              placeholder="모임 상세소개를 입력해주세요"
              value={meeting.content}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  content: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add flex-start">
            <span className="Manage-input-title">상품약관*</span>
            <textarea
              placeholder="상품약관을 입력해주세요"
              value={meeting.meetingTerms}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  meetingTerms: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <p className="Manage-input-title">
              서브 카테고리
              <br />
              (최대5개)
            </p>
            <input
              className="Manage-input-textarea manage-form-gathering-add-input"
              type="text"
              placeholder="ex) 서핑, 바다, 익스트림"
              value={meeting.subCategory}
              onChange={(e) => {
                setMeeting((meeting) => ({
                  ...meeting,
                  subCategory: e.target.value,
                }));
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-gathering-add">
            <span className="Manage-input-title">이미지 (*최대 5장)</span>
            <div className="manage-form-gathering-add-input-img manage-form-gathering-add-input-img-root">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-gathering-add-input"
                type="file"
                required
                onChange={(e) => {
                  inputFiles(e);
                }}
              />

              {meeting.files.map((file, index) => (
                <div
                  key={index}
                  className="manage-form-gathering-img-container"
                >
                  <img
                    src={file.src}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <img
                    src="\img\image-cancel.svg"
                    onClick={(e) => {
                      deleteFiles(index);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Manage-input-container">
            <span className="Manage-input-title">모임분류*</span>
            <div className="input-radio-gathering-container">
              <input
                className="input-radio-gathering"
                type="radio"
                id="pay"
                name="gathering-classification"
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "PAY",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="pay">유료</label>
              <input
                className="input-radio-gathering"
                type="radio"
                id="free-procedure"
                name="gathering-classification"
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "APPROVAL",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="free-procedure">무료 (승인절차O)</label>
              <input
                className="input-radio-gathering"
                type="radio"
                id="free-no-procedure"
                name="gathering-classification"
                onChange={(e) => {
                  setMeeting((item) => ({
                    ...item,
                    meetingType: "FREE",
                    price: 0,
                    discount: false,
                    discountPercent: 0,
                  }));
                }}
              />
              <label htmlFor="free-no-procedure">무료 (승인절차X)</label>
            </div>
          </div>
          {meeting.meetingType === "PAY" ? (
            <div className="Manage-input-container Manage-input-container-gathering-add">
              <span className="Manage-input-title">가격</span>
              <div>
                <input
                  type="number"
                  className="Manage-input-textarea manage-form-gathering-add-input"
                  placeholder="숫자만 입력"
                  onChange={(e) => {
                    setMeeting((item) => ({
                      ...item,
                      price: e.target.value,
                    }));
                  }}
                />
                <div className="sale-apply-container">
                  <input
                    type="checkbox"
                    id="sale-apply"
                    onChange={(e) => {
                      setMeeting((item) => ({
                        ...item,
                        discount: e.target.checked,
                      }));
                    }}
                  ></input>
                  <label htmlFor="sale-apply"></label>
                  <span>할인적용</span>
                  <div className="apply-number-container">
                    <input
                      className="Manage-input-textarea Manage-input-sale-apply-number"
                      type="text"
                      placeholder="N"
                      style={{ paddingLeft: "228px" }}
                      onChange={(e) => {
                        setMeeting((item) => ({
                          ...item,
                          discountPercent: e.target.value,
                        }));
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="gathering-program-add-wrap">
            <span className="gathering-program-add">프로그램 등록</span>
            <div className="gathering-program-add-btn">
              <a
                onClick={(e) => {
                  // if (meeting.spaceId === 0) {
                  //   toast("공간명을 먼저 입력해주세요.");
                  //   return;
                  // }

                  setMeeting((item) => ({
                    ...item,
                    meetingPrograms: [
                      ...meeting.meetingPrograms,
                      {
                        programId: 0,
                        programName: "프로그램을 선택해주세요",
                        reviewFlag: false,
                      },
                    ],
                  }));

                  getProgramList();
                }}
              >
                프로그램 추가
              </a>
              <a
                onClick={(e) => {
                  // if (meeting.spaceId === 0) {
                  //   toast("공간명을 먼저 입력해주세요.");
                  //   return;
                  // }
                  setMeetingProgramFlag(-1);
                  setAddProgramPopupFlag(true);
                }}
              >
                새로운 프로그램 추가
              </a>
            </div>
            <span className="gathering-program-add-note">
              *프로그램 리뷰 작성은 최대 3개까지 선택 가능합니다.
            </span>
            {meeting.meetingPrograms.map((meetingProgram, index) => (
              <div
                key={index}
                className="Manage-input-container Manage-input-container-gathering-add"
                style={{ flexWrap: "wrap" }}
              >
                <span className="Manage-input-title">프로그램*</span>
                <div
                  className="gathering-program-add-column"
                  style={{ width: "75%" }}
                >
                  <div className="gathering-program-add-select-container">
                    <div className="select">
                      <div
                        className="selected"
                        onClick={() => {
                          if (index === meetingProgramFlag) {
                            setMeetingProgramFlag(-1);
                          } else {
                            setMeetingProgramFlag(index);
                          }
                        }}
                      >
                        <div
                          className={
                            meeting.meetingPrograms[index].programId === 0
                              ? "selected-value-basic"
                              : "selected-value"
                          }
                        >
                          {meeting.meetingPrograms[index].programName}
                        </div>
                        <div className="select-toggle-btn">
                          <img src="\img\table-togle.svg" />
                        </div>
                      </div>
                      {meetingProgramFlag === index ? (
                        <ul className="option-container">
                          {programList.map((program, index2) => (
                            <li
                              key={index2}
                              className="option"
                              onClick={() => {
                                meeting.meetingPrograms[index].programId =
                                  program.id;
                                meeting.meetingPrograms[index].programName =
                                  program.title;
                                setMeetingProgramFlag(-1);
                              }}
                            >
                              [{program.space?.name}]{program.title}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                    <div>
                      <a
                        onClick={(e) => {
                          setMeeting((meeting) => ({
                            ...meeting,
                            meetingPrograms: meeting.meetingPrograms.filter(
                              (meetingProgram, currentIndex) =>
                                currentIndex !== index
                            ),
                          }));
                        }}
                      >
                        삭제
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="gathering-review-apply"
                  style={{ marginTop: "10px", marginLeft: "151px" }}
                >
                  <input
                    type="checkbox"
                    id="review-apply"
                    checked={meeting.meetingPrograms[index].reviewFlag}
                    onChange={(e) => {
                      const reviewFlags = meeting.meetingPrograms
                        .map((item) => item.reviewFlag)
                        .filter((item) => item === true);
                      if (reviewFlags.length === 3 && e.target.checked) {
                        toast(
                          "프로그램 리뷰 작성은 최대 3개까지 선택 가능합니다."
                        );
                        e.target.checked = false;
                        return;
                      }

                      let meetingPrograms = JSON.parse(
                        JSON.stringify(meeting.meetingPrograms)
                      );
                      meetingPrograms[index].reviewFlag = e.target.checked;
                      setMeeting((meeting) => ({
                        ...meeting,
                        meetingPrograms: meetingPrograms,
                      }));
                    }}
                  ></input>
                  <label htmlFor="review-apply"></label>
                  <span>리뷰작성</span>
                </div>
              </div>
            ))}
          </div>
          <div className="Manage-form-save manage-form-save-gathering-add">
            <button
              style={{
                position: "relative",
                right: "-12px",
                bottom: "-5px",
                cursor: "pointer",
              }}
              className="cursorPointer"
              onClick={(e) => {
                save();
              }}
            >
              저장하기
            </button>
          </div>
        </div>

        <ManageGatheringProgramAdd
          key={String(addProgramPopupFlag)}
          openFlag={addProgramPopupFlag}
          setAddProgramPopupFlag={setAddProgramPopupFlag}
          spaceId={meeting.spaceId}
          getProgramList={getProgramList}
        ></ManageGatheringProgramAdd>
      </section>
    </div>
  );
};

export default ManageGatheringAdd;
