import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../css/Manage.css";
import "../../css/program/ManageProgramAdit.css";
import ProgramApi from "../../api/programApi";
import { toast } from "react-toastify";

const ManageProgramAdit = () => {
  let navigate = useNavigate();

  let location = useLocation();

  const [delId, setDelId] = useState([]);
  const [programId, setProgramId] = useState(null);
  const [programInfo, setProgramInfo] = useState({});
  const [programImgs, setProgramImgs] = useState([]);

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setProgramId((id) => location.state.id);
        getProgramInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getProgramInfo = async (id) => {
    try {
      const info = (await ProgramApi.GetProgram(id)).data.data.content;
      setProgramInfo((programInfo) => info);
      setProgramImgs(info.programFiles);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const uploadImg = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (programImgs.length === 5) {
        toast("이미지는 최대 5장까지 등록가능합니다.");
        return;
      }
      const arr = [...programImgs];
      const obj = {};
      obj.image = file;
      obj.imageUrl = e.target.result;
      arr.push(obj);
      setProgramImgs((prev) => {
        return arr;
      });
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const editProgram = async () => {
    try {
      const formData = new FormData();
      formData.append("programCategory", programInfo.programCategory);
      formData.append("title", programInfo.title);
      formData.append("requiredTime", programInfo.requiredTime);
      formData.append("content", programInfo.content);
      if (programImgs.length) {
        for (let i = 0; i < programImgs.length; i++) {
          if (programImgs[i].image) {
            formData.append(`images`, programImgs[i].image);
          }
        }
      }
      if (delId.length) {
        for (let i = 0; i < delId.length; i++) {
          formData.append(`imagesDeleteIds`, delId[i]);
        }
      }
      await ProgramApi.Patch(programId, formData);
      toast("수정되었습니다.");
      navigate(`/admin/program/detail/${programId}`, { state: { id: programId } });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-program-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-program-see-more">
          <span>수정하기</span>
          <div className="Manage-input-container Manage-input-container-program-adit">
            <span className="Manage-input-title">프로그램명*</span>
            <input
              className="Manage-input-textarea manage-form-program-add-input"
              type="text"
              placeholder="프로그램명"
              required
              value={programInfo.title}
              onChange={(e) => {
                setProgramInfo({
                  ...programInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-program-adit">
            <span className="Manage-input-title">소요시간*</span>
            <div className="taken-time-container">
              <input
                className="Manage-input-textarea manage-form-program-add-input"
                type="nunber"
                required
                value={`${programInfo.requiredTime}`}
                onChange={(e) => {
                  setProgramInfo({
                    ...programInfo,
                    requiredTime: e.target.value,
                  });
                }}
              />
              <span className="taken-time-placeholder">시간</span>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-program-adit">
            <span className="Manage-input-title">상세설명*</span>
            <textarea
              placeholder="상세설명을 입력하세요"
              value={programInfo.content}
              onChange={(e) => {
                setProgramInfo({
                  ...programInfo,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-program-adit">
            <span className="Manage-input-title">이미지 (*최대 5장)</span>
            <div className="manage-form-program-add-input-img manage-form-program-add-input-img-root">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-program-add-input"
                type="file"
                required
                onChange={(e) => {
                  uploadImg(e);
                }}
              />
              {programImgs &&
                programImgs.map((item, index) => {
                  return (
                    <div className="uploaded-imgs">
                      <img className="uploaded-img" src={item.imageUrl} />
                      <img
                        src="\img\image-cancel.svg"
                        onClick={() => {
                          let copyData = [...programImgs];
                          copyData.splice(index, 1);
                          setProgramImgs([...copyData]);
                          if (item.id) {
                            setDelId((delId) => [...delId, item.id]);
                          }
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-program-add">
            <button className="cursorPointer" onClick={editProgram}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageProgramAdit;
