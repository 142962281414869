import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringNoticeAdit.css";
import MeetingNoticeApi from "../../api/meetingNoticeApi";
import { toast } from "react-toastify";
const ManageGatheringNoticeAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [noticeId, setNoticeId] = useState(null);
  const [noticeInfo, setNoticeInfo] = useState({});

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setNoticeId(location.state.id);
      getNoticeInfo(id);
    }
  }, [location]);

  const getNoticeInfo = async (id) => {
    try {
      const info = (await MeetingNoticeApi.GetNotice(id)).data.data.content;
      setNoticeInfo({ ...info, imageDeleteFlag: false, fileDeleteFlag: false });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const uploadImg = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setNoticeInfo((noticeInfo) => ({
        ...noticeInfo,
        image: file,
        imageUrl: e.target.result,
        imageDeleteFlag: true,
      }));
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteImage = () => {
    setNoticeInfo((noticeInfo) => ({
      ...noticeInfo,
      image: null,
      imageUrl: "",
      imageDeleteFlag: true,
    }));
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setNoticeInfo((noticeInfo) => ({
        ...noticeInfo,
        file: file,
        fileUrl: e.target.result,
        fileDeleteFlag: true,
        fileOriginFileName: file.name,
      }));
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteFile = () => {
    setNoticeInfo((noticeInfo) => ({
      ...noticeInfo,
      file: null,
      fileUrl: "",
      fileDeleteFlag: true,
    }));
  };

  const editNotice = async () => {
    try {
      const formData = new FormData();
      formData.append("title", noticeInfo.title);
      formData.append("content", noticeInfo.content);
      if (noticeInfo.image) {
        formData.append("image", noticeInfo.image);
      }
      if (noticeInfo.file) {
        formData.append("file", noticeInfo.file);
      }
      if (noticeInfo.imageDeleteFlag) {
        formData.append("imageDeleteFlag", noticeInfo.imageDeleteFlag);
      }
      if (noticeInfo.fileDeleteFlag) {
        formData.append("fileDeleteFlag", noticeInfo.fileDeleteFlag);
      }
      await MeetingNoticeApi.PatchNotice(noticeId, formData);
      toast("수정되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-notice-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-notice-see-more">
          <span>수정하기</span>
          <div className="Manage-input-container gathering-notice-adit-input-container">
            <span className="Manage-input-title">제목</span>
            <input
              className="Manage-input-textarea manage-form-gathering-notice-add-input"
              type="text"
              placeholder="제목을 입력하세요"
              required
              value={noticeInfo.title}
              onChange={(e) =>
                setNoticeInfo((noticeInfo) => ({
                  ...noticeInfo,
                  title: e.target.value,
                }))
              }
            />
          </div>
          <div className="Manage-input-container gathering-notice-adit-input-container">
            <span className="Manage-input-title">내용</span>
            <textarea
              placeholder="내용을 입력하세요"
              value={noticeInfo.content}
              onChange={(e) =>
                setNoticeInfo((noticeInfo) => ({
                  ...noticeInfo,
                  content: e.target.value,
                }))
              }
            ></textarea>
          </div>
          <div className="Manage-input-container gathering-notice-adit-input-container">
            <span className="Manage-input-title">이미지</span>
            <div className="gathering-notice-add-input-img-container">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-gathering-notice-add-input"
                type="file"
                required
                onChange={(e) => uploadImg(e)}
              />
              {noticeInfo.imageUrl ? (
                <div className="gathering-notice-img">
                  <img className="uploaded-img" src={noticeInfo.imageUrl} />
                  <img src="\img\image-cancel.svg" onClick={deleteImage} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container gathering-notice-adit-input-container">
            <span className="Manage-input-title">첨부파일</span>
            <div className="gathering-notice-add-input-file-container">
              <label htmlFor="file-input">
                <img src="\img\file-upload-plus.svg" />
                <span>파일 등록</span>
              </label>
              <input
                id="file-input"
                className="Manage-input-textarea manage-form-gathering-notice-add-input"
                type="file"
                required
                onChange={(e) => uploadFile(e)}
              />
              {noticeInfo.fileUrl ? (
                <div className="gathering-notice-filename">
                  <span>{noticeInfo.fileOriginFileName}</span>
                  <img src="\img\image-cancel.svg" onClick={deleteFile} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-gathering-notice-add">
            <button className="cursorPointer" onClick={editNotice}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringNoticeAdit;
