import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/generalmember/ManageGeneralMemberAdit.css";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";

const ManageGeneralMemberAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [memberInfo, setMemberInfo] = useState({});
  const [memberId, setMemberId] = useState({});

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setMemberId(location.state.id);
      getMemberInfo(id);
    }
  }, [location]);

  const getMemberInfo = async (id) => {
    try {
      const getMember = (await MemberApi.GetMember(id)).data.data.content;
      setMemberInfo(getMember);
    } catch (error) {
      console.log(error);
    }
  };

  const addImg = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setMemberInfo({
        ...memberInfo,
        binaryUrl: file,
        profileUrl: e.target.result,
        profileOriginFileName: file.name,
        profileDeleteFlag: true,
      });
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const delimg = () => {
    setMemberInfo({
      ...memberInfo,
      profileUrl: "",
      profileOriginFileName: "",
      profileServerFileName: "",
      profileDeleteFlag: true,
    });
  };

  const memberEditHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("email", memberInfo.email);
      formData.append("name", memberInfo.name);
      formData.append("birth", memberInfo.birth);
      formData.append("phoneNumber", memberInfo.phoneNumber);
      formData.append("nickname", memberInfo.nickname);
      formData.append("intro", memberInfo.intro);
      if (memberInfo.binaryUrl) {
        formData.append("image", memberInfo.binaryUrl);
      }
      formData.append("agreeToUse", memberInfo.agreeToUse);
      formData.append("agreeToPrivacy", memberInfo.agreeToPrivacy);
      formData.append("agreeToEmailSms", memberInfo.agreeToEmailSms);
      formData.append("agreeToService", memberInfo.agreeToService);
      formData.append("agreeToAd", memberInfo.agreeToAd);
      formData.append("pushToken", memberInfo.pushToken);
      formData.append("profileDeleteFlag ", memberInfo.profileDeleteFlag);

      await MemberApi.EditMember(memberId, formData);
      toast("수정되었습니다.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-general-member-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-general-member-adit">
          <span>수정하기</span>
          <div className="Manage-input-container Manage-input-container-general-member-adit">
            <span className="Manage-input-title">이메일</span>
            <input
              className="Manage-input-textarea manage-form-general-member-adit-input"
              type="email"
              placeholder="이메일"
              disabled
              value={memberInfo.email}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-adit">
            <span className="Manage-input-title">이름*</span>
            <input
              className="Manage-input-textarea manage-form-general-member-adit-input"
              type="text"
              value={memberInfo.name}
              onChange={(e) => {
                setMemberInfo({
                  ...memberInfo,
                  name: e.target.value,
                });
              }}
              required
            />
          </div>
          <div className="Manage-input-container general-member-adit-dirth">
            <span className="Manage-input-title">생년월일*</span>
            <div>
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                className="Manage-input-textarea manage-form-general-member-adit-input"
                type="number"
                maxLength={6}
                required
                value={memberInfo.birth}
                onChange={(e) => {
                  setMemberInfo({
                    ...memberInfo,
                    birth: e.target.value,
                  });
                }}
              />
              <img src="\img\dash.svg" />
              <div className="resident-number-second-wrap">
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  className="Manage-input-textarea manage-form-general-member-adit-input"
                  type="number"
                  maxLength={1}
                  required
                  value={memberInfo.gender}
                  onChange={(e) => {
                    setMemberInfo({
                      ...memberInfo,
                      gender: e.target.value,
                    });
                  }}
                />
                <span>******</span>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details general-member-detail-gender">
            <span className="Manage-input-title">성별</span>
            <span>{memberInfo.gender % 2 === 1 ? "남성" : "여성"}</span>
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-adit">
            <span className="Manage-input-title">휴대폰 번호</span>
            <input
              className="Manage-input-textarea manage-form-general-member-adit-input"
              type="email"
              placeholder="010-0000-0000"
              disabled
              defaultValue={memberInfo.phoneNumber}
            />
          </div>
          <div className="Manage-form-view-details general-member-info-flex-start">
            <span>
              주민등록증
              <br />
              이미지
            </span>
            <div className="general-member-img-container">
              <div>
                <label htmlFor="img-input">
                  <img src="\img\img-registration.svg" />
                  <span>이미지 등록</span>
                </label>
                <input
                  id="img-input"
                  className="manage-form-general-member-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    addImg(e);
                  }}
                />
                {memberInfo.profileUrl ? (
                  <div className="manage-form-general-member-img-container">
                    <img className="uploaded-img" src={memberInfo.profileUrl} />
                    <img src="\img\image-cancel.svg" onClick={delimg} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-adit">
            <span className="Manage-input-title">별명*</span>
            <input
              className="Manage-input-textarea manage-form-general-member-adit-input"
              type="text"
              placeholder="별명"
              required
              value={memberInfo.nickname}
              onChange={(e) => {
                setMemberInfo({
                  ...memberInfo,
                  nickname: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-adit general-member-info-flex-start">
            <span className="Manage-input-title">한 줄 자기소개*</span>
            <textarea
              value={memberInfo.intro}
              onChange={(e) => {
                setMemberInfo({
                  ...memberInfo,
                  intro: e.target.value,
                });
              }}
            ></textarea>
          </div>
          <div className="Manage-form-view-details general-member-agreement-checkbox">
            <span>마케팅 수신 동의</span>
            <div>
              <div className="seemore-consent-checkbox">
                <span>(필수) 괜찮아마을 이용약관 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info"
                  checked="true"
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(필수) 개인정보수집 및 이용 동의</span>
                <input
                  type="checkbox"
                  id="consent-personal-info-third"
                  checked="true"
                  readOnly
                ></input>
                <label htmlFor="consent-personal-info-third"></label>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details general-member-agreement-checkbox">
            <span>알림 수신 동의</span>
            <div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 이메일 및 SMS 마케팅정보수신 동의</span>
                <input
                  type="checkbox"
                  className="cursorPointer"
                  id="agree-email-sms"
                  onChange={() => {
                    if (memberInfo.agreeToEmailSms === true) {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToEmailSms: false,
                      });
                    } else {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToEmailSms: true,
                      });
                    }
                  }}
                  checked={memberInfo.agreeToEmailSms ? true : false}
                ></input>
                <label htmlFor="agree-email-sms"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 서비스 알림 수신 동의</span>
                <input
                  type="checkbox"
                  className="cursorPointer"
                  id="agree-service"
                  onChange={() => {
                    if (memberInfo.agreeToService === true) {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToService: false,
                      });
                    } else {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToService: true,
                      });
                    }
                  }}
                  checked={memberInfo.agreeToService ? true : false}
                ></input>
                <label htmlFor="agree-service"></label>
              </div>
              <div className="seemore-consent-checkbox">
                <span>(선택) 광고성 알림 수신 동의</span>
                <input
                  type="checkbox"
                  id="agree-ad"
                  className="cursorPointer"
                  onChange={() => {
                    if (memberInfo.agreeToAd === true) {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToAd: false,
                      });
                    } else {
                      setMemberInfo({
                        ...memberInfo,
                        agreeToAd: true,
                      });
                    }
                  }}
                  checked={memberInfo.agreeToAd ? true : false}
                ></input>
                <label htmlFor="agree-ad"></label>
              </div>
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-general-member-adit">
            <button className="cursorPointer" onClick={memberEditHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGeneralMemberAdit;
