import api from "./api";

export default class ReportApi {
  static async Get(data) {
    return await api.get(`community-report`, data);
  }

  static async GetReport(id) {
    return await api.get(`community-report/${id}`);
  }

  static async Del(id) {
    return await api.del(`community-report/${id}`);
  }
}
