import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/report/ManageReportSeeMore.css";
import "../../css/Manage.css";
import moment from "moment";
import ReportApi from "../../api/reportApi";
import { toast } from "react-toastify";

const ManageReportSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [reportId, setReportId] = useState(null);
  const [reportInfo, setReportInfo] = useState({
    writerEmail: null,
    nickname: null,
    writeDate: null,
    title: null,
    content: null,
    imgs: [],
    reportEmail: null,
    reportDate: null,
    reason: null,
  });

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setReportId(location.state.id);
      getReportInfo(id);
    }
  }, [location]);

  const getReportInfo = async (id) => {
    try {
      const info = (await ReportApi.GetReport(id)).data.data.content;
      let createDate = moment(info.community.createdAt).utc().format(
        "YYYY/MM/DD HH:mm:ss"
      );
      let reportDate = moment(info.createdAt).utc().format("YYYY/MM/DD");
      setReportInfo({
        writerEmail: info.community.member.email,
        nickname: info.community.member.nickname,
        writeDate: createDate,
        title: info.community.title,
        content: info.community.content,
        imgs: info.community.communityFiles,
        reportEmail: info.reportEmail,
        reportDate: reportDate,
        reason: info.reason,
      });
    } catch (error) {}
  };

  const delReport = async (id) => {
    try {
      await ReportApi.Del(id);
      toast("삭제되었습니다");
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-report-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={() => delReport(reportId)}>삭제</Link>
          </div>
        </div>
        <div className="Manage-form manage-form-report-see-more">
          <span>상세보기</span>
          <div className="Manage-form-view-details">
            <span>신고유형</span>
            <span>게시글</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시자 이메일</span>
            <span>{reportInfo.writerEmail}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시자 별명</span>
            <span>{reportInfo.nickname}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>작성일시</span>
            <span>{reportInfo.writeDate}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>제목</span>
            <span>{reportInfo.title}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>본문</span>
            <div className="Manage-form-view-details-textarea">
              <span>{reportInfo.content}</span>
              {reportInfo.imgs.map((item, index) => {
                return <img className="report-img" src={item.imageUrl} />;
              })}
            </div>
          </div>
          <div className="Manage-form-view-details">
            <span>신고자 이메일</span>
            <span>{reportInfo.reportEmail}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>신고일자</span>
            <span>{reportInfo.reportDate}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-lastchild">
            <span>신고사유</span>
            <span>{reportInfo.reason}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageReportSeeMore;
