import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/Manage.css";
import "../../css/spacesupport/ManageSpaceSupport.css";
import SpaceSupportApi from "../../api/spaceSupportApi";
import moment from "moment";
import { toast } from "react-toastify";

const ManageSpaceSupport = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [spaceSupportData, setSpaceSupportData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getSupportList();
  }, [currentPage, location]);

  const getSupportList = async () => {
    try {
      const list = (
        await SpaceSupportApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setSpaceSupportData(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const supportStatusHandler = async (id, index, state) => {
    let copyData = [...spaceSupportData];
    copyData[index].status = state;
    setSpaceSupportData(copyData);
    let supportStatus = {
      status: state,
    };
    try {
      await SpaceSupportApi.PostStatus(id, supportStatus);
      toast("변경되었습니다.");
      getSupportList();
    } catch (error) {
      console.log(error);
    }
  };

  const [subMenuActive, setSubMenuActive] = useState(null);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>공간 지원 관리</span>
        <div className="manage-table-container">
          <div className="Manage-spacesupport-search-container">
            <div className="Manage-spacesupport-search-number">
              <span>전체</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-spacesupport-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/spacesupport/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              <div className="cursorPointer">
                <img src="\img\download.svg" />
                <span>엑셀로 내보내기</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-spacesupport">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>담당자 이름</th>
                  <th>제휴 내용</th>
                  <th>신청일</th>
                  <th>상태</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {spaceSupportData.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr key={index}>
                      <td>{totalCount - (index + (currentPage - 1) * 10)}</td>
                      <td>
                        <span>{item.managerName}</span>
                      </td>
                      <td>
                        <span>{item.content}</span>
                      </td>
                      <td>{createDate}</td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (subMenuActive === index) setSubMenuActive(null);
                            else setSubMenuActive(index);
                          }}
                        >
                          <span>{item.status ? "처리" : "미처리"}</span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {subMenuActive === index ? (
                          <ul className="table-sub-menu table-sub-menu-spacesupport">
                            <li
                              onClick={() => {
                                supportStatusHandler(item.id, index, true);
                                setSubMenuActive(null);
                              }}
                            >
                              처리
                            </li>
                            <li
                              onClick={() => {
                                supportStatusHandler(item.id, index, false);
                                setSubMenuActive(null);
                              }}
                            >
                              미처리
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <Link to={`/admin/spacesupport/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/spacesupport/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/spacesupport/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/spacesupport/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageSpaceSupport;
