import api, { _axios } from "./api";

export default class ProgramApi {
  static async Get(data) {
    return await api.get(`program`, data);
  }
  static async Post(data) {
    return await api.post(`program`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async Patch(id, data) {
    return await api.patch(`program/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async PostActivation(id, data) {
    return await api.post(`program/activation/${id}`, data);
  }
  static async GetProgram(id) {
    return await api.get(`program/${id}`);
  }
  static async DelProgram(id) {
    return await api.del(`program/${id}`);
  }

  static async FileDownload(data) {
    return await _axios.post(`program/download/file`, data, {
      responseType: "blob",
    });
  }
}
