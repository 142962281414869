import api from "./api";

export default class MeetingProgramApi {
  static async getMeetings(data) {
    return await api.get(`meeting`, data);
  }

  static async setStatus(id, data) {
    return await api.post(`meeting/status/${id}`, data);
  }

  static async setMeetingProgram(data) {
    return await api.post(`meeting-program`, data);
  }

  static async delMeetingProgram(id) {
    return await api.del(`meeting-program/${id}`);
  }
  static async updateReviewFlag(id, data) {
    return await api.patch(`meeting-program/review/${id}`, data);
  }
}
