import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../css/community/ManageCommunitySeeMore.css";
import "../../css/Manage.css";
import CommunityApi from "../../api/communityApi";
import moment from "moment";
import { toast } from "react-toastify";
import { click } from "@testing-library/user-event/dist/click";
import ManageGatheringCommentPopup from "./ManageGatheringCommentPopup";

const ManageCommunitySeeMore = () => {
  let searchRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();

  const [totalCount, setTotalCount] = useState(0);
  const [clickIndex, setClickIndex] = useState(null);
  const [clickId, setClickId] = useState(null);
  const [communityComData, setCommunityComData] = useState([]);
  const [communityInfo, setCommunityInfo] = useState({
    email: null,
    nickname: null,
    date: null,
    title: null,
    content: null,
    imgs: [],
    likeCount: null,
  });
  const [subMenuActive, setSubMenuActive] = useState(null);
  const [popup, setPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  const { id } = useParams();
  useEffect(() => {
    getCommunityList();
    getCommunityReviewList();
  }, [location, currentPage]);

  useEffect(() => {
    getCommunityReviewList();
  }, [popup]);

  const getCommunityList = async () => {
    try {
      //커뮤니티 상세 보기
      const response = (await CommunityApi.GetNotice(id)).data.data.content;

      let createDate = moment(response.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      setCommunityInfo({
        email: response.member.email,
        nickname: response.member.nickname,
        date: createDate,
        title: response.title,
        content: response.content,
        imgs: response.communityFiles,
        likeCount: response.likeCount,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCommunityReviewList = async () => {
    //커뮤니티 상세 댓글 보기
    const review = (
      await CommunityApi.GetCommunityReview({
        communityId: id,
        page: currentPage,
        size: 10,
      })
    ).data.data;
    setCommunityComData(review.content);
    setTotalCount(review.totalCount);

    setTotalCount(review.totalCount);

    setPostsPerPage(review.content.length);

    const count = Math.ceil(review.totalCount / review.content.length / 4);

    for (let i = 1; i <= count; i++) {
      if (currentPage / 4 <= i) {
        setStartIndex(4 * (i - 1));
        return;
      }
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const comReviewActivation = async (id, index, state) => {
    let copyData = [...communityComData];
    copyData[index].activation = state;
    setCommunityComData(copyData);
    let arr = {
      activation: state,
    };
    try {
      await CommunityApi.reviewActivation(id, arr);
      toast("변경되었습니다.");
      getCommunityReviewList();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setPopup(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-community-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <a>숨김</a>
          </div>
        </div>
        <div className="Manage-form manage-form-community-see-more">
          <span>기본정보</span>
          <div className="Manage-form-view-details">
            <span>게시자 이메일</span>
            <span>{communityInfo.email}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시자 별명</span>
            <span>{communityInfo.nickname}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>작성일시</span>
            <span>{communityInfo.date}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>제목</span>
            <span>{communityInfo.title}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-flexstart">
            <span>본문</span>
            <div className="Manage-form-view-details-textarea">
              <span>{communityInfo.content}</span>
              {communityInfo.imgs.map((item, index) => {
                return <img className="community-img" src={item.imageUrl} />;
              })}
            </div>
          </div>
          <div className="Manage-form-view-details">
            <span>좋아요 수</span>
            <span>{communityInfo.likeCount}</span>
          </div>
        </div>
        <div className="Manage-form manage-form-community-see-more-comment">
          <span>댓글</span>
          <table>
            <thead>
              <tr>
                <th>별명</th>
                <th>분류</th>
                <th>작성일자</th>
                <th>내용</th>
                <th>상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {communityComData.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>
                      <span>{item.member.nickname}</span>
                    </td>
                    <td>
                      <span>{item.id === item.parentId ? "댓글" : "대댓글"}</span>
                    </td>
                    <td>{createDate}</td>
                    <td>
                      <span>{item.content}</span>
                    </td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (subMenuActive === index) {
                            setSubMenuActive(null);
                          } else {
                            setSubMenuActive(index);
                          }
                        }}
                      >
                        <span>{item.activation ? "일반" : "숨김"}</span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {subMenuActive === index ? (
                        <ul className="table-sub-menu">
                          <li
                            onClick={() => {
                              comReviewActivation(item.id, index, true);
                              setSubMenuActive(null);
                            }}
                          >
                            일반
                          </li>
                          <li
                            onClick={() => {
                              comReviewActivation(item.id, index, false);
                              setSubMenuActive(null);
                            }}
                          >
                            숨김
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <a
                        onClick={() => {
                          setPopup(!popup);
                          setClickId(item.id);
                          setClickIndex(index);
                        }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                return (
                  <a
                    key={index}
                    className={currentPage === item ? "pagenation-focus" : null}
                    onClick={() => {
                      setCurrentPage(item);
                    }}
                  >
                    {item}
                  </a>
                );
              })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {popup && (
        <ManageGatheringCommentPopup
          searchRef={searchRef}
          setPopup={setPopup}
          clickId={clickId}
          clickIndex={clickIndex}
        />
      )}
    </div>
  );
};

export default ManageCommunitySeeMore;
