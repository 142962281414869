import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../css/report/ManageReportSeeMore.css";
import "../../css/Manage.css";
import moment from "moment";
import ReportApi from "../../api/reportApi";
import { toast } from "react-toastify";

const ManageReportSeeMoreComment = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [reportId, setReportId] = useState(null);
  const [reportInfo, setReportInfo] = useState({
    writeTitle: null,
    writeDate: null,
    commentEmail: null,
    nickname: null,
    commentDate: null,
    content: null,
    reportEmail: null,
    reportDate: null,
    reason: null,
  });

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setReportId(location.state.id);
      getReportInfo(id);
    }
  }, [location]);

  const getReportInfo = async (id) => {
    try {
      const info = (await ReportApi.GetReport(id)).data.data.content;
      let createDate = moment(info.community.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      let commentDate = moment(info.communityReview.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      let reportDate = moment(info.createdAt).utc().format("YYYY/MM/DD");
      setReportInfo({
        writeTitle: info.community.title,
        writeDate: createDate,
        commentEmail: info.communityReview.member.email,
        nickname: info.communityReview.member.nickname,
        commentDate: commentDate,
        content: info.communityReview.content,
        reportEmail: info.reportEmail,
        reportDate: reportDate,
        reason: info.reason,
      });
    } catch (error) {}
  };

  const delReport = async (id) => {
    try {
      await ReportApi.Del(id);
      toast("삭제되었습니다");
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-report-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={() => delReport(reportId)}>삭제</Link>
          </div>
        </div>
        <div className="Manage-form manage-form-report-see-more">
          <span>상세보기</span>
          <div className="Manage-form-view-details">
            <span>신고유형</span>
            <span>댓글</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시글 제목</span>
            <span>{reportInfo.title}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시글 작성일시</span>
            <span>{reportInfo.writeDate}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>댓글 작성자 이메일</span>
            <span>{reportInfo.commentEmail}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>댓글 작성자 별명</span>
            <span>{reportInfo.nickname}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>댓글 작성일시</span>
            <span>{reportInfo.commentDate}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>댓글 내용</span>
            <span>{reportInfo.content}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>신고자 이메일</span>
            <span>{reportInfo.reportEmail}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>신고일자</span>
            <span>{reportInfo.reportDate}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-lastchild">
            <span>신고사유</span>
            <span>{reportInfo.reason}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageReportSeeMoreComment;
