import api from "./api";

export default class LeaveApi {
  static async Get(data) {
    return await api.get(`leave-member`, data);
  }

  static async Del(id) {
    return await api.del(`leave-member/${id}`);
  }
}
