import { Routes, Route, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./App.css";
import "../src/css/Manage.css";
import Header from "./components/Header.js";
import Sidebar from "./components/Sidebar";
import ManagePermissions from "./pages/permission/ManagePermissions.js";
import ManagePermissionsAdit from "./pages/permission/ManagePermissionsAdit";
import ManagePermissionsApproval from "./pages/permission/ManagePermissionsApproval";
import ManagePermissionsInformation from "./pages/permission/ManagePermissionsInformation";
import ManageTerms from "./pages/terms/ManageTerms";
import ManageBanner from "./pages/banner/ManageBanner";
import ManageMarker from "./pages/marker/ManageMarker";
import ManageOpensource from "./pages/opensource/ManageOpensource";
import ManagePopups from "./pages/popup/ManagePopups";
import ManagePopupsAdit from "./pages/popup/ManagePopupsAdit";
import ManageFaq from "./pages/faq/ManageFaq";
import ManageFaqSeeMore from "./pages/faq/ManageFaqSeeMore";
import ManageFaqSeeMoreAdit from "./pages/faq/ManageFaqSeeMoreAdit";
import ManageFaqSeeMoreAdd from "./pages/faq/ManageFaqSeeMoreAdd";
import ManageCatagory from "./pages/catagory/ManageCatagory";
import ManageCatagoryAdit from "./pages/catagory/ManageCatagoryAdit";
import ManageNotice from "./pages/notice/ManageNotice";
import ManageNoticeSeeMore from "./pages/notice/ManageNoticeSeeMore";
import ManageNoticeSeeMoreAdit from "./pages/notice/ManageNoticeSeeMoreAdit";
import ManageNoticeSeeMoreAdd from "./pages/notice/ManageNoticeSeeMoreAdd";
import ManageAlarm from "./pages/alarm/ManageAlarm";
import ManageAlarmSeeMore from "./pages/alarm/ManageAlarmSeeMore";
import ManageAlarmSeeMoreAdd from "./pages/alarm/ManageAlarmSeeMoreAdd";
import ManageReport from "./pages/report/ManageReport";
import ManageReportSeeMore from "./pages/report/ManageReportSeeMore";
import ManageReportSeeMoreComment from "./pages/report/ManageReportSeeMoreComment";
import ManageCommunity from "./pages/community/ManageCommunity";
import ManagecommunitySeeMore from "./pages/community/ManageCommunitySeeMore";
import ManageMileage from "./pages/mileage/ManageMileage";
import ManageMileageSeeMore from "./pages/mileage/ManageMileageSeeMore";
import ManageMileageIndiIncrease from "./pages/mileage/ManageMileageIndiIncrease";
import ManageMileageAllIncrease from "./pages/mileage/ManageMileageAllIncrease";
import ManageMileageHistory from "./pages/mileage/ManageMileageHistory";
import ManageProgram from "./pages/program/ManageProgram";
import ManageProgramSeeMore from "./pages/program/ManageProgramSeeMore";
import ManageProgramAddManager from "./pages/program/ManageProgramAddManager";
import ManageProgramAddRootAdmin from "./pages/program/ManageProgramAddRootAdmin";
import ManageProgramAdit from "./pages/program/ManageProgramAdit";
import ManageWithdraw from "./pages/withdraw/ManageWithdraw";
import ManageSpaceSupport from "./pages/spacesupport/ManageSpaceSupport";
import ManageSpaceSupportSeeMore from "./pages/spacesupport/ManageSpaceSupportSeeMore";
import ManageLogin from "./pages/login/ManageLogin";
import ManageRegistration from "./pages/registration/ManageRegistration";
import ManageRegistrationComplete from "./pages/registration/ManageRegistrationComplete";
import ManageGathering from "./pages/gathering/ManageGathering";
import ManageGatheringSeeMore from "./pages/gathering/ManageGatheringSeeMore";
import ManageGatheringAdd from "./pages/gathering/ManageGatheringAdd";
import ManageGatheringAdit from "./pages/gathering/ManageGatheringAdit";
import ManageGatheringSchedule from "./pages/gathering/ManageGatheringSchedule";
import ManageGatheringScheduleAdd from "./pages/gathering/ManageGatheringScheduleAdd";
import ManageGatheringScheduleEnroll from "./pages/gathering/ManageGatheringScheduleEnroll";
import ManageGatheringProgramAdd from "./pages/gathering/ManageGatheringProgramAdd";
import ManageGatheringScheduleFalse from "./pages/gathering/ManageGatheringScheduleFalse";
import ManageGatheringReview from "./pages/gathering/ManageGatheringReview";
import ManageGatheringReviewSeeMore from "./pages/gathering/ManageGatheringReviewSeeMore";
import ManageGatheringInquiry from "./pages/gathering/ManageGatheringInquiry";
import ManageGatheringInquirySeeMore from "./pages/gathering/ManageGatheringInquirySeeMore";
import ManageGatheringNotice from "./pages/gathering/ManageGatheringNotice";
import ManageGatheringNoticeSeeMore from "./pages/gathering/ManageGatheringNoticeSeeMore";
import ManageGatheringCommentPopup from "./pages/gathering/ManageGatheringCommentPopup";
import ManageGatheringNoticeAdit from "./pages/gathering/ManageGatheringNoticeAdit";
import ManageGatheringNoticeAdd from "./pages/gathering/ManageGatheringNoticeAdd";
import ManageGeneralMember from "./pages/generalmember/ManageGeneralMember";
import ManageGeneralMemberSeeMore from "./pages/generalmember/ManageGeneralMemberSeeMore";
import ManageGeneralMemberAdit from "./pages/generalmember/ManageGeneralMemberAdit";
import ManageGeneralMemberMessage from "./pages/generalmember/ManageGeneralMemberMessage";
import ManageSpace from "./pages/space/ManageSpace";
import ManageSpaceSeeMore from "./pages/space/ManageSpaceSeeMore";
import ManageSpaceAdd from "./pages/space/ManageSpaceAdd";
import ManageSpaceAdit from "./pages/space/ManageSpaceAdit";
import ManageSpaceReview from "./pages/space/ManageSpaceReview";
import ManageMain from "./pages/main/ManageMain";
import ManageCatagoryAdd from "./pages/catagory/ManageCatagoryAdd";
import ManagePopupsAdd from "./pages/popup/ManagePopupsAdd";
import ManageProgramAdd from "./pages/program/ManageProgramAdd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const SHeader = ({ isSHeader }) => {
  // console.log("isSHeader", isSHeader);
  return (
    <>
      {isSHeader ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : null}
    </>
  );
};

function App(props) {
  const [isSHeader, setIsSHeader] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // console.log(location);

    menuController(location);
  }, [location.pathname]);

  const menuController = (location) => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("/admin/join") ||
      location.pathname.includes("/admin/login")
    ) {
      setIsSHeader(false);
    } else {
      setIsSHeader(true);
    }
  };

  return (
    <div className="App">
      <SHeader isSHeader={isSHeader} />

      <Routes>
        <Route path="/" element={<ManageLogin />} />
        <Route path="/admin" element={<ManageLogin />} />
        <Route path="/admin/login" element={<ManageLogin />} />
        <Route path="/admin/join" element={<ManageRegistration />} />
        <Route path="/admin/join/success" element={<ManageRegistrationComplete />} />

        {/* <ManageRegistrationComplete /> */}
        <Route path="/admin/home" element={<ManageMain />} />
        <Route path="/admin/member/:page" element={<ManageGeneralMember />}></Route>
        <Route path="/admin/member/detail/:id" element={<ManageGeneralMemberSeeMore />}></Route>
        <Route path="/admin/member/edit/:id" element={<ManageGeneralMemberAdit />}></Route>
        <Route path="/admin/member/message" element={<ManageGeneralMemberMessage />}></Route>
        <Route path="/admin/space/:page" element={<ManageSpace />}></Route>
        <Route path="/admin/space/detail/:id" element={<ManageSpaceSeeMore />}></Route>
        <Route path="/admin/space/add" element={<ManageSpaceAdd />}></Route>
        <Route path="/admin/space/detail/edit/:id" element={<ManageSpaceAdit />}></Route>
        <Route path="/admin/space/detail/review/:id" element={<ManageSpaceReview />}></Route>
        <Route path="/admin/spacesupport/:page" element={<ManageSpaceSupport />}></Route>
        <Route path="/admin/spacesupport/detail/:id" element={<ManageSpaceSupportSeeMore />}></Route>
        <Route path="/admin/withdraw/:page" element={<ManageWithdraw />}></Route>
        <Route path="/admin/gathering/:page" element={<ManageGathering />}></Route>
        <Route path="/admin/gathering/detail/:id" element={<ManageGatheringSeeMore />}></Route>
        <Route path="/admin/gathering/detail/:id/:page" element={<ManageGatheringSeeMore />}></Route>
        <Route path="/admin/gathering/add" element={<ManageGatheringAdd />}></Route>
        <Route path="/admin/gathering/edit/:id" element={<ManageGatheringAdit />}></Route>
        <Route path="/admin/gathering/detail/schedule/:id" element={<ManageGatheringSchedule />}></Route>
        <Route path="/admin/gathering/detail/schedule/enroll/:id" element={<ManageGatheringScheduleEnroll />}></Route>
        <Route path="/admin/gathering/detail/schedule/add" element={<ManageGatheringScheduleAdd />}></Route>
        <Route path="/admin/gathering/detail/notice" element={<ManageGatheringNotice />}></Route>
        <Route path="/admin/gathering/detail/notice/detail/:id" element={<ManageGatheringNoticeSeeMore />}></Route>
        <Route path="/admin/gathering/detail/notice/edit/:id" element={<ManageGatheringNoticeAdit />}></Route>
        <Route path="/admin/gathering/detail/notice/add/:id" element={<ManageGatheringNoticeAdd />}></Route>
        <Route path="/admin/gathering/detail/review" element={<ManageGatheringReview />}></Route>
        <Route path="/admin/gathering/detail/review/detail/:id" element={<ManageGatheringReviewSeeMore />}></Route>
        <Route path="/admin/gathering/detail/ask" element={<ManageGatheringInquiry />}></Route>
        <Route path="/admin/gathering/detail/ask/detail" element={<ManageGatheringInquirySeeMore />}></Route>

        {/* <ManageGatheringScheduleFalse /> */}

        <Route path="/admin/program/:page" element={<ManageProgram />}></Route>
        <Route path="/admin/program/detail/:id" element={<ManageProgramSeeMore />}></Route>
        <Route path="/admin/program/edit/:id" element={<ManageProgramAdit />}></Route>
        <Route path="/admin/program/edit" element={<ManageProgramAdit />}></Route>
        <Route path="/admin/program/add" element={<ManageProgramAdd />}></Route>
        <Route path="/admin/program/add/manager" element={<ManageProgramAddManager />}></Route>
        <Route path="/admin/program/add/root" element={<ManageProgramAddRootAdmin />}></Route>
        <Route path="/admin/mileage/:page" element={<ManageMileage />}></Route>
        <Route path="/admin/mileage/indiincrease" element={<ManageMileageIndiIncrease />}></Route>
        <Route path="/admin/mileage/allincrease" element={<ManageMileageAllIncrease />}></Route>
        <Route path="/admin/mileage/detail/:id" element={<ManageMileageSeeMore />}></Route>
        <Route path="/admin/mileage/history/:id" element={<ManageMileageHistory />}></Route>
        <Route path="/admin/community/:page" element={<ManageCommunity />}></Route>
        <Route path="/admin/community/detail/:id" element={<ManagecommunitySeeMore />}></Route>
        <Route path="/admin/report/:page" element={<ManageReport />}></Route>
        <Route path="/admin/report/detail/:id" element={<ManageReportSeeMore />}></Route>
        <Route path="/admin/report/comment/:id" element={<ManageReportSeeMoreComment />}></Route>

        <Route path="/admin/alarm/:page" element={<ManageAlarm />}></Route>
        <Route path="/admin/alarm/detail/:id" element={<ManageAlarmSeeMore />}></Route>
        <Route path="/admin/alarm/add" element={<ManageAlarmSeeMoreAdd />}></Route>
        <Route path="/admin/notice/:page" element={<ManageNotice />}></Route>
        <Route path="/admin/notice/detail/:id" element={<ManageNoticeSeeMore />}></Route>
        <Route path="/admin/notice/detail/edit/:id" element={<ManageNoticeSeeMoreAdit />}></Route>
        <Route path="/admin/notice/add" element={<ManageNoticeSeeMoreAdd />}></Route>
        <Route path="/admin/faq/:page" element={<ManageFaq />}></Route>
        <Route path="/admin/faq/add" element={<ManageFaqSeeMoreAdd />}></Route>
        <Route path="/admin/faq/detail/:id" element={<ManageFaqSeeMore />}></Route>
        <Route path="/admin/faq/detail/edit/:id" element={<ManageFaqSeeMoreAdit />}></Route>
        <Route path="/admin/popups/:page" element={<ManagePopups />}></Route>
        <Route path="/admin/popups/edit/:id" element={<ManagePopupsAdit />}></Route>
        <Route path="/admin/popups/add" element={<ManagePopupsAdd />}></Route>
        <Route path="/admin/terms" element={<ManageTerms />}></Route>
        <Route path="/admin/banner" element={<ManageBanner />}></Route>
        <Route path="/admin/marker" element={<ManageMarker />}></Route>
        <Route path="/admin/opensource" element={<ManageOpensource />}></Route>
        <Route path="/admin/information" element={<ManagePermissionsInformation />}></Route>
        <Route path="/admin/permission/:page" element={<ManagePermissions />}></Route>
        <Route path="/admin/permission/edit/:id" element={<ManagePermissionsAdit />}></Route>
        <Route path="/admin/permission/approve/:id" element={<ManagePermissionsApproval />}></Route>
        <Route path="/admin/category/edit/:id" element={<ManageCatagoryAdit />}></Route>
        <Route path="/admin/category/add" element={<ManageCatagoryAdd />}></Route>

        <Route path="*" element={<div>404</div>}></Route>
      </Routes>
      <ToastContainer className="toast" position="top-center" autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default App;
