import React from "react";

export default function AlertPopup(props) {
  return props.isOpen ? (
    <div className="gathering-program-add-container">
      <div
        className="gathering-program-add-popup"
        style={{
          width: "496px",
          height: "217px",
          background: "#FFFFFF",
          borderRadius: "12px",
          padding: "32px 33px",
        }}
      >
        <div>
          <span
            style={{
              fontFamily: "Pretendard",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "25px",
              color: "#262C31",
            }}
          >
            {props.headerTitle}
          </span>
        </div>
        <div style={{ marginTop: "12px" }}>
          <p
            style={{
              fontFamily: "Pretendard",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "26px",
              color: "#5A636A",
              display: "block",
            }}
          >
            {props.content}
          </p>
        </div>
        <div
          style={{ marginTop: "15px", display: "flex", justifyContent: "end" }}
        >
          <div
            style={{
              cursor: "pointer",
              width: "102px",
              height: "49px",
              background: "#CCD1D7",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Pretendard",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "25px",
              color: "#FFFFFF",
              marginRight: "12px",
            }}
            onClick={(e) => {
              props.setAlertValue(false);
              props.setIsOpen(false);
            }}
          >
            {props.cancelTitle}
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "102px",
              height: "49px",
              background: "#3AC8F5",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Pretendard",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "25px",
              color: "#FFFFFF",
            }}
            onClick={(e) => {
              props.setAlertValue(true);
              props.setIsOpen(false);
            }}
          >
            {props.confirmTitle}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
