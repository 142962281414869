import React, { useState } from "react";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringDeatilProgramAdd.css";
import MeetingProgramApi from "../../api/meetingProgramApi";
import { toast } from "react-toastify";

const ManageGatheringDetailProgramAdd = (props) => {
  const [meetingProgramFlag, setMeetingProgramFlag] = useState(-1);
  const [meetingProgram, setMeetingProgram] = useState({
    programId: 0,
    programName: "프로그램을 선택해주세요",
    reviewFlag: false,
  });

  const setReviewActivation = async () => {
    if (meetingProgram.programId) {
      try {
        await MeetingProgramApi.setMeetingProgram({
          meetingId: props.meetingId,
          programId: meetingProgram.programId,
          reviewFlag: false,
        });
        props.setAddProgramPopupFlag(false);
        await props.getMeetingProgramsInfo();
        toast("추가되었습니다.");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    }
  };

  return props.openFlag ? (
    <div>
      <div className="gathering-detail-program-add-container">
        <div
          className="gathering-detail-program-add-popup"
          ref={props.searchRef}
        >
          <span>프로그램 추가하기</span>
          <div
            className="Manage-input-container Manage-input-container-gathering-add"
            style={{ flexWrap: "wrap" }}
          >
            <span className="Manage-input-title">프로그램*</span>
            <div
              className="gathering-program-add-column"
              style={{ width: "75%" }}
            >
              <div className="gathering-program-add-select-container">
                <div className="select">
                  <div
                    className="selected"
                    onClick={() => {
                      if (1 === meetingProgramFlag) {
                        setMeetingProgramFlag(-1);
                      } else {
                        setMeetingProgramFlag(1);
                      }
                    }}
                  >
                    <div
                      className={
                        meetingProgram.programId === 0
                          ? "selected-value-basic"
                          : "selected-value"
                      }
                    >
                      {meetingProgram.programName}
                    </div>
                    <div className="select-toggle-btn">
                      <img src="\img\table-togle.svg" />
                    </div>
                  </div>
                  {meetingProgramFlag === 1 ? (
                    <ul className="option-container">
                      {props.programList.map((program, index2) => (
                        <li
                          key={index2}
                          className="option"
                          onClick={() => {
                            meetingProgram.programId = program.id;
                            meetingProgram.programName = program.title;
                            setMeetingProgramFlag(-1);
                          }}
                        >
                          [{program.space?.name}]{program.title}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="save-gathering-detail-program-add-popup">
            <a
              onClick={(e) => {
                props.setAddProgramPopupFlag(false);
              }}
            >
              닫기
            </a>
            <a
              onClick={(e) => {
                setReviewActivation();
              }}
            >
              추가하기
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ManageGatheringDetailProgramAdd;
