import React, { useEffect, useState } from "react";
import "../../css/opensource/ManageOpensource.css";
import "../../css/Manage.css";
import OpensourceApi from "../../api/opensourceApi";
import { toast } from "react-toastify";

const ManageOpensource = () => {
  const [opensourceId, setOpensourceId] = useState(null);
  const [emptyCheck, setEmptyCheck] = useState(false);
  const [opensourceInfo, setOpensourceInfo] = useState({ content: null });
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    getOpensource();
  }, []);

  const getOpensource = async () => {
    try {
      const opensource = (await OpensourceApi.Get()).data.data.content;
      if (opensource.length === 0) {
        setEmptyCheck(true);
      } else {
        setEmptyCheck((value) => false);
        setOpensourceInfo({
          ...opensourceInfo,
          content: opensource[0].content,
        });
        setOpensourceId((value) => opensource[0].id);
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const postOpensource = async () => {
    try {
      await OpensourceApi.Post(opensourceInfo);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };
  const patchOpensource = async () => {
    try {
      await OpensourceApi.Patch(opensourceId, opensourceInfo);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>오픈소스</span>
        <div
          className={`Manage-form ${
            toggle ? "manage-form-opensource" : "manage-form-opensource-close"
          }`}
        >
          <div>
            <span>오픈소스</span>
            <img
              onClick={() => setToggle(!toggle)}
              src={toggle ? "/img/table-toggle-up.svg" : "/img/table-togle.svg"}
            />
          </div>
          {toggle ? (
            <>
              <div>
                <textarea
                  className="Manage-form-textarea"
                  value={opensourceInfo.content}
                  onChange={(e) =>
                    setOpensourceInfo({
                      content: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="Manage-form-save Manage-form-save-opensource cursorPointer">
                <button onClick={emptyCheck ? postOpensource : patchOpensource}>
                  저장하기
                </button>
              </div>
            </>
          ) : null}
          {/* <div>
            <textarea
              className="Manage-form-textarea"
              value={opensourceInfo.content}
              onChange={(e) =>
                setOpensourceInfo({
                  content: e.target.value,
                })
              }
            ></textarea>
          </div>
          <div className="Manage-form-save Manage-form-save-opensource">
            <button onClick={emptyCheck ? postOpensource : patchOpensource}>
              저장하기
            </button>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default ManageOpensource;
