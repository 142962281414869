import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/gathering/ManageGatheringScheduleAdd.css";
import "../../css/Manage.css";
const ManageGatheringScheduleAdd = () => {
  let navigate = useNavigate();

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-schedule">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-schedule-add">
          <div>
            <span>일정관리</span>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title-schedule">모임기간*</p>
            <div className="Manage-date-postdate gathering-period-schedule">
              <div>
                <input
                  type="datetime-local"
                  required
                  data-placeholder="NNNN-NN-NN --:--"
                />
              </div>
              <span>~</span>
              <div>
                <input
                  type="datetime-local"
                  required
                  data-placeholder="NNNN-NN-NN --:--"
                />
              </div>
              <a>등록</a>
            </div>
          </div>
          <div className="Manage-form-save save-gathering-add">
            <button className="cursorPointer">저장하기</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringScheduleAdd;
