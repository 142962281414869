import api from "./api";

export default class BannerApi {
  static async Post(data) {
    return await api.post(`banner`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
  static async Get() {
    return await api.get(`banner`);
  }
  static async Patch(id, data) {
    return await api.patch(`banner/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
